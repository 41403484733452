import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CurrencyPipe } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as FileSaver from "file-saver";
import { Subject } from "rxjs";
import { ToastService } from "src/app/services/toast.service";
import { Intervento, Progetto } from "../../models";
import {
  DashboardService,
  ProgettoService,
  SoggettoGiuridicoService,
  TemaPrioritarioService,
  TipoClassificazioneService,
  FinanziamentoService,
  PnrrService,
  StorageService,
  AggregatoService,
} from "../../services";
import { GeoService } from "../../../../services";

import { MapComponent } from "./map/map.component";

import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "src/app/services";
import { TipologiaInterventiService } from "../../services/tipologia-interventi.service";
import { ImageHelper } from "src/app/helpers/image.helper";
import { Router } from "@angular/router";

class Annualita {
  anno: string;
  importoI: number;
  importoF: number;
  importoFormattatoI: any;
  importoFormattatoF: any;
}

class Classificazione {
  codice: string;
  count: number = 0;
  importoI: number = 0;
  importoF: number = 0;
  differenza: number = 0;
  descrizione: string;
}

@Component({
  selector: "dashboard-finanziaria-component",
  templateUrl: "./dashboard-finanziaria.component.html",
  styleUrls: ["./dashboard-finanziaria.component.css"],
})
export class DashboardFinanziariaComponent implements OnInit, OnDestroy {
  private chart1;
  private chart2;
  private chart3;
  private chart4;
  private chart5;
  private chart6;
  private chart7;
  @ViewChild(MapComponent)
  map: MapComponent;
  allProjects: Progetto[] = [];
  filteredInterventions: Intervento[] = [];

  areeTematicheCombo: any[] = [];
  interventiCombo: any[] = [];
  classificazioniCombo: any[] = [];
  classifications = [];
  classificationsCodes = [];
  description: string;
  interventionDescription: string;

  soggettiCombo = [];
  provinceSoggettiCombo = [];
  temiCombo = [];

  anniFromCombo = [];
  anniToCombo = [];

  exportForm: FormGroup;
  myForm: FormGroup;

  pnrrComboItems: any[] = [];
  finanziamentoMissionsPnrrComboItems: any[] = [];
  finanziamentoComponentsPnrrComboItems: any[] = [];
  finanziamentoLinesPnrrComboItems: any[] = [];

  provinciaItems: any[] = [];
  comuneItems: any[] = [];
  aggregatoItems: any[] = [];

  statuses: any[] = [
    { code: 0, label: "Da validare", gruppo: "Tutti" },
    { code: 1, label: "Validato", gruppo: "Tutti" },
    { code: 2, label: "In attesa di validazione", gruppo: "Tutti" },
    { code: 7, label: "Fabbisogno", gruppo: "Tutti" },
    { code: 8, label: "Parzialmente finanziato", gruppo: "Tutti" },
    { code: 9, label: "Completato", gruppo: "Tutti" },
  ];

  data: any;

  cqlFilter: string = "";

  valoreTotaleProgetti: number = 0;
  valoreTotaleFinanziatoProgetti: number = 0;

  totInt: number = 0;
  totFin: number = 0;

  filterObject = {
    alwaysProject: 0,
    descriptionSearchString: "",
    //filtro progetto e main id
    filterProjectId: -1, // projectId
    filterMainInterventionId: -1,
    //filtro intervento
    filterSimpleInterventionId: -1,
    filterFinanziamento: "", // finanziamento
    //filtro stato finanziamento
    filterStatus: [-1], // validationStatus
    filterTipologiaIntervento: [-1], // tipologiaIntervento
    filterAreeGestionaliDirezioneEsterne: -1, // filterArea
    filterTipoClassificazione: -1, // idTipoClassificazione
    filterConClassificazioni: false, // conClassificazioni
    filterSoggettoGiuridico: -1, // idSoggetto
    filterAnno: "all", // anno
    filterProvinciaSoggettoGiuridico: "all", //provinciaSoggetto
    filterTemaProprietario: -1, // idTema
    filterFrom: -1, // annoFrom
    filterTo: -1, // annoTo
    filterFonteFinanziamentoIds: null, // fonteFinanziamento
    filterPnrr: -1,
    filterProvince: -1,
    filterComune: -1,
    filterAggregato: [-1],
    filterLineeFinanziamentiPnrr: {
      missions: null,
      components: null,
      lines: null,
    },
    isSisma: false,
    rp: -1,
  };

  noresult = false;

  isShowingFiltered = false;

  showPage = false;
  showLoading = false;

  fillInterventions = true;

  onlyNormalIntervention = [];

  completerParams = {
    labelField: "descrizioneEstesa",
    placeholder: "Cerca intervento",
    ajax: this.filterComboInterventi,
    items: [],
    context: this,
  };

  completerParamsFinanziamento = {
    labelField: "descrizioneEstesa",
    placeholder: "Cerca finanziamento",
    ajax: null,
    items: [],
  };

  classificazioniGrafico: Classificazione[] = [];

  isHiddenChart67: boolean = true;

  showIcon = false;

  tipologieInterventi: any;

  isSisma = false;

  setRP($event: any) {
    this.filterObject.rp = $event ? $event.id : -1;
  }

  soggettiRPCombo = [];

  constructor(
    private projectService: ProgettoService,
    private formBuilder: FormBuilder,
    private classificazioniService: TipoClassificazioneService,
    private dashboardService: DashboardService,
    private soggettiService: SoggettoGiuridicoService,
    private temiService: TemaPrioritarioService,
    private toastr: ToastService,
    private modalService: BsModalService,
    private finanziamentoService: FinanziamentoService,
    private authService: AuthService,
    private pnrrService: PnrrService,
    private storageService: StorageService,
    private tipologiaInterventiService: TipologiaInterventiService,
    private geoService: GeoService,
    private aggregatoService: AggregatoService,
    public router: Router
  ) {}

  ngOnInit() {
    this.isSisma = this.router.url.includes("dashboard-sisma");
    this.myForm = this.initializeFormGroup();
    this.projectService.getAll().subscribe((res) => {
      this.allProjects = res
        .filter((p) => !p.isDeleted)
        .map((p) => {
          p["label"] = `${p.codice} - ${p.descrizione}`;
          return p;
        });
    });
    this.getTipologiaInterventi();
    this.getAreeTematiche();
    this.getClassificazioni();
    this.getSoggettiGiuridici();
    this.getTemiPrioritari();
    this.getAnni();
    this.getFinanziamenti();
    this.getProvince();
    this.getFontiFinanziamento();
    this.exportForm = this.formBuilder.group({
      conClassificazioni: [null],
    });

    this.fillPnrrComboItems();
    this.fillFinanziamentoPnrrComboItems();
    if (this.isSisma) {
      this.storageService.elencoRup.subscribe((x) => {
        this.soggettiRPCombo = x;
        console.log("this.soggettiRPCombo", this.soggettiRPCombo);
      });
    }
  }

  filterComboInterventi(filterString, callBack) {
    console.log(filterString);
    let f = { ...this.filterObject };
    f["descriptionSearchString"] = filterString;
    this["context"].projectService
      .getInterventiListSimpleData(f)
      .subscribe((x) => {
        x = x.map((y) => {
          y["descrizioneEstesa"] = `${y.codice} - ${y.descrizione}`;
          return y;
        });
        callBack(x);
      });
  }

  disableFilters() {
    this.myForm.disable();
  }

  enableFilters() {
    this.myForm.enable();
  }

  fillPnrrComboItems() {
    const arr1 = [
      { key: "0", value: "No" },
      { key: "1", value: "Tutti" },
    ];
    const arr2 = [
      { key: "2", value: "Attuazione regionale" },
      { key: "3", value: "Territoriale" },
    ];
    this.pnrrComboItems = [...arr1, ...arr2];
  }

  fillFinanziamentoPnrrComboItems() {
    this.pnrrService.getAllLineaFinanziamento().subscribe((res) => {
      res = res.sort((a, b) => a.codice.localeCompare(b.codice));

      const buildFMFinanziamento = new Set();
      const buildSMFinanziamento = new Set();
      const buildTMFinanziamento = new Set();
      res.forEach((data) => {
        let dataSplitted = data.codice.split("I");

        if (dataSplitted.length <= 1) dataSplitted = data.codice.split("R");

        buildFMFinanziamento.has(data.missione.codice)
          ? null
          : buildFMFinanziamento.add(data.missione.descrizione);
        buildSMFinanziamento.has(data.missione.codice + data.componente.codice)
          ? null
          : buildSMFinanziamento.add(
              dataSplitted[0] + " - " + data.componente.descrizione
            );
        buildTMFinanziamento.add({
          valore: data.codice + " - " + data.descrizione,
          codice: data.codice,
        });
      });

      this.finanziamentoMissionsPnrrComboItems =
        Array.from(buildFMFinanziamento);
      this.finanziamentoMissionsPnrrComboItems =
        this.finanziamentoMissionsPnrrComboItems.map((data) => ({
          valore: data,
          codice: data.split(" - ")[0],
        }));
      this.finanziamentoComponentsPnrrComboItems =
        Array.from(buildSMFinanziamento);
      this.finanziamentoComponentsPnrrComboItems =
        this.finanziamentoComponentsPnrrComboItems.map((data) => ({
          valore: data,
          codice: data.split(" - ")[0],
        }));
      this.finanziamentoLinesPnrrComboItems = Array.from(buildTMFinanziamento);
    });
  }

  tipologieInterventoForSelectItems;

  tipologieInterventoForSelect() {
    if (!this.tipologieInterventi || this.tipologieInterventi.length == 0) {
      return [];
    }
    let tipologieInterventiSelect = this.tipologieInterventi.map(
      (tipologia) => {
        return {
          key: tipologia.codice,
          label: tipologia.descrizione,
        };
      }
    );
    this.tipologieInterventoForSelectItems = tipologieInterventiSelect;
  }

  ngOnDestroy(): void {
    this.disposeCharts();
    if (this.elencoAreeSubscription) this.elencoAreeSubscription.unsubscribe();
    if (this.elencoTipiFinSubscription)
      this.elencoTipiFinSubscription.unsubscribe();
  }

  disposeCharts() {
    this.chart1 && this.chart1.dispose();
    this.chart2 && this.chart2.dispose();
    this.chart3 && this.chart3.dispose();
    this.chart4 && this.chart4.dispose();
    this.chart5 && this.chart5.dispose();
    this.chart6 && this.chart6.dispose();
    this.chart7 && this.chart7.dispose();
  }

  initializeFormGroup() {
    return this.formBuilder.group({
      project: [null, null],
      intervention: [{ value: null, disabled: true }, null],
      status: [{ value: null, disabled: false }, null],
      direction: [{ value: null, disabled: false }, null],
      classification: [{ value: null, disabled: false }, null],
      from: [{ value: null, disabled: false }, null],
      to: [{ value: null, disabled: false }, null],
      fonteFinanziamento: [null],
      priority: [null],
      subject: [null],
      subjectProvince: [null],
      province: [null],
      municipality: [null],
      dettaglioTipologia: [null],
      pnrrPnc: [null],
      finanziamentoPnrrPnc: [null],
    });
  }

  getFinanziamenti() {
    this.finanziamentoService.getAll().subscribe((result) => {
      const finanziamentiList = result.map((x) => {
        return {
          ...x,
          titoloLungo: (x.codice ? x.codice + " - " : "") + x.titolo,
        };
      });
      finanziamentiList.sort((a, b) => a.id - b.id);

      this.completerParamsFinanziamento = {
        items: finanziamentiList,
        labelField: "titoloLungo",
        ajax: null,
        placeholder: "Cerca finanziamento ",
      };
    });
  }

  getAnni() {
    this.dashboardService.getAnni().subscribe((years) => {
      const from = [];
      const to = [];
      years.forEach((y) => {
        from.push({ anno: y, data: `${y}-01-01` });
        to.push({ anno: y, data: `${y}-12-31` });
      });
      this.anniFromCombo = from;
      this.anniToCombo = to;
    });
  }

  private elencoAreeSubscription = null;
  getAreeTematiche() {
    this.elencoAreeSubscription =
      this.storageService.elencoAreeTematiche.subscribe((x) => {
        this.areeTematicheCombo = x ? x : [];
      });
  }

  getClassificazioni() {
    this.classificazioniService.getAll().subscribe((x) => {
      this.classificazioniCombo = [
        { id: -2, tipo: "Nessuna classificazione" },
        ...x,
      ];
    });
  }

  getSoggettiGiuridici() {
    this.soggettiService.getAll().subscribe((x) => {
      console.log("Contenuto di soggetti giuridici", x);
      this.soggettiCombo = x;
      const province = new Set();
      this.soggettiCombo.forEach((s) => {
        s.siglaProvincia && province.add(s.siglaProvincia);
      });
      province.forEach((p) => {
        this.provinceSoggettiCombo.push({ key: p, value: p });
      });
      this.provinceSoggettiCombo = [...this.provinceSoggettiCombo];
      this.soggettiCombo = [
        { id: -2, denominazione: "Nessun soggetto giudirico" },
        ...this.soggettiCombo,
      ];
    });
  }

  getTemiPrioritari() {
    this.temiService.getAll().subscribe((temi) => {
      this.temiCombo = temi;
    });
  }

  getProvince() {
    this.geoService.getProvinces("12").subscribe((res) => {
      let ress: any[];
      ress = res;
      ress.map((i) => {
        return i.provincia;
      });
      this.provinciaItems = ress;
    });
  }

  getComune() {
    this.f.municipality.setValue(null);
    this.geoService
      .getMunicipalities(this.myForm.value.province)
      .subscribe((res) => {
        let ress: any[];
        ress = res;
        ress.map((i) => {
          return i.municipality;
        });
        this.comuneItems = ress;
      });
  }

  resetDettaglioTipologiaChecks(kp: any) {
    if (!this.f.dettaglioTipologia.value) return;

    let valid = [];
    kp.forEach((item) => {
      this.myForm.value.dettaglioTipologia.find((dt) => dt == item.id)
        ? valid.push(item.id)
        : null;
    });
    return valid;
  }

  getAggregato() {
    this.aggregatoService.getAll().subscribe((res) => {
      let filter: any[];
      let kp: any[] = [];
      this.myForm.value.status.map((item) => {
        filter = res.filter((x) => x.tipologiaIntervento.codice == item);
        kp = kp.concat(filter);
      });
      this.f.dettaglioTipologia.setValue(
        this.resetDettaglioTipologiaChecks(kp)
      );
      this.aggregatoItems = kp;
    });
  }

  isFiltered(): boolean {
    if (
      this.filterObject.filterTipoClassificazione !== -1 ||
      this.filterObject.filterTemaProprietario !== -1 ||
      this.filterObject.filterSoggettoGiuridico !== -1 ||
      this.filterObject.filterProvinciaSoggettoGiuridico !== "all" ||
      this.filterObject.filterProjectId >= 0 ||
      this.filterObject.filterMainInterventionId >= 0 ||
      this.filterObject.filterSimpleInterventionId >= 0 ||
      this.filterObject.filterFinanziamento !== "" ||
      (this.filterObject.filterFonteFinanziamentoIds &&
        this.filterObject.filterFonteFinanziamentoIds.length > 0 &&
        this.filterObject.filterFonteFinanziamentoIds[0] != -1) ||
      (this.filterObject.filterStatus &&
        this.filterObject.filterStatus.length > 0 &&
        this.filterObject.filterStatus[0] != -1) ||
      this.filterObject.filterAreeGestionaliDirezioneEsterne >= 0 ||
      (this.filterObject.filterTipologiaIntervento &&
        this.filterObject.filterTipologiaIntervento.length > 0 &&
        this.filterObject.filterTipologiaIntervento[0] != -1) ||
      (this.filterObject.filterLineeFinanziamentiPnrr &&
        ((this.filterObject.filterLineeFinanziamentiPnrr.missions &&
          this.filterObject.filterLineeFinanziamentiPnrr.missions.length > 0) ||
          (this.filterObject.filterLineeFinanziamentiPnrr.components &&
            this.filterObject.filterLineeFinanziamentiPnrr.components.length >
              0) ||
          (this.filterObject.filterLineeFinanziamentiPnrr.lines &&
            this.filterObject.filterLineeFinanziamentiPnrr.lines.length >
              0))) ||
      this.filterObject.filterPnrr > -1 ||
      this.filterObject.filterProvince > -1 ||
      this.filterObject.filterComune > -1 ||
      (this.filterObject.filterAggregato.length > 0 &&
        this.filterObject.filterAggregato[0] != -1) ||
      this.filterObject.rp > -1
    )
      this.isShowingFiltered = true;
    else this.isShowingFiltered = false;
    return this.isShowingFiltered;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.myForm.controls;
  }

  setProject($event) {
    this.description = $event ? $event.descrizione : "";
    this.filterObject.filterProjectId = $event ? $event.id : -1;
    this.filterObject.filterMainInterventionId = $event
      ? $event.mainInterventoId
      : -1;
    this.f.intervention.enable();
    this.f.intervention.setValue(null);
    this.filterObject.filterSimpleInterventionId = -1;
    if (!$event) {
      this.interventiCombo = [];
      this.f.intervention.disable();
    }
    this.fillInterventions = true;
  }

  onChangeIntervention($event) {
    !$event.target.value && this.setIntervention(null);
  }

  onChangeFiltroFinanziamento($event) {
    !$event.target.value && this.setFinanziamento(null);
  }

  setInterventionCompleter($event) {
    this.setIntervention($event);
  }

  setIntervention($event) {
    this.f.status.enable();
    this.filterObject.filterSimpleInterventionId = $event ? $event.id : -1;
    this.interventionDescription = $event && $event.descrizione;
    this.fillInterventions = false;
  }

  setFinanziamento($event) {
    this.filterObject.filterFinanziamento = $event ? $event.codice : "";
    this.fillInterventions = false;
  }

  countNotMain() {
    return this.filteredInterventions.filter((i) => !i.isMain).length;
  }

  countMain() {
    return this.filteredInterventions.filter((i) => i.isMain).length;
  }

  setInterventionStatus($event) {
    this.filterObject.filterStatus = $event ? $event.map((x) => x.code) : [];
    this.fillInterventions = false;
  }

  setTipologiaIntervento($event) {
    this.filterObject.filterTipologiaIntervento = $event
      ? $event.map((x) => x.key)
      : [];
    this.filterObject.filterAggregato = [];
    this.fillInterventions = false;
    this.getAggregato();
  }

  setAreaTematica($event) {
    this.filterObject.filterAreeGestionaliDirezioneEsterne = $event
      ? $event.id
      : -1;
    this.fillInterventions = false;
  }

  setClassificazione($event) {
    this.filterObject.filterTipoClassificazione = $event ? $event.id : -1;
    $event ? (this.isHiddenChart67 = false) : (this.isHiddenChart67 = true);
    this.loadElencoClassificazioni();
  }

  setSoggetto($event) {
    this.filterObject.filterSoggettoGiuridico = $event ? $event.id : -1;
    this.fillInterventions = false;
  }

  setTema($event) {
    this.filterObject.filterTemaProprietario = $event ? $event.id : -1;
    console.log(this.filterObject.filterTemaProprietario);
    this.fillInterventions = false;
  }

  setProvinciaSoggettiGiuridici($event) {
    this.filterObject.filterProvinciaSoggettoGiuridico = $event
      ? $event.key
      : "all";
    this.fillInterventions = false;
  }

  setPnrrFilter($event) {
    this.filterObject.filterPnrr = $event ? $event.key : "-1";
    this.fillInterventions = false;
  }

  setProvincia($event) {
    this.filterObject.filterProvince = $event ? $event.key : "-1";
    this.filterObject.filterComune = -1;
    this.fillInterventions = false;
    this.getComune();
  }

  setComune($event) {
    this.filterObject.filterComune = $event ? $event.key : "-1";
    this.fillInterventions = false;
  }

  setAggregato($event) {
    this.filterObject.filterAggregato = $event ? $event.map((x) => x.id) : [];
    this.fillInterventions = false;
  }

  setLineeFinanziamento($event, type: string) {
    const values = $event.map((data) => data.codice.toUpperCase());
    switch (type) {
      case "mission":
        this.filterObject.filterLineeFinanziamentiPnrr.missions = values;
        break;
      case "component":
        this.filterObject.filterLineeFinanziamentiPnrr.components = values;
        break;
      case "line":
        this.filterObject.filterLineeFinanziamentiPnrr.lines = values;
        break;
    }
    this.fillInterventions = false;
  }

  setFrom($event) {
    this.filterObject.filterFrom = $event ? parseInt($event.anno) : -1;
    if (
      (this.filterObject.filterFrom > 0 &&
        this.filterObject.filterTo &&
        this.filterObject.filterFrom <= this.filterObject.filterTo) ||
      (this.filterObject.filterFrom === -1 && this.filterObject.filterTo === -1)
    ) {
      this.fillInterventions = false;
    }
  }

  setTo($event) {
    this.filterObject.filterTo = $event ? parseInt($event.anno) : -1;
    if (
      (this.filterObject.filterFrom > 0 &&
        this.filterObject.filterTo &&
        this.filterObject.filterFrom <= this.filterObject.filterTo) ||
      (this.filterObject.filterFrom === -1 && this.filterObject.filterTo === -1)
    ) {
      this.fillInterventions = false;
    }
  }

  elencoClassificazioni = [];
  loadElencoClassificazioni() {
    let that = this;
    this.classificazioniService
      .getClassificazioni(this.filterObject.filterTipoClassificazione)
      .subscribe((result) => {
        that.elencoClassificazioni = result;
      });
  }

  //se fiilIntervento è true riempie la combo degli interventi escludendo i main
  filterInterventions(fillIntervento: boolean, renderData: boolean) {
    if (renderData) {
      this.disableFilters();
      this.showLoading = true;
    } else {
      this.disposeCharts();
      this.showPage = false;
    }

    const arrayOfProjecId = this.filterObject.filterProjectId
      ? [this.filterObject.filterProjectId]
      : [-1];
    this.projectService.getInterventiSimpleData(this.filterObject).subscribe(
      (res) => {
        this.noresult = renderData && (!res || res.length === 0);
        this.showPage = renderData && !this.noresult;

        setTimeout(() => {
          this.completeFilterInterventions(
            fillIntervento,
            renderData,
            res,
            arrayOfProjecId
          );
        }, 1);
      },
      (error) => console.log(error)
    );
  }

  private completeFilterInterventions(
    fillIntervento: boolean,
    renderData: boolean,
    res: any[],
    arrayOfProjecId: number[]
  ) {
    if (renderData) {
      this.filteredInterventions = res.map((i) => {
        i["label"] = `${i.codice} - ${i.descrizione}`;
        return i;
      });
    }
    if (fillIntervento) {
      this.filteredInterventions = res.map((i) => {
        i["label"] = `${i.codice} - ${i.descrizione}`;
        return i;
      });
    }

    if (!renderData) {
      this.showLoading = false;
      this.enableFilters();
      return;
    }

    setTimeout(() => {
      this.map && this.map.setInterventions(this.filteredInterventions);

      const arrayOfMainId = this.filterObject.filterMainInterventionId
        ? [this.filterObject.filterMainInterventionId]
        : [-1];

      if (arrayOfMainId.length > 0 && arrayOfMainId[0] > 0) {
        this.map.createViewerLayer(arrayOfProjecId, null);
      } else {
        this.map.createViewerLayer(null, null);
      }
    }, 300);

    //layer con tutti gli interventi che non sono main
    this.onlyNormalIntervention = this.filteredInterventions.filter(
      (i) => !i.isMain
    );

    this.annualita = this.countImportiPerAnnualita();
    this.annualita && this.countTotImporti(this.annualita);

    this.disposeCharts();
    this.renderCharts();
    this.executeGetValoriProgettiAsync().then((x) => {});
    this.loadDatiTabellaDettaglio();
  }

  annualita: Annualita[];

  async renderCharts() {
    am4core.useTheme(am4themes_animated);
    if (this.filterObject.filterTipoClassificazione >= 0) {
      this.setClassificazioniPerGrafici();
      this.renderChart6();
      this.renderChart7();
    }
    this.renderChart1();
    this.renderChart3();
    this.renderChart4();
    await this.renderChart5();

    this.showLoading = false;
    this.enableFilters();
  }

  aggiungiLogo(logoChart) {
    // Add watermark
    const watermark = new am4core.Image();
    watermark.href = "assets/img/logo_blu.svg";
    logoChart.tooltipContainer.children.push(watermark);
    watermark.align = "right";
    watermark.valign = "bottom";
    watermark.opacity = 0.3;
    watermark.marginRight = 10;
    watermark.marginBottom = 5;
    watermark.disabled = true;

    // Enable watermark on export
    logoChart.exporting.events.on("exportstarted", function (ev) {
      watermark.disabled = false;
    });

    // Disable watermark when export finishes
    logoChart.exporting.events.on("exportfinished", function (ev) {
      watermark.disabled = true;
    });

    // Add watermark to validated sprites
    logoChart.exporting.validateSprites.push(watermark);
  }
  /*---*/
  async renderChart1() {
    this.chart1 = am4core.create("chart1", am4charts.PieChart);
    let countDaValidare = 0;
    let countValidato = 0;
    let countCompletato = 0;

    let countFabbisogno = 0;
    let countTerritoriale = 0;
    let countIbrido = 0;

    this.onlyNormalIntervention.forEach((i) => {
      switch (i.validationStatus) {
        case "IDLE":
        case "REJECTED":
        case "WAITING":
        case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
        case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
        case "WAITING_FOR_REMOVE_APPROVAL":
          countDaValidare++;
          break;
        case "APPROVED":
          countValidato++;
          break;
        case "TERMINATO":
          countCompletato++;
          break;
        case "FABBISOGNO":
          countFabbisogno++;
          break;
        case "TERRITORIALE":
          countTerritoriale++;
          break;
        case "IBRIDO":
          countIbrido++;
          break;
      }
    });

    const data = [];

    countDaValidare &&
      data.push({
        title: "Da validare",
        value: countDaValidare,
        color: "#76bed0",
      });

    countValidato &&
      data.push({ title: "Validato", value: countValidato, color: "#007e33" });

    countCompletato &&
      data.push({
        title: "Completato",
        value: countCompletato,
        color: "#0083e0",
      });

    countFabbisogno &&
      data.push({
        title: "Fabbisogno",
        value: countFabbisogno,
        color: "#dc3545",
      });
    countTerritoriale &&
      data.push({
        title: "Rilevanza territoriale",
        value: countTerritoriale,
        color: "#3AB795",
      });
    countIbrido &&
      data.push({
        title: "Parzialmente finanziato",
        value: countIbrido,
        color: "#577590",
      });

    this.chart1.data = data;

    const pieSeries = this.chart1.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "title";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    pieSeries.labels.template.relativeRotation = 90;

    pieSeries.labels.template.adapter.add("radius", function (radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 2) {
        return 0;
      }
      return radius;
    });

    pieSeries.labels.template.adapter.add("fill", function (color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 2) {
        return am4core.color("#000");
      }
      return color;
    });

    this.chart1.legend = new am4charts.Legend();
    this.chart1.legend.position = "right";
    this.chart1.legend.itemContainers.template.clickable = false;
    this.chart1.legend.itemContainers.template.focusable = false;
    this.chart1.legend.itemContainers.template.cursorOverStyle =
      am4core.MouseCursorStyle.default;
    this.chart1.legend.maxWidth = undefined;
    const markerTemplate = this.chart1.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    // Add chart title
    const title = this.chart1.titles.create();
    title.marginTop = 15;
    title.marginBottom = 10;
    title.text = "Stato Interventi (Quantità)";
    this.chart1.radius = am4core.percent(80);

    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-50);
    pieSeries.labels.template.fill = am4core.color("white");
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.propertyFields.stroke = "color";

    this.exportChart(this.chart1, title.text);

    this.aggiungiLogo(this.chart1);
  }

  setCodiciClassificazioni() {
    let classificazioni = [];

    this.onlyNormalIntervention.forEach((i) => {
      classificazioni = [...classificazioni, ...i.classificazioni];
    });
    this.classifications = classificazioni;

    const classificazioniArray = [];

    this.classifications.forEach((c) => {
      const classificazioneTmp = this.elencoClassificazioni.find(
        (x) => x.id == c.id
      );
      const classificazione = {
        codice: c.codice,
        idTipologia: c.idTipoClassificazione,
        descrizione: classificazioneTmp ? classificazioneTmp.descrizione : "",
      };
      if (
        !classificazioniArray.find((x) => x.codice === classificazione.codice)
      ) {
        classificazioniArray.push(classificazione);
      }
    });

    this.classificationsCodes = [...new Set(classificazioniArray)];
    if (this.filterObject.filterTipoClassificazione >= 0) {
      this.classificationsCodes = this.classificationsCodes.filter((c) => {
        if (c.idTipologia == this.filterObject.filterTipoClassificazione)
          return c;
      });
    }

    return this.classificationsCodes;
  }

  setClassificazioniPerGrafici() {
    this.setCodiciClassificazioni();
    this.classificazioniGrafico = [];
    this.onlyNormalIntervention.forEach((i) => {
      i.classificazioni.forEach((c) => {
        this.classificationsCodes.forEach((code) => {
          if (code.codice && c.codice === code.codice) {
            const classif = new Classificazione();
            classif.codice = code.codice;
            classif.descrizione = code.descrizione;
            classif.count++;

            const importi = i.importi;
            const finanziamenti = i.finanziamenti;
            importi.forEach((imp) => {
              classif.importoI += imp.importo;
            });
            finanziamenti.forEach((f) => {
              let annualita = f.annualita;
              annualita.forEach((a) => {
                classif.importoF = a.importo;
              });
            });
            if (!this.classificazioniGrafico[code.codice]) {
              this.classificazioniGrafico[code.codice] = new Classificazione();
            }
            this.classificazioniGrafico[code.codice].count += classif.count;
            this.classificazioniGrafico[code.codice].codice = code.codice;
            this.classificazioniGrafico[code.codice].descrizione =
              code.descrizione;
            this.classificazioniGrafico[code.codice].importoI +=
              classif.importoI;
            this.classificazioniGrafico[code.codice].importoF +=
              classif.importoF;
            const diff = classif.importoI - classif.importoF;
            this.classificazioniGrafico[code.codice].differenza += diff;
          }
        });
      });
    });

    const data = [];
    for (let elementName in this.classificazioniGrafico) {
      const element = this.classificazioniGrafico[elementName];

      const obj = {};
      obj["codice"] = element.codice;
      obj["value"] = element.count;
      obj["importoI"] = element.importoI;
      obj["importoF"] = element.importoF;
      obj["diff"] = element.differenza;
      obj["descrizione"] = element.descrizione;
      data.push(obj);
    }
    return data;
  }

  async renderChart6() {
    function am4themes_myTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [am4core.color("#0083e0")];
      }
    }
    am4core.useTheme(am4themes_myTheme);
    am4core.useTheme(am4themes_animated);

    this.chart6 = am4core.create("chart6", am4charts.XYChart);
    this.chart6.scrollbarX = new am4core.Scrollbar();

    const title = this.chart6.titles.create();
    title.text = "Classificazioni (Quantità)";
    title.marginBottom = 0;

    let data = [];
    for (let elementName in this.classificazioniGrafico) {
      const element = this.classificazioniGrafico[elementName];

      const obj = {};
      obj["codice"] = element.codice;
      obj["value"] = element.count;

      data.push(obj);
    }
    this.chart6.data = this.setClassificazioniPerGrafici(); //data;

    this.chart6.events.on("ready", () => {
      const asse = that.chart6.xAxes.values[0];
      const catCount = that.chart6.data.length;
      if (catCount > 0 && catCount > 8) asse.zoomToIndexes(0, 8);
    });

    // Create axes
    const categoryAxis = this.chart6.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "codice";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "top";
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.rotation = -25;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 110;

    this.chart6.numberFormatter.numberFormat = "#.#";
    const valueAxis = this.chart6.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 50;

    // Create series
    const series = this.chart6.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "codice";
    series.columns.template.tooltipText = "{descrizione}:\n [bold]{valueY}[/b]";
    series.tooltip.label.maxWidth = 200;
    series.tooltip.label.wrap = true;
    series.tooltip.label.fontSize = 12;
    series.columns.template.strokeWidth = 0;

    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 120;
    label.tooltipText = "{category}";

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    const hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    const that = this;
    series.columns.template.adapter.add("fill", function (fill, target) {
      return that.chart6.colors.getIndex(target.dataItem.index);
    });

    // Cursor
    this.chart6.cursor = new am4charts.XYCursor();

    // Remove padding
    this.chart6.paddingBottom = 0;
    this.exportChart(this.chart6, title.text);

    this.aggiungiLogo(this.chart6);
  }

  renderChart7() {
    function am4themes_myTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#67b7dc"),

          am4core.color("#28a745"),
          am4core.color("#ff8800"),
        ];
      }
    }

    am4core.useTheme(am4themes_myTheme);
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    this.chart7 = am4core.create("chart7", am4charts.XYChart);
    this.chart7.scrollbarX = new am4core.Scrollbar();

    // Add percent sign to all numbers
    this.chart7.numberFormatter.numberFormat = "# a '€'";

    this.chart7.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];

    // Add chart title
    const title = this.chart7.titles.create();
    title.text = "Copertura Classificazioni";
    title.marginBottom = 0;

    const that = this;

    // Add data
    const data = this.setClassificazioniPerGrafici();
    data.forEach((element) => {
      element["percentage"] = element.importoI
        ? ((element.importoF * 100.0) / element.importoI).toFixed(0)
        : "0";
      element["diffpercentage"] = (
        100.0 - parseInt(element["percentage"])
      ).toString();
    });
    this.chart7.data = data;

    // Create axes
    const categoryAxis = this.chart7.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "codice";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.tooltip.disabled = true;

    const valueAxis = this.chart7.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    // Create series
    const series = this.chart7.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "importoI";
    series.calculatePercent = true;
    series.dataFields.categoryX = "codice";
    series.clustered = false;

    series.tooltipText = "{descrizione}:\n [bold]{valueY}[/]";
    series.tooltip.label.maxWidth = 300;
    series.tooltip.label.wrap = true;
    series.tooltip.label.fontSize = 12;
    series.tooltip.pointerOrientation = "vertical";
    series.name = "Valore";

    const series2 = this.chart7.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "importoF";
    series2.dataFields.categoryX = "codice";
    series2.clustered = true;
    series2.calculatePercent = true;
    series2.name = "Finanziato";
    series2.columns.template.width = am4core.percent(80);
    series2.tooltipText = "{descrizione}:\n [bold]{valueY}[/]";
    series2.tooltip.label.maxWidth = 300;
    series2.tooltip.label.wrap = true;
    series2.tooltip.label.fontSize = 12;
    series2.tooltip.pointerOrientation = "vertical";

    const bullet1 = series2.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{percentage}%";
    bullet1.label.fill = am4core.color("#000000");

    bullet1.label.dy = -10;
    bullet1.fontSize = 12;

    const series3 = this.chart7.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = "diff";
    series3.dataFields.categoryX = "codice";
    series3.clustered = true;
    series3.name = "Differenza";
    series3.calculatePercent = true;
    series3.columns.template.width = am4core.percent(80);
    series3.tooltipText = "{descrizione}:\n [bold]{valueY}[/]";
    series3.tooltip.label.maxWidth = 300;
    series3.tooltip.label.wrap = true;
    series3.tooltip.label.fontSize = 12;
    series3.tooltip.pointerOrientation = "vertical";

    const bullet2 = series3.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{diffpercentage}%";
    bullet2.label.dy = -10;
    bullet2.label.fill = am4core.color("#000000");
    bullet2.fontSize = 12;

    this.chart7.cursor = new am4charts.XYCursor();
    this.chart7.cursor.lineX.disabled = true;
    this.chart7.cursor.lineY.disabled = true;

    this.chart7.legend = new am4charts.Legend();
    this.chart7.legend.position = "bottom";
    this.chart7.legend.useDefaultMarker = true;

    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 110;

    const markerTemplate = this.chart7.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    this.chart7.events.on("ready", () => {
      const asse = that.chart7.xAxes.values[0];
      const catCount = that.chart7.data.length;
      if (catCount > 0 && catCount > 8) asse.zoomToIndexes(0, 8);
    });

    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.rotation = -25;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "top";

    this.exportChart(this.chart7, title.text);

    this.aggiungiLogo(this.chart7);
  }

  async renderChart2() {
    function am4themes_myTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#67b7dc"),

          am4core.color("#28a745"),
          am4core.color("#ff8800"),
        ];
      }
    }

    am4core.useTheme(am4themes_myTheme);

    // Create chart instance
    this.chart2 = am4core.create("chart2", am4charts.XYChart);

    // Add percent sign to all numbers
    this.chart2.numberFormatter.numberFormat = "# a '€'";

    this.chart2.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];

    // Add chart title
    const title = this.chart2.titles.create();
    title.text = "Copertura Progettualità/Fabbisogni";
    title.marginBottom = 20;

    // Add data

    const data = [
      {
        titolo: "",
        totInt: this.valoreTotaleProgetti,
        totFin: this.valoreTotaleFinanziatoProgetti,
        percentage:
          this.valoreTotaleProgetti > 0
            ? (
                (this.valoreTotaleFinanziatoProgetti * 100.0) /
                this.valoreTotaleProgetti
              ).toFixed(0)
            : "0",
        diff: this.valoreTotaleProgetti - this.valoreTotaleFinanziatoProgetti,
        diffPercentage:
          this.valoreTotaleProgetti > 0
            ? (
                ((this.valoreTotaleProgetti -
                  this.valoreTotaleFinanziatoProgetti) *
                  100.0) /
                this.valoreTotaleProgetti
              ).toFixed(0)
            : "0",
      },
    ];

    this.chart2.data = data;

    // Create axes
    const categoryAxis = this.chart2.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "titolo";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    const valueAxis = this.chart2.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    // Create series
    const series = this.chart2.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "totInt";
    series.dataFields.categoryX = "titolo";

    series.clustered = false;
    series.name = "Valore"; /*Valore Interventi*/

    series.columns.template.width = am4core.percent(80);

    series.tooltipText =
      "[bold]" + this.printValueAsCurrency(this.valoreTotaleProgetti) + " [/]";
    series.tooltip.pointerOrientation = "vertical";

    const series2 = this.chart2.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "totFin";
    series2.dataFields.categoryX = "titolo";
    series2.clustered = true;
    series2.name = "Finanziato";
    series2.calculatePercent = true;
    series2.columns.template.width = am4core.percent(50);

    const bullet1 = series2.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{percentage}%";
    bullet1.label.fill = am4core.color("#000000");

    bullet1.label.dy = -10;

    series2.tooltipText =
      "[bold]" +
      this.printValueAsCurrency(this.valoreTotaleFinanziatoProgetti) +
      " [/]";
    series2.tooltip.pointerOrientation = "vertical";

    const series3 = this.chart2.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = "diff";
    series3.dataFields.categoryX = "titolo";
    series3.clustered = true;
    series3.name = "Differenza";
    series3.calculatePercent = true;
    series3.columns.template.width = am4core.percent(50);

    const bullet2 = series3.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{diffPercentage}%";
    bullet2.label.dy = -10;
    bullet2.label.fill = am4core.color("#000000");

    series3.tooltipText =
      "[bold]" +
      this.printValueAsCurrency(
        this.valoreTotaleProgetti - this.valoreTotaleFinanziatoProgetti
      ) +
      " [/]";
    series3.tooltip.pointerOrientation = "vertical";

    this.chart2.cursor = new am4charts.XYCursor();
    this.chart2.cursor.lineX.disabled = true;
    this.chart2.cursor.lineY.disabled = true;

    this.chart2.legend = new am4charts.Legend();
    this.chart2.legend.position = "bottom";
    this.chart2.legend.useDefaultMarker = true;

    const markerTemplate = this.chart2.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    this.exportChart(this.chart2, title.text);

    this.aggiungiLogo(this.chart2);
  }

  private generateBullet(tipologia: any) {
    return this.tipologiaInterventiService.getAssetIcon(
      this.getTipologiaIntervento(tipologia.codice)
    );
  }

  private generateCounters() {
    const counters = {
      unmapped: this.onlyNormalIntervention.filter((i) => !i.drawType).length,
    };
    this.tipologieInterventi.forEach((tipoInter: any) => {
      let hits = this.onlyNormalIntervention.filter(
        (i) => i.drawType === tipoInter.codice
      ).length;
      counters[tipoInter.codice] = hits;
    });
    return counters;
  }

  private generateDataset(counters) {
    return Object.keys(counters)
      .filter((key) => key != "unmapped")
      .map((counterName) => {
        let tipologia = this.getTipologiaIntervento(counterName);
        return {
          name: tipologia.descrizione,
          points: counters[counterName],
          color: this.chart3.colors.next(),
          bullet: this.generateBullet(tipologia),
        };
      });
  }

  async renderChart3() {
    /* --- */

    // Create chart instance
    this.chart3 = am4core.create("chart3", am4charts.XYChart);

    this.chart3.numberFormatter.numberFormat = "#.#";

    const counters = this.generateCounters();

    // Add chart title
    const title = this.chart3.titles.create();
    title.text = "Tipologia Interventi";
    title.marginBottom = 0;
    //title.marginTop = 15;

    // Add data
    this.chart3.data = this.generateDataset(counters);
    this.chart3.data = this.chart3.data.filter((x) => x.points > 0);

    // Create axes
    const categoryAxis = this.chart3.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.inside = false;
    categoryAxis.renderer.labels.template.fill = am4core.color("#000");
    categoryAxis.renderer.labels.template.rotation = -45;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.verticalCenter = "top";
    categoryAxis.renderer.labels.template.horizontalCenter = "right";

    const valueAxis = this.chart3.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.strokeDasharray = "4,4";
    //valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Do not crop bullets
    this.chart3.maskBullets = false;

    // Remove padding
    this.chart3.paddingBottom = 0;

    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 220;

    // Create series
    const series = this.chart3.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "points";
    series.dataFields.categoryX = "name";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.column.cornerRadiusTopLeft = 15;
    series.columns.template.column.cornerRadiusTopRight = 15;
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/b]";

    //this.chart3.scrollbarY = new am4core.Scrollbar();
    this.chart3.scrollbarX = new am4core.Scrollbar();

    // Add bullets
    const bullet = series.bullets.push(new am4charts.Bullet());
    const image = bullet.createChild(am4core.Image);
    image.horizontalCenter = "middle";
    image.verticalCenter = "bottom";
    image.dy = 20;
    image.y = am4core.percent(100);
    image.widthRatio = 0.6;
    image.propertyFields.href = "bullet";
    image.tooltipText = series.columns.template.tooltipText;
    image.propertyFields.fill = "color";
    image.filters.push(new am4core.DropShadowFilter());

    //this.chart3.paddingTop = 40;

    this.exportChart(this.chart3, "grafico");

    this.aggiungiLogo(this.chart3);
  }

  async renderChart5() {
    function am4themes_myTheme(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#67b7dc"),

          am4core.color("#28a745"),
          am4core.color("#ff8800"),
        ];
      }
    }

    am4core.useTheme(am4themes_myTheme);
    // Create chart instance
    this.chart5 = am4core.create("chart5", am4charts.XYChart);

    // Add percent sign to all numbers
    this.chart5.numberFormatter.numberFormat = "# a '€'";

    this.chart5.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];

    // Add chart title
    const title = this.chart5.titles.create();
    title.text = "Copertura Interventi";
    title.marginBottom = 20;

    // Add data
    const data = [
      {
        titolo: "",
        totInt: this.totInt,
        totFin: this.totFin,
        percentage: this.totInt
          ? ((this.totFin * 100.0) / this.totInt).toFixed(0)
          : "0",
        diff: this.totInt - this.totFin,
        diffPercentage: this.totInt
          ? (((this.totInt - this.totFin) * 100.0) / this.totInt).toFixed(0)
          : "0",
      },
    ];

    this.chart5.data = data;

    // Create axes
    const categoryAxis = this.chart5.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "titolo";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    const valueAxis = this.chart5.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    // Create series
    const series = this.chart5.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "totInt";
    series.dataFields.categoryX = "titolo";

    series.clustered = false;
    series.name = "Valore"; /*Valore Interventi*/
    series.columns.template.width = am4core.percent(80);
    series.tooltipText =
      "[bold]" + this.printValueAsCurrency(this.totInt) + " [/]";
    series.tooltip.pointerOrientation = "vertical";

    const series2 = this.chart5.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "totFin";
    series2.dataFields.categoryX = "titolo";
    series2.clustered = true;
    series2.name = "Finanziato";
    series2.calculatePercent = true;
    series2.columns.template.width = am4core.percent(50);
    const bullet1 = series2.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{percentage}%";
    bullet1.label.fill = am4core.color("#000000");
    bullet1.label.dy = -10;
    series2.tooltipText =
      "[bold]" + this.printValueAsCurrency(this.totFin) + " [/]";
    series2.tooltip.pointerOrientation = "vertical";

    const series3 = this.chart5.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = "diff";
    series3.dataFields.categoryX = "titolo";
    series3.clustered = true;
    series3.name = "Differenza";
    series3.calculatePercent = true;
    series3.columns.template.width = am4core.percent(50);
    const bullet2 = series3.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{diffPercentage}%";
    bullet2.label.fill = am4core.color("#000000");
    bullet2.label.dy = -10;

    series3.tooltipText =
      "[bold]" + this.printValueAsCurrency(this.totInt - this.totFin) + " [/]";
    series3.tooltip.pointerOrientation = "vertical";

    this.chart5.cursor = new am4charts.XYCursor();
    this.chart5.cursor.lineX.disabled = true;
    this.chart5.cursor.lineY.disabled = true;

    this.chart5.legend = new am4charts.Legend();
    this.chart5.legend.position = "bottom";
    this.chart5.legend.useDefaultMarker = true;
    this.chart5.legend.maxWidth = 160;
    const markerTemplate = this.chart5.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    this.exportChart(this.chart5, title.text);

    this.aggiungiLogo(this.chart5);
  }

  async renderChart4() {
    // Create chart instance
    this.chart4 = am4core.create("chart4", am4charts.XYChart);
    this.annualita &&
      this.annualita.forEach((a) => {
        a["percentage"] = a.importoI
          ? ((a.importoF * 100.0) / a.importoI).toFixed(0)
          : "0";
        a.importoFormattatoI = this.printValueAsCurrency(a.importoI);
        a.importoFormattatoF = this.printValueAsCurrency(a.importoF);
      });

    if (this.filterObject.filterFrom > 0 && this.filterObject.filterTo > 0) {
      let dataFiltrato = [];
      for (
        let i = this.filterObject.filterFrom;
        i <= this.filterObject.filterTo;
        i++
      ) {
        const elements = this.annualita.filter((a) => +a.anno == i);
        dataFiltrato = [...dataFiltrato, ...elements];
      }
      this.annualita = dataFiltrato;
    }

    this.chart4.data = this.annualita;

    // Create axes
    const categoryAxis = this.chart4.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "anno";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.tooltip.disabled = true;

    const valueAxis = this.chart4.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    // Create series
    const series = this.chart4.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "importoI";
    series.dataFields.categoryX = "anno";
    series.clustered = false;
    series.name = "Valore"; /*Valore Interventi*/
    series.tooltipText = "[bold]{importoFormattatoI} [/]";
    series.columns.template.fill = am4core.color("#67b7dc"); //  fill azzurro
    series.columns.template.stroke = am4core.color("#67b7dc"); // outline
    series.tooltip.pointerOrientation = "vertical";

    const series2 = this.chart4.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "importoF";
    series2.dataFields.categoryX = "anno";
    series2.clustered = false;
    series2.name = "Finanziato";
    series2.columns.template.width = am4core.percent(50);
    series2.columns.template.fill = am4core.color("#28a745"); //  fill verde
    series2.columns.template.stroke = am4core.color("#28a745"); // outline
    series2.tooltipText = "[bold]{importoFormattatoF} [/]";
    series2.tooltip.pointerOrientation = "vertical";

    series2.calculatePercent = true;

    const bullet1 = series2.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{percentage}%";
    bullet1.label.fill = am4core.color("#000000");
    bullet1.label.dy = -10;

    this.chart4.numberFormatter.numberFormat = "# a '€'";

    this.chart4.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "Mln" },
      { number: 1e9, suffix: "Mrd" },
    ];

    this.chart4.cursor = new am4charts.XYCursor();
    this.chart4.cursor.lineX.disabled = true;
    this.chart4.cursor.lineY.disabled = true;

    // Add chart title
    const title = this.chart4.titles.create();
    title.text = "Copertura per anno";
    title.marginBottom = 10;

    const that = this;

    // Add cursor
    this.chart4.cursor = new am4charts.XYCursor();

    // Add simple vertical scrollbar
    this.chart4.scrollbarX = new am4core.Scrollbar();

    this.chart4.legend = new am4charts.Legend();
    this.chart4.legend.position = "bottom";
    this.chart4.legend.paddingBottom = 20;
    // chart.legend.labels.template.maxWidth = 120
    const markerTemplate = this.chart4.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;
    this.chart4.responsive.enabled = true;

    this.chart4.events.on("ready", () => {
      const asse = that.chart4.xAxes.values[0];
      const catCount = that.chart4.data.length;
      if (catCount > 0 && catCount > 10) asse.zoomToIndexes(0, 10);
    });

    this.exportChart(this.chart4, title.text);

    this.aggiungiLogo(this.chart4);
  }

  countImportiPerAnnualita() {
    const annualita: Annualita[] = [];
    this.filteredInterventions.forEach((i) => {
      if (
        !i.isMain /*|| i.numSubInterventi <= 0*/ &&
        i.importi &&
        i.importi.length > 0
      ) {
        i.importi.forEach((imp) => {
          let currentAnnualita = annualita.find(
            (x) => x.anno == imp.anno.toString()
          );

          if (!currentAnnualita) {
            currentAnnualita = new Annualita();
            currentAnnualita.anno = imp.anno.toString();
            currentAnnualita.importoF = 0.0;
            currentAnnualita.importoI = 0.0;
            annualita.push(currentAnnualita);
          }
          currentAnnualita.importoI += imp.importo;
        });
      }

      if (
        (!i.isMain || i.numSubInterventi <= 0) &&
        i.finanziamenti &&
        i.finanziamenti[0]
      ) {
        i.finanziamenti[0].annualita.forEach((imp) => {
          let currentAnnualita = annualita.find((x) => x.anno == imp.anno);
          if (!currentAnnualita) {
            currentAnnualita = new Annualita();
            currentAnnualita.anno = imp.anno;
            currentAnnualita.importoF = 0.0;
            currentAnnualita.importoI = 0.0;
            annualita.push(currentAnnualita);
          }
          currentAnnualita.importoF += imp.importo;
        });
      }
    });
    annualita.sort((a, b) => {
      return a["anno"] > b["anno"] ? 1 : a.anno < b.anno ? -1 : 0;
    });
    return annualita;
  }

  countTotImporti(annualita: Annualita[]) {
    this.totInt = 0;
    this.totFin = 0;

    annualita &&
      annualita.length > 0 &&
      annualita.forEach((a) => {
        this.totInt += a.importoI;
        this.totFin += a.importoF;
      });
  }

  printValueAsCurrency(value) {
    value = typeof value === "number" ? value : value.replace(",", ".").trim();
    const format = "1.2-2";
    const currency = "€";
    const currentLocale: string = "it";
    return new CurrencyPipe(currentLocale).transform(
      value,
      "EUR",
      currency,
      format,
      "it-IT"
    );
  }

  getValoriProgetti() {
    //ottengo i valori degli importi e dei finanziamenti dei progetti. Non importa di che anno visto che mi serve il totale
    this.valoreTotaleFinanziatoProgetti = 0;
    this.valoreTotaleProgetti = 0;
    this.filteredInterventions
      .filter((x) => x.isMain)
      .forEach((element) => {
        if (element.importi)
          element.importi.forEach(
            (importo) => (this.valoreTotaleProgetti += importo.importo)
          );
        if (element.finanziamenti)
          element.finanziamenti.forEach((finanziamento) =>
            finanziamento.annualita.forEach(
              (finImporto) =>
                (this.valoreTotaleFinanziatoProgetti += finImporto.importo)
            )
          );
      });
    this.renderChart2();
  }

  async executeGetValoriProgettiAsync() {
    return new Promise((resolve) => {
      //console.log(`dentro executeGetValoriProgettiAsync`);
      this.getValoriProgetti();
    });
  }
  async executeGetTabellaDettaglioObjAsync() {
    this.tabellaDettaglioObjLoaded = false;
    return new Promise((resolve) => {
      //console.log(`dentro executeGetTabellaDettaglioObjAsync`);
      this.getTabellaDettaglioObj(this.filteredInterventions);
    });
  }
  tabellaDettaglioObj: any;
  tabellaDettaglioObjArrayAnni: any;
  tabellaDettaglioObjLoaded = false;
  subjectTabDettaglio = new Subject<any>();
  observable$ = this.subjectTabDettaglio.asObservable();

  getTabellaDettaglioObj(filteredInterventions) {
    this.tabellaDettaglioObjArrayAnni = [];
    this.tabellaDettaglioObj = {};
    this.tabellaDettaglioObj.progetti = [];
    const myTempArray = [];
    filteredInterventions
      .filter((x) => x.isMain)
      .forEach((progetto) => {
        myTempArray.push(progetto);
        progetto.importi.forEach((x) =>
          this.tabellaDettaglioObjArrayAnni.push(x.anno)
        );

        progetto["interventi"] = filteredInterventions.filter(
          (x) =>
            !x.isMain /*|| x.numSubInterventi <= 0*/ &&
            x["progettoId"] == progetto["progettoId"]
        );
      });
    const mySet = new Set(
      this.tabellaDettaglioObjArrayAnni.sort((a, b) => a - b)
    );
    this.tabellaDettaglioObjArrayAnni = [...mySet];

    this.tabellaDettaglioObj.progetti = myTempArray;
    this.tabellaDettaglioObj.anni = this.tabellaDettaglioObjArrayAnni;

    this.subjectTabDettaglio.next(this.tabellaDettaglioObj);

    const arrayOfProjecId = this.filterObject.filterProjectId
      ? [this.filterObject.filterProjectId]
      : [-1];

    this.filterObjectForDetailTable = {
      progettoId: arrayOfProjecId[0],
      interventoId: this.filterObject.filterSimpleInterventionId,
      validationStatusCode: this.filterObject.filterStatus,
      idAreaTematica: this.filterObject.filterAreeGestionaliDirezioneEsterne,
      idTipoClassificazione: this.filterObject.filterTipoClassificazione,
      idSoggetto: this.filterObject.filterSoggettoGiuridico,
      anno: "all",
      provinciaSoggetto: this.filterObject.filterProvinciaSoggettoGiuridico,
      idTema: this.filterObject.filterTemaProprietario,
      annoFrom: this.filterObject.filterFrom,
      annoTo: this.filterObject.filterTo,
      description: "",
      tipologiaIntervento: this.filterObject.filterTipologiaIntervento,
      filterFinanziamento: this.filterObject.filterFinanziamento,
      filterFonteFinanziamento: this.filterObject.filterFonteFinanziamentoIds,
      filterPnrr: this.filterObject.filterPnrr,
      filterLineeFinanziamentiPnrr:
        this.filterObject.filterLineeFinanziamentiPnrr,
      filterProvince: this.filterObject.filterProvince,
      filterComune: this.filterObject.filterComune,
      filterAggregato: this.filterObject.filterAggregato,
    };

    // this.tabellaDettaglioObjLoaded = true;
  }

  filterObjectForDetailTable = null;

  loadDatiTabellaDettaglio() {
    this.tabellaDettaglioObjLoaded = false;
    const arrayOfProjecId = this.filterObject.filterProjectId
      ? [this.filterObject.filterProjectId]
      : [-1];
    this.projectService
      .getInterventiSimpleDataWithProjects(this.filterObject)
      .subscribe((result) => {
        this.getTabellaDettaglioObj(result);
      });
  }

  loadingExportExcel = false;
  doEsportaExcel() {
    this.loadingExportExcel = true;
    this.filterObject.filterConClassificazioni =
      this.exportForm.controls.conClassificazioni.value;
    if (!this.filterObject.filterConClassificazioni)
      this.filterObject.filterConClassificazioni = false;
    const arrayOfProjecId = this.filterObject.filterProjectId
      ? [this.filterObject.filterProjectId]
      : [-1];

    this.filterObject.isSisma = this.isSisma;

    this.dashboardService
      .getDashboardFinanziariaExport(this.filterObject)
      .subscribe(
        (res) => {
          let filename = `esportazione_sintesi_${moment(new Date()).format(
            "YYYY_MM_DDTHH_mm_ss"
          )}.xlsx`;
          FileSaver.saveAs(res.body, `${filename}`);
          this.loadingExportExcel = false;
        },
        (error) => {
          this.toastr.error(
            `Errore: ${
              error.error.message
                ? error.error.message
                : error.error.error_description
                ? error.error.error_description
                : error.error
            }`,
            null,
            {
              timeOut: 2000,
              disableTimeOut: false,
            }
          );
          //console.log("error");
          this.loadingExportExcel = false;
        }
      );
  }

  exportChart(chart, title) {
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.background = "#ff0000";
    chart.exporting.menu.defaultStyles = true;
    chart.exporting.filePrefix = title;
    chart.exporting.menu.items = [
      {
        menu: [
          { type: "jpg", label: "JPG" },
          { type: "png", label: "PNG" },
          { type: "csv", label: "CSV" },
        ],
      },
    ];
    chart.exporting.menu.items[0].icon = "assets/img/export.png";
  }

  @ViewChild("modalExportData", { static: true })
  public modalExportData: TemplateRef<any>;
  modalEsportazioneRef: BsModalRef;
  esportaExcel() {
    if (
      !this.filterObject.filterTipoClassificazione ||
      this.filterObject.filterTipoClassificazione === -1
    ) {
      this.doEsportaExcel();
      return;
    }
    const config = {
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      class: "modal-xs",
    };
    this.modalEsportazioneRef = this.modalService.show(
      this.modalExportData,
      config
    );
  }
  closeModalEsportazione() {
    this.modalEsportazioneRef.hide();
  }

  subjectTabDettaglioDoingExport = new Subject<any>();
  subjectTabDettaglioDoingExport$ =
    this.subjectTabDettaglioDoingExport.asObservable();
  exportXlsDetailTableData($event) {
    console.log("invoke exportXlsDetailTableData");
    this.loadingExportExcel = true;
    this.subjectTabDettaglioDoingExport.next({ value: true });
    this.dashboardService
      .getDashboardFinanziariaDetailTableExport({
        filterProjectId: this.filterObjectForDetailTable.progettoId,
        filterSimpleInterventionId:
          this.filterObjectForDetailTable.interventoId,
        filterStatus: this.filterObjectForDetailTable.validationStatusCode,
        filterAreeGestionaliDirezioneEsterne:
          this.filterObjectForDetailTable.idAreaTematica,
        filterTipoClassificazione:
          this.filterObjectForDetailTable.idTipoClassificazione,
        filterSoggettoGiuridico: this.filterObjectForDetailTable.idSoggetto,
        filterProvinciaSoggettoGiuridico:
          this.filterObjectForDetailTable.provinciaSoggetto,
        filterTemaProprietario: this.filterObjectForDetailTable.idTema,
        filterFrom: this.filterObjectForDetailTable.annoFrom,
        filterTo: this.filterObjectForDetailTable.annoTo,
        filterTipologiaIntervento:
          this.filterObjectForDetailTable.tipologiaIntervento,
        filterFinanziamento:
          this.filterObjectForDetailTable.filterFinanziamento,
        filterFonteFinanziamentoIds:
          this.filterObjectForDetailTable.filterFonteFinanziamento,
        filterPnrr: this.filterObjectForDetailTable.filterPnrr,
        filterLineeFinanziamentiPnrr:
          this.filterObjectForDetailTable.filterLineeFinanziamentiPnrr,
        filterProvince: this.filterObjectForDetailTable.filterProvince,
        filterComune: this.filterObjectForDetailTable.filterComune,
        filterAggregato: this.filterObjectForDetailTable.filterAggregato,
      })
      .subscribe(
        (res) => {
          this.loadingExportExcel = false;
          this.subjectTabDettaglioDoingExport.next({ value: false });
          const filename = `esportazione_sintesi_dettaglio_${moment(
            new Date()
          ).format("YYYY_MM_DDTHH_mm_ss")}.xls`;
          FileSaver.saveAs(res.body, `${filename}`);
          this.loadingExportExcel = false;
        },
        (error) => {
          this.loadingExportExcel = false;
          this.subjectTabDettaglioDoingExport.next({ value: false });
          this.toastr.error(
            `Errore: ${
              error.error.message
                ? error.error.message
                : error.error.error_description
                ? error.error.error_description
                : error.error
            }`,
            null,
            {
              timeOut: 2000,
              disableTimeOut: false,
            }
          );
          //console.log("error");
          this.loadingExportExcel = false;
          this.subjectTabDettaglioDoingExport.next({ value: false });
        }
      );
  }

  //filtro fonte fianziamento
  fontiFianziamentoList = [];

  setFonteFinanziamento($event) {
    this.filterObject.filterFonteFinanziamentoIds = $event
      ? $event.map((x) => x.id)
      : [];
    this.fillInterventions = true;
    //this.filterInterventions(this.fillInterventions, false);
  }

  private elencoTipiFinSubscription = null;
  getFontiFinanziamento() {
    this.elencoTipiFinSubscription =
      this.storageService.elencoTipiFinanziamento.subscribe((x) => {
        console.log(x);
        this.fontiFianziamentoList = x ? x : [];
      });
  }

  getTipologiaIntervento(codiceTipo: string) {
    return this.tipologieInterventi.find(
      (tipo: any) => tipo.codice == codiceTipo
    );
  }

  getKnownTipologiaInterventiTypes() {
    return this.tipologieInterventi.map((i: any) => i.codice);
  }

  getTipologiaInterventi() {
    this.tipologiaInterventiService.getTipologiaInterventi().subscribe(
      (result) => {
        this.tipologieInterventi = result;
        this.tipologieInterventoForSelect();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isCustomHeader() {
    return this.authService.getHeaderCode() != "generale";
  }
  getHeaderLabel() {
    return this.isCustomHeader()
      ? "Piano Nazionale Ripresa e Resilienza"
      : "Direzione Regionale Infrastrutture e Mobilità";
  }
  isAdmin() {
    return this.authService.isAdmin();
  }
  isGr34() {
    return this.authService.isGr34();
  }

  countSubIntervents() {
    return this.filteredInterventions.filter(
      (i) => i.isMain && i.numSubInterventi > 0
    ).length;
  }
}
