<div class="modal-header text-center">
    <h4 class="modal-title pull-left">Seleziona soggetti giuridici</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">




    <section class="fascia-grey-login" style="margin:0px;padding-top:20px">
        <div class="container">
            <div class="row clearfix">
                <div class="col-lg-12 ">
                    <form [formGroup]="myGroup" novalidate>
                        <div class="form-registrazione">

                            <div class="user-type">
                                <h5><label for="role" class="mandatory">Ruolo:</label></h5>

                                <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                                    <ng-select [formGroup]="myGroup" formControlName="role" [items]="roles" bindValue="id" bindLabel="ruolo" placeholder="Seleziona ruolo">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && f.role.errors" class="invalidFieldMessage" style="display: block;">
                                    <div *ngIf="f.role.errors" class="invalid-feedback" style="display: block;">
                                        Ruolo soggetto &egrave; obbligatorio
                                    </div>
                                </div>
                            </div>
                            <div class="user-type">
                                <h5> <label for="soggettoGiuridico" class="mandatory">Soggetto Giuridico:</label></h5>

                                <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.soggettoGiuridico.errors }">
                                    <ng-select [formGroup]="myGroup" formControlName="soggettoGiuridico" [items]="soggettiGiuridici" bindValue="id" bindLabel="descrizione" placeholder="Seleziona soggetto giuridico">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && f.soggettoGiuridico.errors" class="invalidFieldMessage" style="display: block;">
                                    <div *ngIf="f.soggettoGiuridico.errors" class="invalid-feedback" style="display: block;">
                                        Soggetto giuridico &egrave; obbligatorio
                                    </div>
                                </div>
                            </div>


                            <button class="btn btn-lg btn-primary btn-block" type="button" (click)="add()" style="margin:15px 0">
                Aggiungi
              </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>