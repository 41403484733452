import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { GeoService } from "../../../../../services";
import { AggregatoService } from "../../../services";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  templateUrl: "./geolocalizzazione-selection-modal.component.html",
  styleUrls: ["./geolocalizzazione-selection-modal.component.css"],
})
export class GeolocalizzazioneSelectionModalComponent implements OnInit {
  // Inizializzazione campi
  province = [];
  municipality = [];
  aggregato = [];
  id: number;
  public quantity: any = {
    mask: "KK",
    lazy: false, // make placeholder always visible

    blocks: {
      KK: {
        mask: Number,
        /*thousandsSeparator: '.',*/
        scale: 0,
        padFractionalZeros: false,
        radix: ",",
        min: 0,
      },
    },
  };

  myGroup: FormGroup;
  submitted: boolean = false;
  // Queste variabili sono passate tramite l'initialState della modale
  @Input() keySelected!: any; 
  @Input() tipologyCode!: any; // Questa variabile viene passata tramite l'initialState della modale

  @Output() onSubmitOk = new EventEmitter();

  constructor(
    private geoService: GeoService,
    private aggregatoService: AggregatoService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.myGroup = this.initializeFormGroup();
    this.getProvince();
    this.getAggregato();
  }

  initializeFormGroup() {
    if (this.keySelected == "01")
      return this.formBuilder.group({
        province: [null, [Validators.required]],
        municipality: [null, [Validators.required]],
        specificaAggregata: [null, [Validators.required]],
        quantity: [null, [Validators.required]],
        address: [null],
      });
    else
      return this.formBuilder.group({
        province: [null, [Validators.required]],
        municipality: [null, [Validators.required]],
        specificaAggregata: [null, [Validators.required]],
        quantity: [null],
        address: [null],
      });
  }

  getProvince() {
    this.geoService.getProvinces("12").subscribe((res) => {
      // console.log("valore result: ", res);
      let ress: any[];
      ress = res;
      ress.map((i) => {
        return i.provincia;
      });
      this.province = ress;
    });
  }

  getMunicipality() {
    this.f.municipality.setValue(null);
    this.geoService
      .getMunicipalities(this.myGroup.value.province)
      .subscribe((res) => {
        // console.log("valore result: ", res);
        let ress: any[];
        ress = res;
        ress.map((i) => {
          return i.municipality;
        });
        this.municipality = ress;
      });
  }

  getAggregato() {
    this.aggregatoService.getAll()
    .subscribe(res =>{
      let kp: any[];
      kp = res.filter(x => x.tipologiaIntervento.codice == this.tipologyCode);
      this.aggregato = kp;
    })
  }

  get f() {
    return this.myGroup.controls;
  }

  add() {
    console.log("onSubmit");
    this.submitted = true;
    this.id = Date.now();
    var errors = false;
    for (let c in this.f) {
      errors = errors || this.f[c].errors !== null;
      console.log(
        "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
      );
    }

    console.log("errors:" + errors);
    if (this.myGroup.invalid || errors) {
      console.log("invalid");
      return false;
    }
    let provincia = this.province.find(
      (x) => x.key == this.myGroup.value.province
    );
    let comune = this.municipality.find(
      (x) => x.key == this.myGroup.value.municipality
    );
    let agg = this.aggregato.find(
      (x) => x.id == this.myGroup.value.specificaAggregata
    );
    let obj = {
      provincia: {
        id: provincia.id,
        code: provincia.key,
        name: provincia.value,
        acronym: "",
        isCittametropolitana: "",
        fromDate: Date,
        toDate: Date,
      },
      comune: {
        id: comune.id,
        istatCode: comune.key,
        cadastreCode: "",
        name: comune.value,
        fromDate: Date,
        toDate: Date,
        provinceId: provincia.id,
        provinceName: provincia.value,
      },
      aggregato: {
        id: agg.id,
        name: agg.name,
        tipologiaIntervento: agg.tipologiaIntervento,
      },
    };

    let data = Object.assign(
      {},
      { id: this.id },
      { provincia: obj.provincia },
      { comune: obj.comune },
      {
        quantita: this.myGroup.value.quantity
          ? this.myGroup.value.quantity
          : null,
      },
      { aggregato: obj.aggregato },
      { indirizzo: this.myGroup.value.address }
    );
    console.log("data passato al submit", data);

    this.onSubmitOk.emit(data);
  }
}
