<footer>
    <div class="footerinframob ">
        <div class="container-xl">
            <div class="row mr-0 ml-0">
                <div class="col-lg-12 text-center pt-3 mb-3">
                    <img src="{{logo}}" alt="logo" style="max-height:75px;">
                </div>
                <div class="col-lg-12 titolofooter">

                    <p><small>Contatti  |  Mail. <a class="font-white" href="mailto:inframob@regione.lazio.it">test@test.it</a></small></p>

                </div>
            </div>
        </div>
    </div>
</footer>
