<section class="fascia-grey-login" style="margin:0px;padding-top:20px">
  <div class="container v">
    <div class="row clearfix">
      <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-lg-2 offset-md-1">
        <h3 id="registrati" class="text-center mb-4"><b>Completa la registrazione</b></h3>

        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="account-wall">
         
            <div class="tab-content clearfix">          
              <!--main step-->
              <app-register-main-step  (OnSubmitDone)="mainStepDone($event)" [myUser]="registerUser" *ngIf="isMainStepEnabled()"></app-register-main-step>
              <!--TAB4-->
              <app-register-step4 *ngIf="isStep4Enabled()"></app-register-step4>
            </div>            
          </div>
        </form>
      </div>

      <div class="col-lg-4 col-md-4 col-sx-6 col-xs-12"></div>
    </div>
  </div>
</section>
