import { CurrencyPipe } from '@angular/common';
import {
    Component,
    OnInit,
    ElementRef,
    OnDestroy,
    ViewChild,
    TemplateRef,
    Input,
    EventEmitter,
    Output,
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators,
    AbstractControl,
    ValidatorFn,
} from "@angular/forms";
import { Progetto } from "../../models";
import {
    ProgettoService,
    AreeTematicheService,
    ClassificazioneService,
    TemaPrioritarioService,
    InterventoService,
    MotivazioniService,
    PnrrService,
    StorageService,
    BancaDatiEsternaService
} from "../../services";
import { AuthService } from "../../../../services/auth.service";
import { Subscription } from "rxjs";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { ToastService } from "src/app/services/toast.service";
import { GenericSelectionModalComponent } from "../genericSelectionModal/generic-selection-modal.component";
import { AlertMessageModalComponent } from "../alert-message-modal/alert-message-modal.component";
import { SoggettoGiuridicoSelectionModalComponent } from "./SoggettoGiuridicoSelectionModal/soggetto-giuridico-selection-modal.component";
import { GeolocalizzazioneSelectionModalComponent } from "./geolocalizzazione-selection-modal/geolocalizzazione-selection-modal.component";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { DashboardComponent } from "src/app/modules/web-gis/components/dashboard/dashboard.component";
import * as FileSaver from "file-saver";
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper"
import { TipologiaInterventiService } from '../../services/tipologia-interventi.service';
import { FormHelper } from 'src/app/helpers/form.helper';
import { NaturaTipologieService } from '../../services/natura-tipologie.service';
declare var $: any;
@Component({
    selector: "dati-generali-component",
    templateUrl: "./dati-generali.component.html",
    styleUrls: ["./dati-generali.component.css"],
})
export class DatiGeneraliComponent implements OnInit, OnDestroy {
    section: string = 'Interventi';
    public mask: any = {
        mask: 'KK €',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };

    public maskValue: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 2,
                padFractionalZeros: true,
                radix: ','
            }
        }
    };
    public maskValueNumber: any = {
        mask: 'KK',
        lazy: false,  // make placeholder always visible

        blocks: {
            KK: {
                mask: Number,
                thousandsSeparator: '.',
                scale: 0,
                padFractionalZeros: false,
                radix: ','
            }
        }
    };

    myGroup: FormGroup;
    submitted = false;
    loading = false;
    subscribed: Subscription;
    loadDataCounter: number = 0;
    modalOpts: ModalOptions = {
        backdrop: "static",
        ignoreBackdropClick: true,
        keyboard: false,
        animated: false,
    };
    currentProject = new Progetto();

    minYear: number = 1950;
    maxYear: number = new Date().getFullYear();
    soggettiGiuridici: any[];
    geolocalizzazione: any[];
    modalRef: BsModalRef;
    modalSubscriptions = [];

    areeTematicheIsError = false;
    classificazioniIsError = false;
    soggettiGiuridiciIsError = false;
    geolocalizzazioneIsError = false;
    temiPrioritariIsError = false;
    importiIsError = false;
    motivazioniIsError = false;

    isIntervento = false;
    esistonoInterventi = false;

    _interventoData: string; //{interventoId:number,projectId:number};

    projectId: number;

    soggettiRespArray = [];

    selectedTipologiaIntervento: any;

    openAsNewProject() {
        this.projectId = null;
    }

    isTipologiaInterventoMandatory = false;

    _projectData: any;

    naturaList = [];
    currentNaturaKey!: string;
    currentTipologiaKey!: string;

    isDatiStaticiOpen:boolean=false;
    isClassificazioniOpen:boolean=false;

    @Input()
    set projectData(data) {
        this._projectData = data;
        this.projectId = data?.progettoId;
        this.myGroup.reset();
        this.pnrrLineaFinanziamentoSelezionata = null;
        this.submitted = false;
        if (!this.projectId) {
            this.currentProject = new Progetto();
            this.currentProject['isMain'] = true;
            this.onFillFormCompleteOk.emit(true);
            this.initializeTipologieIntervento();
            this.hideTipologia = true;
            this.esistonoVecchieMotivazioni = false;
            this.esistonoInterventi = false;
            this.isIntervento = false;
            this.setNonLocalizedInterventoValidators();
            if (!this.isAdmin() && !this.isGr34()) {
                if (!this.gestisciNonPnrr()) {
                    this.f.pnrrRilevante.setValue('1');
                    this.f.pnrrRilevante.disable();
                    this.pnrrRilevanteChange();
                }
                if (!this.gestisciPnrr()) {
                    this.f.pnrrRilevante.setValue('0');
                    this.f.pnrrRilevante.disable();
                    this.pnrrRilevanteChange();
                }
            }
            this.setIsDirty(false);

            return;
        }
        this.getProjectData(data);
    }

    getProjectData(data: any) {
        if (!this.projectId || this.projectId <= 0) {
            return;
        }
        this.loadDataCounter++;
        if(!data.localizzazioni) data.localizzazioni = [];
        this.currentProject = data;
        this.hideRup(null);
        this.currentProject["importi"].sort((a, b) => a.anno - b.anno);
        this.validato = false;
        let status = data["validazione"]
            ? data.validazione.validationStatus
                ? data.validazione.validationStatus
                : 0
            : 0;
        switch (status.toString().toUpperCase()) {
            case "1":
            case "APPROVED":
            case "4":
            case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
            case "5":
            case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
            case "TERMINATO":               
                this.validato = (!this.currentProject["pnrrRilevante"]);
                break;
        }
        this.readOnlyForm = data.isReadOnly || this.readOnlyForm || !this.canEdit();
        this.fillForm();
        this.esistonoVecchieMotivazioni = this.currentProject['motivazioni'] && this.currentProject['motivazioni'].length > 0;
        this.loadDataCounter--;
        this.progettoService.getInterventiCount(this.projectId).subscribe(
            result => {
                this.esistonoInterventi = result - 1 > 0;
                if (this.esistonoInterventi) {
                    this.setNonLocalizedInterventoValidators();
                    this.hideTipologia = true;
                }
                else {
                    this.setLocalizedInterventoValidators();
                }
                this.setIsDirty(false);
            }
        );


        this.setIsDirty(false);
    }

    @Input()
    set interventoData(value) {
        this._interventoData = value;
        this.readOnlyForm = false;
        if (!value) return;
        this.getInterventoData(
            JSON.parse(this._interventoData).interventoId,
            JSON.parse(this._interventoData).projectData
        );
    }
    get interventoData() {
        return this._interventoData;
    }

    @Output() onFillFormCompleteOk: EventEmitter<any> = new EventEmitter();
    @Output() onSubmitOk: EventEmitter<any> = new EventEmitter();

    readOnlyForm: boolean = false;
    validato: boolean = false;
    types = [];
    hideTipologia = true;
    isRupHidden = true;

    constructor(
        private formBuilder: FormBuilder,
        private el: ElementRef,
        private progettoService: ProgettoService,
        private areeTematicheService: AreeTematicheService,
        private classificazioneService: ClassificazioneService,
        private temaPrioritarioService: TemaPrioritarioService,
        private modalService: BsModalService,
        private toastr: ToastService,
        private interventoService: InterventoService // private maskPipe:MaskPipe
        , private isDirtyHelper: IsDirtyHelper
        , private authService: AuthService
        , private motivazioneService: MotivazioniService
        , private pnrrService: PnrrService
        , private storageService: StorageService,
        private tipologiaInterventiService: TipologiaInterventiService,
        private formHelper: FormHelper
        , private naturaTipologieService: NaturaTipologieService,
        private bancheDatiEsterneService:BancaDatiEsternaService
    ) {
        this.myGroup = this.initializeFormGroup();
    }

    ngOnInit() {
        
        this.initializeTipologieIntervento();
        this.getPrioritaMotivazioni();
        this.getSoggettiResponsabili();
        this.getRandomNumber();
        this.getNaturaInterventoList(null);
    }


    getNaturaInterventoList(callBack) {
        console.log('getNaturaInterventoList, callBack!=null',callBack!=null);
        this.naturaTipologieService.getNaturaTipologie().subscribe(
            result => {
                this.naturaList = [];
                result.sort((a, b) => a.codice <= b.codice ? -1 : 1);
                result.forEach(x => this.naturaList.push({ key: x.codice, value: x.codice + ' - ' + x.etichetta }));
                if(callBack)callBack();
            }
        );
    }

    setIsDirty(value: boolean) {
        this.isDirtyHelper.isDirty = value;
    }

    cleanUpTypeField() {       
    }


    setNonLocalizedInterventoValidators() {
        if (this.isFormTipologiaDisegnabile() && !this.esistonoInterventi) {
            this.cleanUpTypeField()
        }
        if (!this.isIntervento) {
            this.myGroup.controls.type.clearValidators();
            this.myGroup.controls.type.updateValueAndValidity();
            this.myGroup.controls.natura.clearValidators();
            this.myGroup.controls.natura.updateValueAndValidity();
            this.setFlagTipologiaInterventoIsMandatory();
        }
    }

    setLocalizedInterventoValidators() {
        if (!this.isFormTipologiaDisegnabile() && !this.esistonoInterventi) {
            this.cleanUpTypeField()
        }
        this.myGroup.controls.type.setValidators([Validators.required]);
        this.myGroup.controls.type.updateValueAndValidity();
        this.myGroup.controls.natura.setValidators([Validators.required]);
        this.myGroup.controls.natura.updateValueAndValidity();
        this.setFlagTipologiaInterventoIsMandatory();
    }

    private isFormTipologiaDisegnabile() {
        return (this.myGroup.controls.type && this.myGroup.controls.type.value) ?
            this.getTipologiaType(this.myGroup.controls.type.value)?.disegnabile : false
    }


    allComboTipologie = [];

    initializeTipologieIntervento() {
        const tipologie = [];

        const tipi = this.tipologiaInterventiService.getTipologiaInterventi().subscribe(
            (tipi) => {
                tipi.forEach((t: any) => {
                    const tipo = {
                        key: t.codice, label: t.descrizione,
                        value: t.descrizione
                        , disegnabile: t.disegnabile, naturaTipologia: t.naturaTipologia
                    };
                    tipologie.push(tipo);
                }
                )
                this.allComboTipologie = [...tipologie];
                this.types = [...tipologie];
            }
        );

    }

    getTipologiaType(key) {
        return this.allComboTipologie.find((type) => type.key == key);
    }

    esistonoVecchieMotivazioni = false;

    getInterventoData(id: number, prjData: any) {
        this._projectData = prjData;
        this.esistonoVecchieMotivazioni = false;
        let that = this;
        this.isIntervento = true;
        this.esistonoInterventi = false;
        
        this.hideTipologia = false;
        if (!id || id <= 0) {
            //fill the intervento data with projectdata as default
            this.loadDataCounter++;
            this.currentProject = new Progetto();
            this.currentProject["areeTematiche"] = prjData.areeTematiche;
            this.currentProject["classificazioni"] = prjData.classificazioni?.sort((a,b)=>a.ordine-b.ordine);
            this.currentProject["bancheDatiEsterne"] = prjData.bancheDatiEsterne;
            this.currentProject["temiPrioritari"] = prjData.temiPrioritari;
            this.currentProject["soggettiGiuridici"] = prjData.soggettiGiuridici;
            this.currentProject["motivazioni"] = prjData.motivazioni;
            this.currentProject["prioritaMotivazione"] = prjData.prioritaMotivazione;
            this.currentProject["cup"] = prjData.cup;
            this.readOnlyForm = prjData.isReadOnly || this.validato || !this.canEdit();
            this.myGroup.controls.cup.setValue(prjData.cup);
            this.loadDataCounter--;
            this.currentProject["tipo"] = prjData.tipo;
            if(this.myGroup.controls.type)
                this.myGroup.controls.type.disable();
            this.currentProject["nonLocalizzabile"] = prjData.nonLocalizzabile;
            this.pnrrLineaFinanziamentoSelezionata = prjData['pnrrLineaFinanziamento'];
            that.setLocalizedInterventoValidators();
            if (prjData.prioritaMotivazione) this.myGroup.controls['prioritaMotivazione'].setValue(prjData.prioritaMotivazione.id);
            //commento in attesa di nuove istruzioni
            if (!this.isAdmin() && !this.isGr34()) {
                if (!this.gestisciNonPnrr()) {
                    this.f.pnrrRilevante.setValue('1');
                    this.f.pnrrRilevante.disable();
                    this.pnrrRilevanteChange();
                }
                if (!this.gestisciPnrr()) {
                    this.f.pnrrRilevante.setValue('0');
                    this.f.pnrrRilevante.disable();
                    this.pnrrRilevanteChange();
                }
            }
        }

        if (id) {
            that.setLocalizedInterventoValidators();
            this.myGroup.controls['type'].setValidators([Validators.required]);
            this.myGroup.controls['type'].updateValueAndValidity();
            this.myGroup.controls['natura'].setValidators([Validators.required]);
            this.myGroup.controls['natura'].updateValueAndValidity();
            this.myGroup.controls['prioritaMotivazione'].clearValidators();
            this.loadDataCounter++;
            this.interventoService.get(id).subscribe((x) => {
                this.currentProject = x;
                this.validato = false;
                let status = x.validazione
                    ? x.validazione.validationStatus
                        ? x.validazione.validationStatus
                        : 0
                    : 0;
                switch (status.toString().toUpperCase()) {
                    case "1":
                    case "APPROVED":
                    case "4":
                    case "WAITING_FOR_APPROVAL_FIRST_LEVEL":
                    case "5":
                    case "WAITING_FOR_APPROVAL_SECOND_LEVEL":
                    case "TERMINATO":
                       
                        this.validato = true;
                        break;
                }
                this.readOnlyForm = x.isReadOnly || this.readOnlyForm || !this.canEdit();
                this.currentProject["importi"].sort((a, b) => a.anno - b.anno);
                this.fillForm();
                this.loadDataCounter--;
                this.f.pnrrRilevante.enable();
                if (x.finanziamenti && x.finanziamenti.length > 0) {
                    this.f.pnrrRilevante.disable();
                }
                this.setIsDirty(false);
            });
        }

        this.esistonoVecchieMotivazioni = this.currentProject['motivazioni'] && this.currentProject['motivazioni'].length > 0;
        this.hideRup(null);
        this.setIsDirty(false);
    }

    fillForm() {

        let callBack = ()=>{
            console.log('callBack');
            this.myGroup.setValue({
                id: this.currentProject["id"],
                codice: this.currentProject["codice"],
                codiceDirezione: this.currentProject["codiceDirezione"],
                descrizione: this.currentProject["descrizione"],
                annoRiferimento: this.currentProject["annoRiferimento"],
                noteResponsabile: this.currentProject["noteResponsabile"],
                cup: this.currentProject["cup"] ? this.currentProject["cup"] : "",
                presenteInBdu: this.currentProject["presenteInBdu"] ? "1" : "0",
                cig: this.currentProject["cig"] ? this.currentProject["cig"] : "",
                allowSalWithoutValidation: this.currentProject["allowSalWithoutValidation"] ? "1" : "0",
                type: this.currentProject["tipo"]
                , prioritaMotivazione: this.currentProject["prioritaMotivazione"] ? this.currentProject["prioritaMotivazione"].id : null
                , importoBaseAsta: this.currentProject["importoBaseAsta"] ? this.currentProject["importoBaseAsta"].toString() : null
                , importoAggiudicato: this.currentProject["importoAggiudicato"] ? this.currentProject["importoAggiudicato"].toString() : null
                , parametriTecnici: this.currentProject["parametriTecnici"] ? this.currentProject["parametriTecnici"].toString() : null
                , parametriTecniciEffettivi: this.currentProject["parametriTecniciEffettivi"] ? this.currentProject["parametriTecniciEffettivi"].toString() : null
                , tipologiaStatistici: this.currentProject["tipologiaStatistici"] ? this.currentProject["tipologiaStatistici"] : null
                , statisticiExtraField1: this.currentProject["statisticiExtraField1"] ? this.currentProject["statisticiExtraField1"] : null
                , statisticiExtraField2: this.currentProject["statisticiExtraField2"] ? this.currentProject["statisticiExtraField2"] : null
                , statisticiExtraField3: this.currentProject["statisticiExtraField3"] ? this.currentProject["statisticiExtraField3"] : null
                , nonLocalizzabile: this.currentProject["nonLocalizzabile"] ? this.currentProject["nonLocalizzabile"] : false
                , rupSelect: this.currentProject["rup"] ? this.currentProject["rup"].id : null
                , pnrrTerritoriale: this.currentProject["pnrrTerritoriale"] ? this.currentProject["pnrrTerritoriale"] : null
                , pnrrRilevante: this.currentProject["pnrrRilevante"] ? '1' : '0'
                , natura: this.getNaturaFromTipo(this.currentProject["tipo"])
            });
            this.hideTipologia = false;
            this.getTipologieDatiStatistici();
            this.setItemsStatisticiExtraField2();
            this.onFillFormCompleteOk.emit(true);
            this.myGroup.enable();
            if (this.readOnlyForm || (this.validato && !this.currentProject["pnrrRilevante"])) this.myGroup.disable();
            //se ho i permessi di scrittura, posso sempre salvare le note
            if (!this.readOnlyForm) this.myGroup.controls.noteResponsabile.enable();
            if (!this.readOnlyForm) this.myGroup.controls.rupSelect.enable();

            this.pnrrLineaFinanziamentoSelezionata = this.currentProject["pnrrLineaFinanziamento"];
            if (!this.pnrrLineaFinanziamentoSelezionata && !this.currentProject['isMain']) {
                this.pnrrLineaFinanziamentoSelezionata = this._projectData ? this._projectData['pnrrLineaFinanziamento'] : this.pnrrLineaFinanziamentoSelezionata;
            }

            if (!this.isAdmin() && !this.isGr34()) {
                if (!this.gestisciNonPnrr()) {
                    this.f.pnrrRilevante.setValue('1');
                    this.f.pnrrRilevante.disable();
                    this.pnrrRilevanteChange();
                }
                if (!this.gestisciPnrr()) {
                    this.f.pnrrRilevante.setValue('0');
                    this.f.pnrrRilevante.disable();
                    this.pnrrRilevanteChange();
                }
            }
            this.setNaturaIntervento({ key: this.getNaturaFromTipo(this.currentProject["tipo"]) });
            this.setIsDirty(false);
            this.isDatiStaticiOpen =  this.isClassificazioniOpen =this.currentProject['classificazioni'] &&
            this.currentProject['classificazioni'].length &&
            this.currentProject['classificazioni'].length > 0;


        }


        if (!this.naturaList || this.naturaList.length<=0){
            this.getNaturaInterventoList(callBack);
            return;
        }
        callBack();
        

    }


    

    onSubmit() {
        console.log("progetto_onSubmit");
        this.submitted = true;
        this.pnrrLineaFinanziamentoIsError = this.f.pnrrRilevante.value == '1' && !this.pnrrLineaFinanziamentoSelezionata;
        this.areeTematicheIsError = !(
            this.currentProject &&
            this.currentProject["areeTematiche"] &&
            this.currentProject["areeTematiche"].length &&
            this.currentProject["areeTematiche"].length > 0
        );
        this.classificazioniIsError = false;
        this.soggettiGiuridiciIsError = false;
        this.temiPrioritariIsError = false;
        this.geolocalizzazioneIsError = false;
        this.motivazioniIsError = false;
        this.motivazioniIsError =
            !this.hideTipologia

            && !(
                this.currentProject &&
                this.currentProject["motivazioni"] &&
                this.currentProject["motivazioni"].length &&
                this.currentProject["motivazioni"].length > 0
            ) 
            ;
        
        const controlloLocalizzazioni = !(this.currentProject['isMain'] && this.currentProject['pnrrRilevante']);
        this.geolocalizzazioneIsError = controlloLocalizzazioni && ((
                !this.currentProject['localizzazioni'].length       && !this.hideTipologia
                ) 
                || (
                    this.currentNaturaKey == '01' &&
                    this.currentProject['localizzazioni'].find(x => x.quantita==null)!=null
                )
        );


        let errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }

        console.log("errors:" + errors);
        if (
            this.myGroup.invalid ||
            errors ||
            this.areeTematicheIsError ||
            this.classificazioniIsError ||
            this.temiPrioritariIsError ||
            this.geolocalizzazioneIsError ||
            this.soggettiGiuridiciIsError ||
            this.pnrrLineaFinanziamentoIsError ||
            (this.motivazioniIsError && (this.esistonoVecchieMotivazioni || !this.currentProject['id']))
        ) {
            let invalidElements = [];
            for (let el in this.myGroup.controls) {
                if (this.myGroup.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.myGroup.controls[el] instanceof FormGroup) {
                        for (let el1 in this.myGroup.get(el)["controls"]) {
                            if (this.myGroup.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {             
                    invalidElements[0].focus();
            }

            if (this.motivazioniIsError && (this.esistonoVecchieMotivazioni || !this.currentProject['id'])) {
                this.toastr.error("Dati statistici incompleti, inserire almeno una motivazione",
                    null, { disableTimeOut: true });
            }

            if (this.myGroup.controls.prioritaMotivazione.invalid) {
                this.toastr.error("Dati statistici incompleti, inserire almeno una priorità",
                    null, { disableTimeOut: true });
            }

            if(
                controlloLocalizzazioni
                 && !this.currentProject['localizzazioni'].length && this.geolocalizzazioneIsError)
                this.toastr.error("Inserire almeno un campo Geolocalizzazione", null, { disableTimeOut: true });

            if(
                this.currentNaturaKey == '01' &&
                this.currentProject['localizzazioni'].find(x => x.quantita == null)
            ) this.toastr.error("La natura intervento \"Acquisto di beni\" richiede che la quantità venga specificata", null, { disableTimeOut: true });

            console.log("invalid");
            return false;
        }

        if (!this.hideTipologia && this.motivazioniIsError) {
            if (this.esistonoVecchieMotivazioni || !this.currentProject['id'])
                this.toastr.error("Dati statistici incompleti, inserire almeno una motivazione",
                    null, { disableTimeOut: true });
            else
                this.toastr.warning("Dati statistici incompleti, si prega di inserire almeno una motivazione",
                    null, { timeOut: 2000,
                        /*closeButton:true,*/ disableTimeOut: false });
        }
        if (!this.hideTipologia && this.currentProject['id'] && !this.myGroup.controls.prioritaMotivazione.value) {
            this.toastr.warning("Dati statistici incompleti, inserire almeno una priorità",
                null, { timeOut: 2000,
                     disableTimeOut: false });
        }
        let pnrrRilevanteIsEnabled = this.f.pnrrRilevante.enabled;
        this.f.pnrrRilevante.enable();
        let prj = this.myGroup.value;
        if (this.readOnlyForm || this.validato) prj = this.myGroup.getRawValue();
        prj.presenteInBdu = prj.presenteInBdu == "1";
        prj.allowSalWithoutValidation = prj.allowSalWithoutValidation == "1";
        if (!this.isIntervento) {
            prj.presenteInBdu = null;
            //prj.cup = null;
        }
        if (!pnrrRilevanteIsEnabled) this.f.pnrrRilevante.disable();

        if (this.myGroup.controls.prioritaMotivazione.value) {
            prj['prioritaMotivazione'] = this.prioritaMotivazioniList.find(x => x.id == this.myGroup.controls.prioritaMotivazione.value);
        }
        this.currentProject['parametriTecniciUnitaDiMisura'] = this.getUnitaDiMisura();

        prj['pnrrRilevante'] = prj['pnrrRilevante'] == '1';
        if (!prj['pnrrRilevante']) this.pnrrLineaFinanziamentoSelezionata = null;
        prj['pnrrLineaFinanziamento'] = this.pnrrLineaFinanziamentoSelezionata;


        if (this._interventoData && JSON.parse(this._interventoData).projectId)
            this.currentProject.progettoId = JSON.parse(
                this._interventoData
            ).projectId;
        this.currentProject = Object.assign({}, this.currentProject, prj);

        this.loading = true;
        let currentService = this.isIntervento
            ? this.interventoService
            : this.progettoService;
        if (this.currentProject.id) currentService = this.interventoService;
        //this.subscribed = this.modalService.onShown.subscribe((reason: string) => {
        if (this.isIntervento) {

            if (this.currentProject["drawType"] && this.currentProject["drawType"] !== this.currentProject["tipo"]) {
                this.toastr.warning("Attenzione hai selezionato una tipologia diversa da quella selezionata in localizzazione. Ti preghiamo di aggiornare uno dei due valori per renderli congrui.", null, { timeOut: 2000,
                    disableTimeOut: false })
            }
        }

        currentService
            .save(
                this.currentProject,
                this._interventoData && JSON.parse(this._interventoData).projectId
                    ? JSON.parse(this._interventoData).projectId
                    : null
            )
            .subscribe(
                (res) => {
                    this.toastr.success(
                        `${this.isIntervento ? "Intervento" : "Progetto"
                        } salvato con successo`
                    );
                    this.loading = false;
                    if (this.subscribed) this.subscribed.unsubscribe();

                    if (!this.currentProject["id"] || this.currentProject["id"] <= 0) {
                        this.currentProject["id"] = res.id;
                        /*if (!this.isIntervento){
                                this.router.navigate([`inframob/progetti/progetto/${res.id}`]);
                                return;
                            }*/
                    }
                    this.setIsDirty(false);
                    this.onSubmitOk.emit(this.currentProject);
                    //this.fillForm();

                    //window.setTimeout(() => this.modalRef.hide(), 100);
                },
                (err) => {
                    this.toastr.error(
                        `Errore durante il salvataggio: non si dispone dei permessi per salvare la risorsa selezionata`
                    );
                    this.loading = false;
                }
            );
        if (this.subscribed) this.subscribed.unsubscribe();
        // });
        // this.modalRef = this.modalService.show(LoadingComponent, this.modalOpts);
        return true;
    }
    ngOnDestroy(): void {
        if (this.subscribed) this.subscribed.unsubscribe();
        if (this.elencoPrioritaSubscription) this.elencoPrioritaSubscription.unsubscribe();
        if (this.elencoRupSubscription) this.elencoRupSubscription.unsubscribe();
        this.clearSubscriptions();
    }

    initializeFormGroup() {
        let frm = this.formBuilder.group({
            id: [null],
            codice: [null],
            descrizione: [null, [Validators.required]],
            annoRiferimento: [
                null/*,
                [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4),
                    this.checkAnnoRiferimentoRangeValidator(this.minYear, this.maxYear),
                ],*/
            ],
            codiceDirezione: [null],
            noteResponsabile: [null],
            cup: [null, [Validators.maxLength(15), Validators.minLength(15)]],
            presenteInBdu: [null],
            allowSalWithoutValidation: [null],
            cig: [null, [Validators.maxLength(10), Validators.minLength(10)]],
            type: [{ value: null }],
            prioritaMotivazione: [null],
            importoBaseAsta: [null],
            importoAggiudicato: [null],
            parametriTecnici: [null],
            parametriTecniciEffettivi: [null],
            tipologiaStatistici: [null],
            statisticiExtraField1: [null],
            statisticiExtraField2: [null],
            statisticiExtraField3: [null],
            nonLocalizzabile: [null],
            rupSelect: [null],
            pnrrTerritoriale: [null],
            pnrrRilevante: [null, [Validators.required]]
            , natura: [null]

        });
        frm.valueChanges.subscribe(val =>
            this.setIsDirty(frm.dirty)
        );
        return frm;
    }




    checkAnnoRiferimentoRangeValidator(
        minYear: number,
        maxYear: number
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            //console.log("inside checkAnnoRiferimentoRangeValidator ");
            if (!control || !control.parent) return null;
            let value = parseInt(control.value);
            if (isNaN(value) || value < minYear /*|| value > maxYear*/)
                return { range: true };
            return null;
        };
    }

    get f() {
        return this.myGroup.controls;
    }

    //area tematica functions
    removeAreaTematica(item) {
        console.log(`inside removeAreaTematica id=${item.id}`);
        this.currentProject["areeTematiche"] = this.currentProject[
            "areeTematiche"
        ].filter((x) => x.id != item.id);
        this.setIsDirty(true);
    }
    removeClassificazione(item) {
        this.currentProject["classificazioni"] = this.currentProject[
            "classificazioni"
        ].filter((x) => x.id != item.id).sort((a,b)=>a.ordine-b.ordine);
        this.setIsDirty(true);
    }

    removeMotivazione(item) {
        this.currentProject["motivazioni"] = this.currentProject[
            "motivazioni"
        ].filter((x) => x.id != item.id);
        this.setIsDirty(true);
    }

    removeGeolocalizzazione(item) {
        this.currentProject["localizzazioni"] = this.currentProject[
            "localizzazioni"
        ].filter((x) => !(x.id == item.id));
        this.setIsDirty(true);
    }

    removeSoggettoGiuridico(item) {
        this.currentProject["soggettiGiuridici"] = this.currentProject[
            "soggettiGiuridici"
        ].filter((x) => !(x.id == item.id && x.ruoloId == item.ruoloId));
        this.setIsDirty(true);
    }
    removeTemaPrioritario(item) {
        this.currentProject["temiPrioritari"] = this.currentProject[
            "temiPrioritari"
        ].filter((x) => x.id != item.id);
        this.setIsDirty(true);
    }

    openModalAT() {
        let modalDefinition = {
            columns: [
                {
                    title: "ID",
                    data: "id",
                    searchable: true,
                    orderable: true,
                    width: "40px",
                },
                {
                    title: "descrizione",
                    data: "descrizione",
                    searchable: true,
                    orderable: true,
                },
                {
                    title: "codice ipa",
                    data: "codiceIpa",
                    searchable: true,
                    orderable: true,
                },
                {
                    title: "struttura",
                    data: "struttura",
                    searchable: true,
                    orderable: true,
                },
                {
                    title: "nominativo",
                    data: "nominativo",
                    searchable: true,
                    orderable: true,
                }
            ],
            ajax: this.getAreeTematicheDT,
            pageTitle: "Seleziona le aree gestionali e/o esterne",
        };

        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "descrizione",
                    prop: "descrizione",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 4,
                    minWidth: 200,
                },
                {
                    name: "codice ipa",
                    prop: "codiceIpa",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "struttura",
                    prop: "struttura",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 2,
                    minWidth: 100,
                },
                {
                    name: "nominativo",
                    prop: "nominativo",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 3,
                    minWidth: 200,
                }
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getAreeTematicheDTNgx,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRef = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of area tematica");
            let existsObj = this.currentProject["areeTematiche"].find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.currentProject["areeTematiche"].push(data);
            this.currentProject["areeTematiche"] = Object.assign(
                [],
                this.currentProject["areeTematiche"]
            );

            this.toastr.success("Area direzione inserita con successo", null, {
                timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
    }

    openModalClassificazioni() {
        let modalDefinition = {
            columns: [
                { title: "Codice", data: "codice", searchable: true, orderable: true },
                { title: "Ordine", data: "ordine", searchable: true, orderable: true },
                {
                    title: "Tipo Classificazione",
                    data: "tipoClassificazione",
                    searchable: true,
                    orderable: true,
                },
                {
                    title: "Descrizione",
                    data: "descrizione",
                    searchable: true,
                    orderable: true,
                },
            ],
            ajax: this.getClassificazioniDT,
            pageTitle: "Seleziona le classificazioni",
        };
        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "codice",
                    prop: "codice",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "ordine",
                    prop: "ordine",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 1,
                    minWidth: 60,
                },
                {
                    name: "tipo classificazione",
                    prop: "tipoClassificazione",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 2,
                    minWidth: 200,
                },
                {
                    name: "descrizione",
                    prop: "descrizione",
                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 6,
                    minWidth: 200,
                },
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getClassificazioniDTNgx,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRef = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of classificazione");
            let existsObj = this.currentProject["classificazioni"].find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.currentProject["classificazioni"].push(data);
            this.currentProject["classificazioni"] = Object.assign(
                [],
                this.currentProject["classificazioni"]
            ).sort((a,b)=>a.ordine-b.ordine);

            this.toastr.success("Classificazione inserita con successo", null, {
                timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
    }

    openModalSoggettiGiuridici($event) {
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg",
        };
        this.modalRef = this.modalService.show(
            SoggettoGiuridicoSelectionModalComponent,
            config
        );
        console.log("apertura modale SoggettoGiuridicoSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of soggetto giuridico");
            let existsObj = this.currentProject["soggettiGiuridici"].find(
                (x) => x.id == data.id && x.ruoloId == data.ruoloId
            );

            if (existsObj) return; //already added
            this.currentProject["soggettiGiuridici"].push(data);
            this.currentProject["soggettiGiuridici"] = Object.assign(
                [],
                this.currentProject["soggettiGiuridici"]
            );

            this.toastr.success("Soggetto giuridico inserito con successo", null, {
                timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
        $event.preventDefault();
    }

    openModalTemiPrioritari($event) {
        let modalDefinition = {
            columns: [
                {
                    title: "ID",
                    data: "id",
                    searchable: true,
                    orderable: true,
                    width: "40px",
                },
                {
                    title: "descrizione",
                    data: "descrizione",
                    searchable: true,
                    orderable: true,
                },
            ],
            ajax: this.getTemiPrioritariDT,
            pageTitle: "Seleziona i temi prioritari",
        };
        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "descrizione",
                    prop: "descrizione",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 6,
                    minWidth: 200,
                },
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getTemiPrioritariDTNgx,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRef = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of temi prioritari");
            let existsObj = this.currentProject["temiPrioritari"].find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.currentProject["temiPrioritari"].push(data);
            this.currentProject["temiPrioritari"] = Object.assign(
                [],
                this.currentProject["temiPrioritari"]
            );

            this.toastr.success("Tema prioritario inserita con successo", null, {
                timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
        $event.preventDefault();
    }

    clearSubscriptions() {
        this.modalSubscriptions.forEach((x) => x.unsubscribe());
        this.modalSubscriptions = [];
    }

    getAreeTematicheDT = (dataTablesParameters: any, callback: any) => {
        this.areeTematicheService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: resp.data,
                });
            });
    };
    getAreeTematicheDTNgx = (dataTablesParameters: any, callback: any) => {
        this.areeTematicheService
            .getAllDtOnlyActive(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };

    getTemiPrioritariDT = (dataTablesParameters: any, callback: any) => {
        this.temaPrioritarioService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: resp.data,
                });
            });
    };
    getTemiPrioritariDTNgx = (dataTablesParameters: any, callback: any) => {
        this.temaPrioritarioService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };

    getClassificazioniDT = (dataTablesParameters: any, callback: any) => {
        this.classificazioneService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: resp.data,
                });
            });
    };
    getClassificazioniDTNgx = (dataTablesParameters: any, callback: any) => {
        this.classificazioneService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };

    //templateModalImporti:BsModalRef;
    @ViewChild("templateModalImporti", { static: true })
    templateModalImporti: TemplateRef<any>;
    modalRefTemplateImporti: BsModalRef;
    editFormImporti: FormGroup;
    submittedImporti: boolean = false;

    fimporti() {
        return this.editFormImporti.controls;
    }

    removeImporto(item) {
        this.currentProject["importi"] = this.currentProject["importi"]
            .filter((x) => x.anno != item.anno)
            .sort((a, b) => a.anno - b.anno);
        this.toastr.success(`Importo eliminato con successo`, null, {
            timeOut: 2000,
      /*closeButton:true,*/ disableTimeOut: false,
        });
    }

    onSubmitImporti() {
        this.submittedImporti = true;
        var errors = false;
        for (let c in this.editFormImporti.controls) {
            errors = errors || this.editFormImporti.controls[c].errors !== null;
            console.log(
                "this.f[" +
                c +
                "].errors:" +
                JSON.stringify(this.editFormImporti.controls[c].errors)
            );
        }

        console.log("errors:" + errors);
        if (this.editFormImporti.invalid || errors) {
            let invalidElements = []; // this.el.nativeElement.querySelectorAll('.is-invalid');
            for (var el in this.editFormImporti.controls) {
                if (this.editFormImporti.controls[el].invalid) {
                    console.log(`set focus on ${el}`);
                    if (this.editFormImporti.controls[el] instanceof FormGroup) {
                        for (var el1 in this.editFormImporti.get(el)["controls"]) {
                            if (this.editFormImporti.get(el)["controls"][el1].invalid) {
                                invalidElements = this.el.nativeElement.querySelectorAll(
                                    `[formcontrolname="${el1}"]`
                                );
                                break;
                            }
                        }
                    } else {
                        invalidElements = this.el.nativeElement.querySelectorAll(
                            `[formcontrolname="${el}"]`
                        );
                    }
                    break;
                }
            }
            if (invalidElements.length > 0) {
                invalidElements[0].focus();
            }

            console.log("invalid");
            return false;
        }
        let importoObj = this.editFormImporti.value;
        console.log(
            `this.editFormImporti.value = ${JSON.stringify(
                this.editFormImporti.value
            )}`
        );
        if (
            this.currentProject["importi"].filter((x) => x.anno == importoObj.anno)
                .length > 0
        ) {
            this.toastr.error(
                `Esiste già un importo per l'anno ${importoObj.anno}`,
                null,
                {
                    timeOut: 2000,
          /*closeButton:true,*/ disableTimeOut: false,
                }
            );
            return false;
        }
        this.modalRefTemplateImporti.hide();
        this.submittedImporti = false;

        this.currentProject["importi"].push(importoObj);
        this.currentProject["importi"] = this.currentProject["importi"].sort(
            (a, b) => a.anno - b.anno
        );
        this.toastr.success(`Importo aggiunto con successo`, null, {
            timeOut: 2000,
      /*closeButton:true,*/ disableTimeOut: false,
        });
        this.editFormImporti.reset();
    }

    openModalImporti() {
        const config = {
            animated: true,
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg",
        };
        this.editFormImporti = this.formBuilder.group({
            id: [null],
            anno: [
                "",
                [Validators.required, Validators.maxLength(4), Validators.minLength(4)],
            ],
            importo: [null, [Validators.required]],
        });
        this.modalRefTemplateImporti = this.modalService.show(
            this.templateModalImporti,
            config
        );
        console.log("apertura modale templateModalImporti");
        /*this.editForm.setValue({
                id: row.id,
                descrizione:row.descrizione,
            });*/
    }
    printCurrency(value) {
        //this.maskService.decimalMarker = ",";
        //console.log(value.toFixed(2).toString().replace('.',','));
        //return value.toFixed(2).toString().replace('.',',');
        return parseFloat(value);
    }

    @Input()
    gotoBtnOptions: any;

    @Output()
    gotoBtnFunction: EventEmitter<boolean> = new EventEmitter();
    gotoBackBtnClick() {
        if (!this.isDirtyHelper.checkIsDirty()) return false;
        //

        this.gotoBtnFunction.emit(true);
    }

    cleanUpForm() {
        this.formHelper.cleanControls(this.myGroup);
        this.myGroup.controls['type'].clearValidators()
        this.myGroup.controls['type'].updateValueAndValidity();
        this.myGroup.controls['natura'].clearValidators();
        this.myGroup.controls['natura'].updateValueAndValidity();
        this.setIsDirty(false);
        this._projectData = null;
        this.projectId = null;
        this.pnrrLineaFinanziamentoSelezionata = null;
        this.submitted = false;
        this.currentProject = new Progetto();
        //this.currentProject['isMain'] = true;
        //this.onFillFormCompleteOk.emit(true);
        this.hideTipologia = true;
        this.esistonoVecchieMotivazioni = false;
        this.esistonoInterventi = false;
    }

    getLocalizzazioneSearchField(){
        let found = this.currentProject['localizzazioni'].find(x => x.indirizzo && x.comune && x.provincia)
        return found ? `${found.indirizzo} ${found.comune.name/*  ? found.comune.name : '' */} ${found.provincia.name/*  ? found.provincia.name : '' */}` : null;
    }

    showMap() {
        let searchField = this.getLocalizzazioneSearchField();
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xxl",
        };
        const initialState = {
            interventoId: /*this._interventoData
        ? JSON.parse(this._interventoData).interventoId
        : this.projectId
        ? this.projectId
        : null*/ this
                    .currentProject
                    ? this.currentProject.id
                    : null,

            isProjectMode: true,
            campoCerca: searchField,
            descrizione: this.currentProject.descrizione,

        };
        this.modalRef = this.modalService.show(
            DashboardComponent,
            Object.assign({}, config, { initialState })
        );
    }

    possoConcludere(): boolean {
        //posso concludere se è validato ed ho i permessi per farlo
        let validato =
            this.currentProject &&
            this.currentProject["validazione"] &&
            this.currentProject["validazione"].validationStatus == "APPROVED";
        return validato && !this.currentProject.isReadOnly;
    }

    onConcludiIntervento() {
        if (!confirm("Vuoi concludere l'intervento selezionato?")) return;
        this.loading = true;
        this.interventoService.concludiIntervento(this.currentProject.id).subscribe(
            (result) => {
                this.loading = false;
                this.toastr.success(`Intervento concluso con successo`, null, {
                    timeOut: 2000,
          /*closeButton:true,*/ disableTimeOut: false,
                });
                this.currentProject = result;
                this.onSubmitOk.emit(result);
            },
            (error) => {
                this.loading = false;
                this.toastr.error(
                    `Errore: ${error.error.message
                        ? error.error.message
                        : error.error.error_description
                            ? error.error.error_description
                            : error.error
                    }`
                );
            }
        );
    }

    esportaPdf() {
        this.loading = true;
        this.interventoService.esportaPdf(this.currentProject.id).subscribe(
            res => {
                this.loading = false;
                let filename = `esportazione_${this.currentProject.codice}.pdf`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }

    esportaExcel() {
        this.loading = true;
        this.interventoService.esportaExcel(this.currentProject.id).subscribe(
            res => {
                this.loading = false;
                let filename = `esportazione_${this.currentProject.codice}.xlsx`;
                FileSaver.saveAs(
                    res.body,
                    `${filename}`
                );
            }
            , error => {
                this.loading = false;
                this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
                    timeOut: 2000, disableTimeOut: false
                });
                console.log('error');
            }
        );
    }

    esportaExcelCompleto() {
      this.loading = true;
      this.interventoService.esportaExcelCompleto(this.currentProject.id).subscribe(
        res => {
          this.loading = false;
          let filename = `esportazioneCompleta_${this.currentProject.codice}.xlsx`;
          FileSaver.saveAs(
            res.body,
            `${filename}`
            );
          }
          , error => {
            this.loading = false;
            this.toastr.error(`Errore: ${error.error.message ? error.error.message : (error.error.error_description ? error.error.error_description : error.error)}`, null, {
              timeOut: 2000, disableTimeOut: false
            });
            console.log('error');
          });
    }

    userIsManager() {
        return this.authService.isManager();
    }

    setTipologiaIntervento(tipologiaIntervento: any) {
        this.currentProject["tipo"] = tipologiaIntervento.codice;
        this.myGroup.controls.type.setValue(tipologiaIntervento.codice);


        this.getTipologieDatiStatistici();
        this.setItemsStatisticiExtraField2();
        if (!tipologiaIntervento.disegnabile) {
            this.f.nonLocalizzabile.setValue(true);
            this.nonLocalizzabileChange(null);
        }
    }

    removeTipologiaIntervento() {
        this.currentProject["tipo"] = null;
        this.myGroup.controls.type.setValue(null);
        this.myGroup.updateValueAndValidity();
        this.unsetTipologieDatiStatistici();
        this.unsetItemsStatisticiExtraField2()
    }

    setFlagTipologiaInterventoIsMandatory() {
        if (this.f.type.validator) {
            const validator = this.f.type.validator({} as AbstractControl);
            if (validator && validator.required) {
                this.isTipologiaInterventoMandatory = true
            }
        }
        else {
            this.isTipologiaInterventoMandatory = false
        }
    }
    //motivazioni

    openModalMotivazioni() {
        let modalDefinition = {
            columns: [
                { title: "Motivazione", data: "motivazione", searchable: true, orderable: true },
            ],
            pageTitle: "Seleziona le motivazioni",
        };
        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "motivazione",
                    prop: "motivazione",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 4,
                    minWidth: 200,
                }
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getMotivazioniDt,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRef = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of motivazione");
            let existsObj = this.currentProject["motivazioni"].find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.currentProject["motivazioni"].push(data);
            this.currentProject["motivazioni"] = Object.assign(
                [],
                this.currentProject["motivazioni"]
            );

            this.toastr.success("Motivazione inserita con successo", null, {
                timeOut: 2000,
    /*closeButton:true,*/ disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
    }
    getMotivazioniDt = (dataTablesParameters: any, callback: any) => {
        this.motivazioneService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };

    prioritaMotivazioniList = [];
    private elencoPrioritaSubscription = null;
    getPrioritaMotivazioni() {
        this.elencoPrioritaSubscription = this.storageService.elencoPriorita.subscribe(
            x => {
                console.log(x);
                this.prioritaMotivazioniList = x;
            }
        );
        /*let that = this;
        this.motivazioneService.getPriorita().subscribe(
            result => {
                that.prioritaMotivazioniList = result.map((i) => {
                    i["label"] = `${i.priorita} - ${i.motivazione}`;
                    return i;
                });
                that.prioritaMotivazioniList.sort(
                    (a, b) => b['priorita'] == a['priorita'] ? 0 : a['priorita'] > b['priorita'] ? 1 : -1
                );
            }
        );*/
    }

    setPrioritaMotivazione($event) {
        this.currentProject["prioritaMotivazione"] = $event.key;
    }

    tipologieStatistici = [];
    getTipologieDatiStatistici() {
        this.tipologieStatistici = [];
        let tipologia = this.currentProject["tipo"];
        switch (tipologia) {
            case "road":
            case "rail":
            case "metro":
            case "bike":
            case "other_lines":
                this.tipologieStatistici = [
                    { k: 'nuova_costruzione', v: 'Nuova costruzione' },
                    { k: 'manutenzione_straordinaria', v: 'Manutenzione straordinaria' },
                    { k: 'ammodernamento', v: 'Ammodernamento' }
                ];
                break;
            case "station":
            case "metrostop":
            case "nodes":
            case "park":
                this.tipologieStatistici = [
                    { k: 'nuova_costruzione', v: 'Nuova costruzione' },
                    { k: 'manutenzione_straordinaria_ristrutturazione_riqualificazione', v: 'Manutenzione straordinaria / Ristrutturazione / Riqualificazione' },
                ];
                break;

            case "parkland":
                this.tipologieStatistici = [
                    { k: 'nuova_costruzione', v: 'Nuova costruzione' },
                    { k: 'rifacimento', v: 'Rifacimento' },
                ];
                break;
            case "emobility":
                this.tipologieStatistici = [
                    { k: 'nuova_costruzione', v: 'Nuova costruzione' },
                    { k: 'rifacimento', v: 'Rifacimento' },
                ];
                break;
            case "port":
                this.tipologieStatistici = [
                    { k: 'turistico', v: 'Turistico' },
                    { k: 'commerciale', v: 'Commerciale' },
                ];
                break;
            case "points":
            case "other_points":

                this.tipologieStatistici = [
                    { k: 'nuova_costruzione', v: 'Nuova costruzione' },
                    { k: 'manutenzione_straordinaria_ristrutturazione_riqualificazione', v: 'Manutenzione straordinaria / Ristrutturazione / Riqualificazione' },
                    { k: 'manutenzione_straordinaria_riqualificazione', v: 'Manutenzione straordinaria/ restauro/ ristrutturazione/ riqualificazione, su edifici e manufatti di interesse storico artistico non soggetti a tutela ai sensi del D.Lgs 42/2004' },
                    { k: 'manutenzione_straordinaria_risanamento', v: 'Manutenzione / restauro / risanamento conservativo / riqualificazione, su edifici e manufatti di interesse storico artistico soggetti a tutela ai sensi del D.Lgs 42/2004, oppure di particolare importanza' },
                ];
                break;
            default:
                this.tipologieStatistici = [
                    { k: 'nuova_costruzione', v: 'Nuova costruzione' },
                    { k: 'manutenzione_straordinaria_ristrutturazione_riqualificazione', v: 'Manutenzione straordinaria / Ristrutturazione / Riqualificazione' },
                    { k: 'manutenzione_straordinaria_riqualificazione', v: 'Manutenzione straordinaria/ restauro/ ristrutturazione/ riqualificazione, su edifici e manufatti di interesse storico artistico non soggetti a tutela ai sensi del D.Lgs 42/2004' },
                    { k: 'manutenzione_straordinaria_risanamento', v: 'Manutenzione / restauro / risanamento conservativo / riqualificazione, su edifici e manufatti di interesse storico artistico soggetti a tutela ai sensi del D.Lgs 42/2004, oppure di particolare importanza' },
                ];
                break;



        }
    }

    unsetTipologieDatiStatistici() {
        this.tipologieStatistici = [];
    }

    getUnitaDiMisura() {
        let tipologia = this.currentProject["tipo"];
        switch (tipologia) {
            case "road":
                return "km";

            case "rail":
                return "km";

            case "metro":
                return "km";

            case "bike":
                return "km";

            case "skilift":
                return "m";

            case "points":
                return "mc";

            case "port":
                return "mc";

            case "metrostop":
                return "mc";

            case "station":
                return "mc";

            case "other_lines":
                return "km";

            case "other_points":
                return "mc";
            case "park":
                return "mc";
            case "nodes":
                return "mc";

            case "parkland":
                return "ha";
            case "emobility":
                return "mc";
            default:
                return '';
        }

    }

    setTipologiaStatistici($event) {
        //this.progetto
    }

    trimText(text, size) {
        if (!size) return text;
        if (text.length <= size) return text;
        return text.substring(0, size) + '...';
    }

    getLabelStatisticiExtraField1() {
        if (!this.currentProject['tipo']) return '';
        switch (this.currentProject['tipo']) {
            case 'road':
            case 'rail':
            case 'bike':
                return 'Opere d\'arte > 30%';
            case 'port':
                return 'Moli';

        }
    }
    itemsStatisticiExtraField2 = [];

    setItemsStatisticiExtraField2() {
        this.itemsStatisticiExtraField2 = [];
        if (!this.currentProject['tipo']) return '';
        switch (this.currentProject['tipo']) {
            case 'road':
                this.itemsStatisticiExtraField2 = [
                    { k: 'A - Autostrade', v: 'A - Autostrade' },
                    { k: 'B - Strade extraurbane principali', v: 'B - Strade extraurbane principali' },
                    { k: 'C - Strade extraurbane secondarie', v: 'C - Strade extraurbane secondarie' },
                    { k: 'D - Strade urbane di scorrimento', v: 'D – Strade urbane di scorrimento' },
                    { k: 'E - Strade urbane di quartiere', v: 'E – Strade urbane di quartiere' },
                    { k: 'F – Strade locali', v: 'F – Strade locali' }
                ];
                break;
            case 'rail':
                this.itemsStatisticiExtraField2 = [{ k: 'Singolo', v: 'Singolo' }, { k: 'Doppio', v: 'Doppio' }];
                break;
            case 'bike':
                this.itemsStatisticiExtraField2 = [{ k: 'Singola', v: 'Singola' }, { k: 'Doppia', v: 'Doppia' }];
                break;

        }
    }

    unsetItemsStatisticiExtraField2() {
        this.itemsStatisticiExtraField2 = [];
    }

    getLabelStatisticiExtraField2() {
        if (!this.currentProject['tipo']) return '';
        switch (this.currentProject['tipo']) {
            case 'road':
                return 'Classificazione prevalente';
            case 'rail':
                return 'Binario';
            case 'bike':
                return 'Corsia';

        }
    }
    getImportoBaseAstaUnitario() {
        let ris = 0;
        let v = this.myGroup.controls.importoBaseAsta.value;
        let p = this.myGroup.controls.parametriTecnici.value;
        p = parseFloat(p);
        v = parseFloat(v);
        if (!isNaN(p) && p != 0) ris = v / p;
        return this.printValueAsCurrency(ris);
    }
    getImportoAggiudicatoUnitario() {
        let ris = 0;
        let v = this.myGroup.controls.importoAggiudicato.value;
        let p = this.myGroup.controls.parametriTecnici.value;
        p = parseFloat(p);
        v = parseFloat(v);
        if (!isNaN(p) && p != 0) ris = v / p;
        return this.printValueAsCurrency(ris);

    }
    printValueAsCurrency(value) {
        //return this.italianCurrencyPipe.parse(value) + " €";
        value = (typeof value === 'number') ? value : value.replace(",", ".").trim();
        const format = '1.2-2';
        const currency = '€';
        const currentLocale: string = 'it';
        return new CurrencyPipe(currentLocale).transform(value, 'EUR', currency, format, 'it-IT');
    }

    nonLocalizzabileChange($event) {
        (!this.isFormTipologiaDisegnabile()) ? this.setNonLocalizedInterventoValidators()
            : this.setLocalizedInterventoValidators();
    }

    private elencoRupSubscription = null;
    getSoggettiResponsabili() {

        this.elencoRupSubscription = this.storageService.elencoRup.subscribe(
            x => {
                this.soggettiRespArray = x;               
                console.log(this.soggettiRespArray);
            }
        );
    }

    setRup($event) {
        this.currentProject["rup"] = $event ? $event : null;
    }

    hideRup($event) {
        if (this.currentProject['isMain'] && this.currentProject['numSubInterventi'] > 0) {
            this.isRupHidden = true;
        } else {
            this.isRupHidden = false;
        }
    }

    randomNum = 0;
    getRandomNumber() {
        this.randomNum = Math.floor(Math.random() * 100);
    }


    pnrrRilevanteChange() {
        //const isRilevante = this.f.pnrrRilevante.value=='1';
        //if (isRilevante)this.showPnrrLineaFinanziamentoAndSetValidators();
        // else this.hidePnrrLineaFinanziamentoAndUnsetValidators();
    }
    showPnrrLineeFinanziamento() {
        return this.f.pnrrRilevante.value == '1';
    }
    pnrrLineaFinanziamentoSelezionata = null;
    pnrrLineaFinanziamentoIsError = false;
    removePnrrLineaFinanziamento() {
        this.pnrrLineaFinanziamentoSelezionata = null;
    }

    modalRefSelectTipologiaIntervento: BsModalRef;

    isInProgettoOrInterventoInsert() {
        let result = this.projectId || (this._interventoData && JSON.parse(this._interventoData)['interventoId'])
        console.log(result);
        return result
    }

    openModalTipologiaIntervento() {
        let modalDefinition = {
            columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                },
                {
                    name: 'disegnabile', prop: 'disegnabile', visible: true
                    , sortable: true, filtrable: true, width: 10
                    , cellTemplate: 'booleanValueTemplate'
                    , resizeable: false, /*search: (this.isInProgettoOrInterventoInsert()) ? { value: !this.disabledGeoButton, regex: false } : undefined*/
                }
                , {
                    name: 'codice'
                    , prop: 'codice'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 65

                },
                {
                    name: 'descrizione'
                    , prop: 'descrizione'
                    , sortable: true
                    , filtrable: true
                    , flexGrow: 5
                    , minWidth: 100
                }
                ,
                {
                    name: 'natura'
                    , prop: 'naturaTipologia.etichetta'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'columnNaturaTipologiaTemplate'// 'buttonsTemplate'
                    , flexGrow: 7
                    , minWidth: 100
                },
            ],
            ajax: this.getTipologieInterventoDt,
            pageTitle: "Seleziona Tipologia Intervento",
            rows: [],
            totalElements: 0,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            scrollbarH: "true",
            sorts: [{ dir: 'asc', prop: 'codice' }]
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinition,
            useCustomSelectButtonTemplate: true
        };

        this.modalRefSelectTipologiaIntervento = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );

        this.clearSubscriptions();
        let subscription = this.modalRefSelectTipologiaIntervento.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of select tipologia intervento");
            this.setTipologiaIntervento(data)
            this.setIsDirty(true);
            this.closeModalRefSelectTipologiaIntervento();

        });
        this.modalSubscriptions.push(subscription);
    }

    onRemoveTipologiaIntervento($event) {
        this.removeTipologiaIntervento();
        this.setIsDirty(true);
        $event.target.blur();
    }

    closeModalRefSelectTipologiaIntervento() {
        if (this.modalRefSelectTipologiaIntervento) this.modalRefSelectTipologiaIntervento.hide();
    }

    getTipologieInterventoDt = (dataTablesParameters: any, callback: any) => {
        this.tipologiaInterventiService.getAllDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    };


    openModalPnrrLineeFinanziamento() {
        let modalDefinition = {
            columns: [
                {
                    name: 'id', prop: 'id', visible: false, sortable: true, filtrable: true, width: 10
                    , resizeable: false
                }

                , {
                    name: 'codice'
                    , prop: 'codice'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 1
                    , minWidth: 65

                },
                {
                    name: 'missione'
                    , prop: 'missione.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                },
                {
                    name: 'componente'
                    , prop: 'componente.descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                },
                {
                    name: 'descrizione'
                    , prop: 'descrizione'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                }
                ,
                {
                    name: 'misura PNRR'
                    , prop: 'misuraPnrr'
                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 100
                }
                , {
                    name: 'tipologia'
                    , prop: 'tipologia'

                    , sortable: true
                    , filtrable: true
                    , resizeable: false
                    , draggable: false
                    , flexGrow: 2
                    , minWidth: 70

                }
                , {
                    name: 'pnrr'
                    , prop: 'pnrr'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'pnc'
                    , prop: 'fc'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'quota assegnata pnrr'
                    , prop: 'quotaAssegnataPnrr'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
                , {
                    name: 'quota assegnata pnc'
                    , prop: 'quotaAssegnataFc'
                    , sortable: true
                    , filtrable: true
                    , cellTemplate: 'currencyValueTemplate'
                    , flexGrow: 2
                    , minWidth: 70
                }
            ],
            ajax: this.getLineeFinanziamentoPnrrDt,
            pageTitle: "Seleziona linea di finanziamento PNRR/PNC",
            rows: [],
            totalElements: 0,
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            scrollbarH: "true",
            sorts: [{ dir: 'asc', prop: 'codice' }]
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinition,
        };

        this.modalRefSelectLineaFinPnrrFc = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRefSelectLineaFinPnrrFc.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of lineepnrr");
            if (this.pnrrLineaFinanziamentoSelezionata) {
                this.toastr.error("Esiste già una linea di finanziamento PNRR/PNC associata", null, {
                    timeOut: 2000,
                    disableTimeOut: false,
                });
                return;
            }
            this.pnrrLineaFinanziamentoSelezionata = data;
            //this.getFasiDellaPnrrLineaFinanziamento(data.id);

            this.toastr.success("Linea di finanziamento PNRR/PNC aggiunta con successo", null, {
                timeOut: 2000,
                disableTimeOut: false,
            });
            this.setIsDirty(true);
            this.closeModalRefSelectLineaFinPnrrFc();

        });
        this.modalSubscriptions.push(subscription);
    }
    modalRefSelectLineaFinPnrrFc: BsModalRef;

    closeModalRefSelectLineaFinPnrrFc() {
        if (this.modalRefSelectLineaFinPnrrFc) this.modalRefSelectLineaFinPnrrFc.hide();
    }

    getLineeFinanziamentoPnrrDt = (dataTablesParameters: any, callback: any) => {
        this.pnrrService.getAllLineaFinanziamentoDt(dataTablesParameters)
            .subscribe(resp => {
                callback(resp);
            });
    };

    canEdit() {
        return (this.enableSection(this.section, ['update']));
    }


    /**
* Controlla se l'utente può accedere o meno alla sezione.
* @param section il nome della sezione
* @param operations le operazioni da poter effettuare sulla sezione
* @param atLeastOne opzionale. Se true almeno un'operazione deve essere contenuta nei permessi, tutte altrimenti. Ha valore false di default
* @param enableOnNotFound opzionale. Se true (default) e non esiste la sezione settata nei permessi, ritorna true, false altrimenti
* @returns true se il controllo è andato a buon fine, false altrimenti
*/
    enableSection(section: string, operations: string[], atLeastOne: boolean = false, enableOnNotFound: boolean = true): boolean {
        return this.authService.checkPermissionBySectionAndOperations(section, operations, atLeastOne, enableOnNotFound);
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
    isGr34() {
        return this.authService.isGr34();
    }
    isGruppoPnrr() {
        return this.authService.isGruppoPnrr();
    }

    gestisciNonPnrr() {
        return this.authService.gestisciNonPnrr();
    }

    gestisciPnrr() {
        return this.authService.gestisciPnrr();
    }


    isManager() {
        return this.authService.isManager();
    }

    

    bloccaCampiPnrr() {
        const pnrrRilevante = this.f.pnrrRilevante.value == "1";
        //console.log(pnrrRilevante && !this.isManager() && !this.isGruppoPnrr());
        return pnrrRilevante && !this.isManager() && !this.isGruppoPnrr();
    }

    getNaturaFromTipo(tipo: string) {
        if (!tipo || !this.allComboTipologie) return null;
        let t = this.allComboTipologie.find(x => x.key == tipo);
        if (t) return t.naturaTipologia?.codice;
        return null;
    }

    checkTipologiaOnNaturaInterventoChange(){
        this.types = [...this.allComboTipologie];
        this.types = this.types.filter(x => x.naturaTipologia.codice == this.currentNaturaKey);
        if(!this.readOnlyForm && !this.validato)this.f.type.enable();
        let t = this.f.type.value;
        if (!this.types.find(x => x.key == t)) {
            this.myGroup.controls.type.setValue(null);
            if (this.currentProject) {
                this.currentProject['tipo'] = null;
            }
        }
    }

    generateAlarmModal(title, msg, acceptBtnLabel, refuseBtnLabel, stylesAcceptBtn, stylesRefuseBtn){
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg",
            initialState:{
               headerTitle: title,
               alertMessage: msg,
               submitBtnLable: [refuseBtnLabel, acceptBtnLabel],
               submitBtnClass: [stylesRefuseBtn, stylesAcceptBtn],
            }
        }
        this.modalRef = this.modalService.show(
            AlertMessageModalComponent,
            config
        );
    }

    setNaturaIntervento($event) {
        this.types = [];
        //this.cleanUpTypeField();
        this.f.type.disable();
        if (!$event || !$event.key) {
            this.myGroup.controls.type.setValue(null);
            if (this.currentProject) {
                this.currentProject['tipo'] = null;
            }
            return;
        }
        if(!this.currentNaturaKey){
            this.currentNaturaKey = $event.key;
            this.currentTipologiaKey = this.currentProject['tipo'];
            this.checkTipologiaOnNaturaInterventoChange();
            return;
        }

        if(this.currentProject['localizzazioni'].length && this.currentNaturaKey != $event.key){
            this.generateAlarmModal(
                'Attenzione!',
                'Stai cercando di cambiare la natura intervento, questo cancellerà tutti i campi precedentemente inseriti.',
                'Rifiuta',
                'Accetta',
                'btn-secondary font-weight-bold',
                'btn-primary'
            )
            let subscription = this.modalRef.content.onSubmitAlarm.subscribe(data=> {
                if(data == "Accetta"){
                    this.currentProject['localizzazioni'] = []
                    this.currentNaturaKey = $event.key;
                }
                else{
                    // Se non c'e' selezione allora metto null
                    if(!this.currentNaturaKey){
                        this.f.natura.setValue(null);
                        this.f.type.setValue(null);
                    }
                    else{
                        this.f.natura.setValue(this.currentNaturaKey);
                        if(!this.currentTipologiaKey)
                            this.f.type.setValue(null);
                        else
                            this.f.type.setValue(this.currentTipologiaKey);
                    }
                }
                this.checkTipologiaOnNaturaInterventoChange();
            })
            this.modalSubscriptions.push(subscription);
            return;
        }
        this.currentNaturaKey = $event.key;
        this.checkTipologiaOnNaturaInterventoChange();
    }

    setTipoIntervento($event) {
        if ($event){
            if(this.currentProject['localizzazioni'].length){
                this.generateAlarmModal(
                    'Attenzione!',
                    'Stai cercando di cambiare la tipologia intervento, questo cancellerà tutti i campi precedentemente inseriti.',
                    'Rifiuta',
                    'Accetta',
                    'btn-secondary font-weight-bold',
                    'btn-primary'
                )
                let subscription = this.modalRef.content.onSubmitAlarm.subscribe(data=> {
                    if(data == "Accetta"){
                        this.currentProject['localizzazioni'] = [];
                        this.currentProject["tipo"] = $event.key;
                        this.currentTipologiaKey = $event.key;
                    }
                    else {
                        if(!this.currentTipologiaKey) this.f.type.setValue(null);
                        else this.f.type.setValue(this.currentTipologiaKey);
                        this.currentProject["tipo"] = this.currentTipologiaKey;
                        this.checkTipologiaOnNaturaInterventoChange();
                    }
                })
                this.modalSubscriptions.push(subscription);
            }
            else{
                this.currentProject["tipo"] = $event.key;
                this.currentTipologiaKey = $event.key;
            }
        }
    }

    onTipologiaInterventoClear(){
        if(this.currentProject['localizzazioni'].length){
            this.generateAlarmModal(
                'Attenzione!',
                'Stai cercando di cancellare la tipologia intervento, questo cancellerà tutti i campi precedentemente inseriti.',
                'Rifiuta',
                'Accetta',
                'btn-secondary font-weight-bold',
                'btn-primary'
            )
            //Controllo se la risposta corrisponde alla mia accettazione
            let subscription = this.modalRef.content.onSubmitAlarm.subscribe(data=> {
                if(data == "Accetta"){
                    this.currentProject['localizzazioni'] = [];
                }
                else {
                    this.f.type.setValue(this.currentTipologiaKey);
                    this.currentProject["tipo"] = this.currentTipologiaKey;
                    this.checkTipologiaOnNaturaInterventoChange();
                }
            })
            this.modalSubscriptions.push(subscription);
        }
    }

    onNaturaInterventoClear(){
        if(this.currentProject['localizzazioni'].length){
            this.generateAlarmModal(
                'Attenzione!',
                'Stai cercando di cancellare la natura intervento, questo cancellerà tutti i campi precedentemente inseriti.',
                'Rifiuta',
                'Accetta',
                'btn-secondary font-weight-bold',
                'btn-primary'
            )
            //Controllo se la risposta corrisponde alla mia accettazione
            let subscription = this.modalRef.content.onSubmitAlarm.subscribe(data=> {
                if(data == "Accetta"){
                    this.f.natura.setValue(null);
                    this.currentNaturaKey = undefined; // Riporto lo stato di currentNaturalKey come se niente fosse mai stato inserito
                    this.currentProject['localizzazioni'] = [];
                }
                else {
                    this.f.natura.setValue(this.currentNaturaKey);
                    this.f.type.setValue(this.currentTipologiaKey);
                    this.currentProject["tipo"] = this.currentTipologiaKey;
                    this.checkTipologiaOnNaturaInterventoChange();
                }
            })
            this.modalSubscriptions.push(subscription);
        }
    }

    openModalGeolocalizzazione($event){
        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-lg",
            initialState:{
                keySelected: this.f.natura.value,
                tipologyCode: this.f.type.value
            }
        };
        this.modalRef = this.modalService.show(
            GeolocalizzazioneSelectionModalComponent,
            config
        );
        console.log("apertura modale GeolocalizzazioneModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("Inizio inserimento geolocalizzazione");
            this.currentProject["localizzazioni"].push(data);
            this.currentProject["localizzazioni"] = Object.assign(
                [],
                this.currentProject["localizzazioni"]
            );
            console.log(this.currentProject);

            this.toastr.success("Geolocalizzazione inserita con successo", null, {
                timeOut: 2000,
                /*closeButton:true,*/
                disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
        $event.preventDefault();
    }


    bancheDatiEsterneIsError:boolean = false;

    removeBancaDatiEsterna(item) {
        console.log(`inside removeBancaDatiEsterna id=${item.id}`);
        this.currentProject["bancheDatiEsterne"] = this.currentProject[
            "bancheDatiEsterne"
        ].filter((x) => x.id != item.id);
        this.setIsDirty(true);
    }

    openModalBancheDatiEsterne($event) {
        let modalDefinition = {
            pageTitle: "Seleziona le banche dati esterne"
        };
        let modalDefinitionNgx = {
            rows: [],
            totalElements: 0,
            columns: [
                {
                    name: "id",
                    prop: "id",
                    visible: false,
                    sortable: true,
                    filtrable: true,
                    width: 10,
                    resizeable: false,
                },

                {
                    name: "descrizione",
                    prop: "titolo",

                    sortable: true,
                    filtrable: true,
                    resizeable: false,
                    draggable: false,
                    flexGrow: 6,
                    minWidth: 200,
                },
            ],
            columnMode: ColumnMode.flex,
            headerHeight: "50",
            footerHeight: "50",
            rowHeight: "auto",
            externalSorting: true, // true
            loadingIndicator: "loading",
            class: "bootstrap",
            summaryRow: true,
            summaryPosition: "'bottom'",
            externalPaging: true, // true
            count: 0,
            offset: 0,
            limit: 10,
            serverSide: true,
            ajax: this.getBancheDatiEsterneDT,
            scrollbarH: "true",
        };

        let config = {
            backdrop: true,
            ignoreBackdropClick: true,
            keyboard: false,
            class: "modal-xl",
        };
        const initialState = {
            modalDefinition: modalDefinition,
            dtOptionsNgx: modalDefinitionNgx,
        };

        this.modalRef = this.modalService.show(
            GenericSelectionModalComponent,
            Object.assign({}, config, { initialState })
        );
        console.log("apertura modale GenericSelectionModalComponent");
        this.clearSubscriptions();
        let subscription = this.modalRef.content.onSubmitOk.subscribe((data) => {
            console.log("inside ok of banche dati");
            if(!this.currentProject["bancheDatiEsterne"])this.currentProject["bancheDatiEsterne"] = [];
            let existsObj = this.currentProject["bancheDatiEsterne"].find(
                (x) => x.id == data.id
            );
            if (existsObj) return; //already added
            this.currentProject["bancheDatiEsterne"].push(data);
            this.currentProject["bancheDatiEsterne"] = Object.assign(
                [],
                this.currentProject["bancheDatiEsterne"]
            );

            this.toastr.success("Banca dati esterna inserita con successo", null, {
                timeOut: 2000,
        /*closeButton:true,*/ disableTimeOut: false,
            });
            this.setIsDirty(true);
        });
        this.modalSubscriptions.push(subscription);
        $event.preventDefault();
    }
    getBancheDatiEsterneDT = (dataTablesParameters: any, callback: any) => {
        this.bancheDatiEsterneService
            .getAllDt(dataTablesParameters)
            .subscribe((resp) => {
                callback(resp);
            });
    };
}
