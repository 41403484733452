import { NgxInputSwitchModule } from 'ngx-input-switch';
import { ErrorHandler, NgModule,APP_INITIALIZER } from "@angular/core";
import { InfraMobRoutingModule } from "./infamob.routing";
import { InframobComponent } from "./components/inframob/inframob.component";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { Ng2CompleterModule } from "ng2-completer";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DatePipe, CommonModule } from "@angular/common";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ShowHidePasswordModule } from "ngx-show-hide-password";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ToastrModule } from "ngx-toastr";
import { NgxMaskModule } from "ngx-mask";
import { JwtInterceptor } from "../../helpers/jwt.interceptor";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { IMaskModule } from "angular-imask";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { OrderModule } from "ngx-order-pipe";
import { UploadFileModule } from "../../components/_components/uploadFile/upload-file.module";
import { NgxDatatableWrapperModule } from "../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.module";
import { ColorPickerModule } from 'ngx-color-picker';
import { AppLoadingModule } from "../../components/_components/loading/loading.module";

import { SoggettiRuoliComponent } from "./components/soggettiRuoli/soggetti-ruoli.component";
import { AreeTematicheComponent } from "./components/areeTematiche/aree-tematiche.component";
import { TipoClassificazioneComponent } from "./components/tipiClassificazione/tipi-classificazione.component";
import { ClassificazioneComponent } from "./components/Classificazione/classificazione.component";
import { StatiFinanziamentoComponent } from "./components/statiFinanziamento/stati-finanziamento.component";
import { SoggettiGiuridiciComponent } from "./components/soggettiGiuridici/soggetti-giuridici.component";
import { TipiFinanziamentoComponent } from "./components/tipiFinanziamento/tipi-finanziamento.component";
import { TipiProvvedimentoComponent } from "./components/tipiProvvedimento/tipi-provvedimento.component";
import { ProgettiComponent } from "./components/progetti/progetti.component";
import { DatiGeneraliComponent } from "./components/datiGenerali/dati-generali.component";
import { GenericSelectionModalComponent } from "./components/genericSelectionModal/generic-selection-modal.component";
import { SoggettoGiuridicoSelectionModalComponent } from "./components/datiGenerali/SoggettoGiuridicoSelectionModal/soggetto-giuridico-selection-modal.component";
import { TemiPrioritariComponent } from "./components/temiPrioritari/temi-prioritari.component";
import { TipologieFasiComponent } from "./components/tipologieFasi/tipologie-fasi.component";
import { ScopiProvvedimentoComponent } from "./components/scopiProvvedimento/scopi-provvedimento.component";
import { CapitoliBilancioComponent } from "./components/capitoliBilancio/capitoli-bilancio.component";
//import { CapitoloBilancioEditModalComponent } from './components/capitoliBilancio/capitolo-bilancio-edit-modal/capitolo-bilancio-edit-modal.component';

import { ProvvedimentiComponent } from "./components/provvedimenti/provvedimenti.component";
import { ProvvedimentoCapitoloBilancioSelectionModalComponent } from "./components/provvedimenti/provvedimentoCapitoloBilancioSelectionModal/provvedimento-capitolo-bilancio-selection-modal.component";
import { FasiComponent } from "./components/fasi/fasi.component";

import { TipologieSoggettoResponsabileComponent } from "./components/tipologieSoggettoResponsabile/tipologie-soggetto-responsabile.component";
import { SoggettoResponsabileComponent } from "./components/soggettoResponsabile/soggetto-responsabile.component";
import { TipiStrumentoAttuativoComponent } from "./components/tipiStrumentoAttuativo/tipi-strumento-attuativo.component";
import { FinanziamentoComponent } from "./components/finanziamento/finanziamento.component";
import { FinanziamentiComponent } from "./components/finanziamenti/finanziamenti.component";
import { InterventiComponent } from "./components/interventi/interventi.component";
import { InterventoComponent } from "./components/intervento/intervento.component";
import { ProgettoComponent } from "./components/progetto/progetto.component";
import { InterventoFinanziamentoComponent } from "./components/interventoFinanziamento/intervento-finanziamento.component";
import { TipologieErogazioniComponent } from "./components/tipologieErogazioni/tipologie-erogazioni.component";
import { InterventoErogazioniComponent } from "./components/interventoErogazioni/intervento-erogazioni.component";
import { CategorieSalComponent } from "./components/categorieSal/categorie-sal.component";
import { MacroCategorieSalComponent } from "./components/macroCategorieSal/macro-categorie-sal.component";
import { InterventoSalComponent } from "./components/interventoSal/intervento-sal.component";
import { ItalianCurrencyPipeModule } from "../italian-currency-pipe/italian-currency-pipe.module";

import { AssociaProvvedimentiComponent } from "../inframob/components/associaProvvedimenti/associa-provvedimenti.component";
import { AssociaAnnualitaComponent } from "../inframob/components/associaAnnualita/associa-annualita.component";
import { DocumentazioneComponent } from "./components/documentazione/documentazione.component";

import { CompleterModule } from "../../components/_components/completer/completer.module";

import { ProgettoDivDettaglioComponent } from "../inframob/components/progettoDivDettaglio/progetto-div-dettaglio.component";
import { RiepilogoProgettoComponent } from "../inframob/components/riepilogoProgetto/riepilogo-progetto.component";
import { RiepilogoInterventoComponent } from "../inframob/components/riepilogoIntervento/riepilogo-intervento.component";
import { RiepilogoFinanziamentoComponent } from "../inframob/components/riepilogoFinanziamento/riepilogo-finanziamento.component";
import { StoricoFinanziamentoComponent } from "../inframob/components/storicoFinanziamento/storico-finanziamento.component";
import { StoricoInterventoComponent } from "../inframob/components/storicoIntervento/storico-intervento.component";
import { StoricoPnrrLineaFinanziamentoComponent } from "../inframob/components/storicoPnrrLineaFinanziamento/storico-pnrr-linea-finanziamento.component";
import { StoricoDettaglioComponent } from "../inframob/components/storicoDettaglio/storico-dettaglio.component";

import { ValidazioneComponent } from "./components/validazione/validazione.component";

import { WebGisModule } from "../web-gis/web-gis.module";

import { DashboardFinanziariaComponent } from "../inframob/components/dashboardFinanziaria/dashboard-finanziaria.component";
import { DashboardErogazioniComponent } from "../inframob/components/dashboardErogazioni/dashboard-erogazioni.component";
import { MapComponent } from "./components/dashboardFinanziaria/map/map.component";

import { DashboardFinanziariaDetailsTableComponent } from "./components/dashboardFinanziaria/detailsTable/dashboard-finanziaria-details-table.component";
import { DashboardFasiDetailsTableComponent } from "./components/dashboard-fasi/detailsTable/dashboard-fasi-details-table.component";

import { LazyForDirective } from "../../directives/lazyFor.directive";
import { DashboardFasiComponent } from "./components/dashboard-fasi/dashboard-fasi.component";
import { DashboardStatoFinanziamentiComponent } from "./components/dashboard-stato-finanziamenti/dashboard-stato-finanziamenti.component";
import { DashboardStatoFinanziamentiDetailsTableComponent } from "./components/dashboard-stato-finanziamenti/detailsTable/dashboard-stato-finanziamenti-details-table.component";

import { StoricoGeneraleComponent } from "./components/storicoGenerale/storico-generale.component";

import { DashboardStatoInterventiComponent } from "./components/dashboard-stato-interventi/dashboard-stato-interventi.component";
import{SpostamentoAnnualitaComponent} from "./components/spostamentoAnnualita/spostamento-annualita.component";
import { DettaglioProvvedimentiComponent } from './components/provvedimenti/dettaglio-provvedimenti/dettaglio-provvedimenti.component';
import{StoricoProvvedimentoComponent} from './components/provvedimenti/storicoProvvedimento/storico-provvedimento.component';

import{GestioneMassivaFasiComponent} from './components/gestioneMassivaFasi/gestione-massiva-fasi.component';
import { GestioneMassivaFasiDetailsTableComponent } from "./components/gestioneMassivaFasi/detailsTable/gestione-massiva-fasi-details-table.component";

import{GestioneMassivaClassificazioniComponent} from './components/gestioneMassivaClassificazioni/gestione-massiva-classificazioni.component';
import{GestioneMassivaClassificazioniDetailsTableComponent} from './components/gestioneMassivaClassificazioni/detailsTable/gestione-massiva-classificazioni-details-table.component';

import{GestioneMotivazioniComponent} from './components/gestioneMotivazioni/gestione-motivazioni.component';

import {DashboardFasiGanttComponent} from './components/dashboard-fasi-gantt/dashboard-fasi-gantt.component';
import { ChartGanttComponent } from './components/dashboard-fasi-gantt/chart-gantt/chart-gantt.component';
import { DashboardInterventiGanttComponent } from "./components/dashboard-interventi-gantt/dashboard-interventi-gantt.component";
import { ChartGanttInterventiComponent } from "./components/dashboard-interventi-gantt/chart-gantt-interventi/chart-gantt-interventi.component";

import{SpostamentoMassivoInterventiComponent} from "./components/spostamentoMassivoInterventi/spostamento-massivo-interventi.component";
import{SpostamentoMassivoInterventiDetailsTableComponent} from "./components/spostamentoMassivoInterventi/detailsTable/spostamento-massivo-interventi-details-table.component";

import{MotivazioniCancellazioneComponent} from "./components/motivazioniCancellazione/motivazioni-cancellazione.component";

import{MonitoraggioDistanzaInterventoComponent} from "./components/monitoraggioDistanzeIntervento/monitoraggio-distanze-intervento.component";
import{MonitoraggioSitiReteNaturaComponent} from "./components/monitoraggioSitiReteNatura/monitoraggio-siti-rete-natura.component";
import{MonitoraggioComponent} from "./components/monitoraggio/monitoraggio.component";
import{MonitoraggiComponent} from "./components/monitoraggi/monitoraggi.component";

import{DocumentazioneCompletaComponent} from "./components/documentazioneCompleta/documentazione-completa.component";

import { FinanziamentoDivDettaglioComponent } from "../inframob/components/finanziamentoDivDettaglio/finanziamento-div-dettaglio.component";

import{GestioneMassivaAreeTematicheComponent} from './components/gestioneMassivaAreeTematiche/gestione-massiva-aree-tematiche.component';
import{GestioneMassivaAreeTematicheDetailsTableComponent} from './components/gestioneMassivaAreeTematiche/detailsTable/gestione-massiva-aree-tematiche-details-table.component';
import{FinanziamentoUsatoDaInterventiComponent} from './components/finanziamentoUsatoDaInterventi/finanziamento-usato-da-interventi.component';
import {StatoAttuazioneComponent} from './components/statoAttuazione/stato-attuazione.component'

import {
    PnrrManagementComponent,PnrrMissioneManagementComponent,PnrrComponenteManagementComponent
    ,PnrrLineaFinanziamentoManagementComponent
  } from "./components/pnrrManagement/index";
import { SearchFinanziamentoComponent } from './components/search-area/search-finanziamento/search-finanziamento.component';
import { SearchAreaComponent } from './components/search-area/search-area.component';
import { SearchProgettoComponent } from './components/search-area/search-progetto/search-progetto.component';
import { SearchProvvedimentoComponent } from './components/search-area/search-provvedimento/search-provvedimento.component';
import { SearchInterventoComponent } from './components/search-area/search-intervento/search-intervento.component';
import { SearchDocumentazioneComponent } from './components/search-area/search-documentazione/search-documentazione.component';
import { DnshComponent } from './components/dnsh/dnsh.component';

import { AssociaProvvedimentiDocumentiComponent } from './components/associa-provvedimenti-documenti/associa-provvedimenti-documenti.component';
import { BdapComponent } from './components/bdap/bdap.component';
import { SicerBilancioComponent } from './components/sicerBilancio/sicer-bilancio.component';
import { SicerBilancioCapitoloComponent } from './components/sicerBilancio/sicer-bilancio-capitolo/sicer-bilancio-capitolo.component';
import { SicerBilancioCapitoloDettaglioComponent } from './components/sicerBilancio/sicer-bilancio-capitolo/sicer-bilancio-capitolo-dettaglio/sicer-bilancio-capitolo-dettaglio.component';
import { SicerBilancioAccertamentiComponent } from './components/sicerBilancio/sicer-bilancio-accertamenti/sicer-bilancio-accertamenti.component';
import { CapitoloInfoComponent } from './components/sicerBilancio/sicer-bilancio-capitolo/sicer-bilancio-capitolo-dettaglio/capitolo-info/capitolo-info.component';
import { DettaglioOrdinativoComponent } from './components/sicerBilancio/sicer-bilancio-accertamenti/dettaglio-ordinativo/dettaglio-ordinativo.component';
import { DettaglioLiquidazioneComponent } from './components/sicerBilancio/sicer-bilancio-accertamenti/dettaglio-liquidazione/dettaglio-liquidazione.component';
import { SicerGestioneMicroservicesComponent } from './components/sicer-gestione-microservices/sicer-gestione-microservices.component';


import{InitService} from './services/init.service';
import { GestioneNaturaTipologieComponent } from './components/gestione-natura-tipologie/gestione-natura-tipologie.component';
import { GestioneTipologiaInterventiComponent } from './components/gestione-tipologia-interventi/gestione-tipologia-interventi.component';

import{DashboardPnrrComponent} from './components/dashboard-pnrr/dashboard-pnrr.component';
import{DashboardPkmgComponent} from './components/dashboard-pkmg/dashboard-pkmg.component';
import { DashboardEntrataUscitaComponent } from "./components/dashboard-entrata-uscita/dashboard-entrata-uscita.component";
import { GestioneBancheDatiEsterneComponent } from './components/gestioneBancheDatiEsterne/gestione-banche-dati-esterne.component';
import { GeolocalizzazioneSelectionModalComponent } from './components/datiGenerali/geolocalizzazione-selection-modal/geolocalizzazione-selection-modal.component';
import { AlertMessageModalComponent } from './components/alert-message-modal/alert-message-modal.component';
import { GestioneAggregatiComponent } from './components/gestione-aggregati/gestione-aggregati.component';
import { RichiestaCapitoloSicerComponent } from './components/richiesta-capitolo-sicer/richiesta-capitolo-sicer.component';
import { SubInterventiComponent } from './components/sub-interventi/sub-interventi.component';
import { TipologieSismaComponent } from './components/tipologieSisma/tipologie-sisma.component';
import { CategorieSismaComponent } from './components/categorie-sisma/categorie-sisma.component';
import { InterventiSismaComponent } from './components/interventi-sisma/interventi-sisma.component';
import {DashboardPnrrPubblicaComponent} from './components/dashboard-pnrr-pubblica/dashboard-pnrr-pubblica.component';
import {DashboardPnrrPubblicaBisComponent} from './components/dashboard-pnrr-pubblica-bis/dashboard-pnrr-pubblica-bis.component';

import { MappaNonDisegnabileComponent } from './components/mappa-non-disegnabile/mappa-non-disegnabile.component';
import { DashboardPnrrNonDisegnabileComponent } from './components/dashboard-pnrr-non-disegnabile/dashboard-pnrr-non-disegnabile.component';
import { ChartInstogrammaInPilaComponent } from './components/chart-instogramma-in-pila/chart-instogramma-in-pila.component';
import { ChartAssiImpilatiOrizzontalmenteComponent } from './components/chart-assi-impalati-orizzontalmente/chart-assi-impilati-orizzontalmente.component';
import { ChartPnrrLineeComponent } from './components/chart-pnrr-linee/chart-pnrr-linee.component';
import { ChartPnrrInterventiProvincieComponent } from './components/chart-pnrr-interventi-province/chart-pnrr-interventi-provincie.component';
import { ChartPnrrInterventiComuniComponent } from './components/chart-pnrr-interventi-comuni/chart-pnrr-interventi-comuni.component';
import { ChartPnrrRisorseProvincieComponent } from './components/chart-pnrr-risorse-province/chart-pnrr-risorse-provincie.component';
import { ChartPnrrRisorseComuniComponent } from './components/chart-pnrr-risorse-comuni/chart-pnrr-risorse-comuni.component';
import { ChartPnrrNaturaTipologieComponent } from './components/chart-pnrr-natura-tipologie/chart-pnrr-natura-tipologie.component';
import { ChartPnrrNaturaImportiComponent } from './components/chart-pnrr-natura-importi/chart-pnrr-natura-importi.component';
import { DashboardAnalisiReportingRisorseProgettiFinanziatiPnrrComponent } from './components/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr.component';
import { ChartTotSpesaEffettivaSpesaPrevistaComponent } from './components/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr/chart-tot-spesa-effettiva-spesa-prevista/chart-tot-spesa-effettiva-spesa-prevista.component';
import { CruscottoNoteAttiComponent } from './components/cruscottoNoteAtti/cruscotto-note-atti.component';
import { GestioneMenuComponent } from './components/gestione-menu/gestione-menu.component';
import { ChartAvanzamentoMtComponent } from './components/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr/chart-avanzamento-mt/chart-avanzamento-mt.component';
import { PnrrTargetMilestoneComponent } from './components/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr/pnrr-target-milestone/pnrr-target-milestone.component';
import { PnrrTabTargetMilestoneComponent } from './components/dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr/pnrr-tab-target-milestone/pnrr-tab-target-milestone.component';
import { HeaderDashboardComponent } from 'src/app/components/_components/header-dashboard/header-dashboard.component';
// export function init_app(appLoadService: InitService) {
//     return () => appLoadService.initializeApp();
// }

@NgModule({
  declarations: [
    InframobComponent,
    SoggettiRuoliComponent,
    AreeTematicheComponent,
    TipoClassificazioneComponent,
    ClassificazioneComponent,
    StatiFinanziamentoComponent,
    SoggettiGiuridiciComponent,
    TipiFinanziamentoComponent,
    TipiProvvedimentoComponent,
    ProgettiComponent,
    DatiGeneraliComponent,
    GenericSelectionModalComponent,
    SoggettoGiuridicoSelectionModalComponent,
    TemiPrioritariComponent,
    TipologieFasiComponent,
    ProgettoComponent,
    ScopiProvvedimentoComponent,
    CapitoliBilancioComponent /*, CapitoloBilancioEditModalComponent*/,
    ProvvedimentiComponent,
    ProvvedimentoCapitoloBilancioSelectionModalComponent,
    FasiComponent,
    TipologieSoggettoResponsabileComponent,
    SoggettoResponsabileComponent,
    TipiStrumentoAttuativoComponent,
    FinanziamentoComponent,
    FinanziamentiComponent,

    InterventiComponent,
    InterventoComponent,
    InterventoFinanziamentoComponent,
    TipologieErogazioniComponent,
    InterventoErogazioniComponent,
    CategorieSalComponent,
    MacroCategorieSalComponent,
    InterventoSalComponent,
    AssociaProvvedimentiComponent,
    AssociaAnnualitaComponent,
    ProgettoDivDettaglioComponent,
    DocumentazioneComponent,
    RiepilogoProgettoComponent,
    RiepilogoFinanziamentoComponent,
    RiepilogoInterventoComponent,
    ValidazioneComponent,
    StoricoFinanziamentoComponent,
    StoricoInterventoComponent,
    StoricoDettaglioComponent,
    DashboardFinanziariaComponent,
    DashboardErogazioniComponent,
    MapComponent,
    DashboardFinanziariaDetailsTableComponent,
    DashboardFasiDetailsTableComponent,
    LazyForDirective,
    DashboardFasiComponent,
    DashboardStatoFinanziamentiComponent,
    DashboardStatoFinanziamentiDetailsTableComponent,
    StoricoGeneraleComponent,
    DashboardStatoInterventiComponent,
    SpostamentoAnnualitaComponent,
    DettaglioProvvedimentiComponent,
    StoricoProvvedimentoComponent,
    GestioneMassivaFasiComponent,
    GestioneMassivaFasiDetailsTableComponent,
    GestioneMassivaClassificazioniComponent,
    GestioneMassivaClassificazioniDetailsTableComponent,
    GestioneMassivaAreeTematicheDetailsTableComponent,
    GestioneMassivaAreeTematicheComponent,
    GestioneMotivazioniComponent,
    DashboardFasiGanttComponent,
    ChartGanttComponent,
    DashboardInterventiGanttComponent,
    ChartGanttInterventiComponent,
    SpostamentoMassivoInterventiComponent,
    SpostamentoMassivoInterventiDetailsTableComponent,
    MotivazioniCancellazioneComponent,
    MonitoraggioDistanzaInterventoComponent,
    MonitoraggioSitiReteNaturaComponent,
    MonitoraggioComponent
    ,MonitoraggiComponent
    ,DocumentazioneCompletaComponent
    ,FinanziamentoDivDettaglioComponent
    ,FinanziamentoUsatoDaInterventiComponent
    ,StatoAttuazioneComponent
    ,PnrrManagementComponent
    ,PnrrMissioneManagementComponent
    ,PnrrComponenteManagementComponent
    ,PnrrLineaFinanziamentoManagementComponent
    ,StoricoPnrrLineaFinanziamentoComponent,
    SearchAreaComponent,
    SearchFinanziamentoComponent,
    FinanziamentiComponent,
    SearchProgettoComponent,
    SearchProvvedimentoComponent,
    SearchInterventoComponent,
    SearchDocumentazioneComponent,
    DnshComponent,
    AssociaProvvedimentiDocumentiComponent,
    BdapComponent,
    SicerBilancioComponent,
    SicerBilancioCapitoloComponent,
    SicerBilancioCapitoloDettaglioComponent,
    CapitoloInfoComponent,
    SicerBilancioAccertamentiComponent,
    DettaglioOrdinativoComponent,
    DettaglioLiquidazioneComponent,
    SicerGestioneMicroservicesComponent,
    GestioneNaturaTipologieComponent,
    GestioneTipologiaInterventiComponent,
    DashboardPnrrComponent,
    DashboardPkmgComponent,
    DashboardEntrataUscitaComponent,
    GestioneBancheDatiEsterneComponent,
    GeolocalizzazioneSelectionModalComponent,
    AlertMessageModalComponent,
    GestioneAggregatiComponent,
    RichiestaCapitoloSicerComponent,
    SubInterventiComponent,
    TipologieSismaComponent,
    CategorieSismaComponent,
    InterventiSismaComponent,
    DashboardPnrrPubblicaComponent,
    DashboardPnrrPubblicaBisComponent,
    MappaNonDisegnabileComponent,
    DashboardPnrrNonDisegnabileComponent,
    ChartInstogrammaInPilaComponent,
    ChartAssiImpilatiOrizzontalmenteComponent,
    ChartPnrrLineeComponent,
    ChartPnrrInterventiProvincieComponent,
    ChartPnrrInterventiComuniComponent,
    ChartPnrrRisorseProvincieComponent,
    ChartPnrrRisorseComuniComponent,
    ChartPnrrNaturaTipologieComponent,
    ChartPnrrNaturaImportiComponent,
    DashboardAnalisiReportingRisorseProgettiFinanziatiPnrrComponent,
    ChartTotSpesaEffettivaSpesaPrevistaComponent,
    CruscottoNoteAttiComponent,
    GestioneMenuComponent,
    ChartAvanzamentoMtComponent,
    PnrrTargetMilestoneComponent,
    PnrrTabTargetMilestoneComponent,
    HeaderDashboardComponent
  ],
  exports: [
    InframobComponent,
    SoggettiRuoliComponent,
    AreeTematicheComponent,
    TipoClassificazioneComponent,
    ClassificazioneComponent,
    StatiFinanziamentoComponent,
    SoggettiGiuridiciComponent,
    TipiFinanziamentoComponent,
    TipiProvvedimentoComponent,
    ProgettiComponent,
    GenericSelectionModalComponent,
    TemiPrioritariComponent,
    TipologieFasiComponent,
    ProgettoComponent,
    CapitoliBilancioComponent,
    ProvvedimentiComponent,
    FasiComponent,
    DocumentazioneComponent,
  ],

  imports: [
    CommonModule,
    InfraMobRoutingModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    FormsModule,
    ShowHidePasswordModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    NgSelectModule,
    Ng2CompleterModule,
    OrderModule,
    ColorPickerModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-left",
      progressBar: true,
      maxOpened: 1,
      newestOnTop: true,
      autoDismiss: true,
      timeOut: 2000,
    }),
    NgxMaskModule.forRoot(),

    NgxDatatableModule,
    NgxDatatableWrapperModule,
    ItalianCurrencyPipeModule,
    IMaskModule,
    UploadFileModule,
    CompleterModule,
    AppLoadingModule,
    WebGisModule ,
    NgxInputSwitchModule

  ],
  providers: [
    CookieService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    InitService,
    // {provide:APP_INITIALIZER,useFactory:init_app,deps:[InitService],multi:true}
  ],
  entryComponents: [
    GenericSelectionModalComponent,
    SoggettoGiuridicoSelectionModalComponent,
    GeolocalizzazioneSelectionModalComponent,
    ProvvedimentoCapitoloBilancioSelectionModalComponent,
    StoricoDettaglioComponent,
    SpostamentoAnnualitaComponent,
    InterventoComponent
  ],
})
export class InfraMobModule {}
