<div class="row">
<div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
<div
  *ngIf="message && show"
  class="Prose Alert  Alert--withIcon u-layout-prose u-padding-r-bottom u-padding-r-right u-margin-r-bottom container mb-0 mt-3 text-center"
  [ngClass]="{
    alert: message,
    'alert-success': message.type === 'success',
    'alert-danger': message.type === 'error'
  }"
>
  {{ message.text }}
</div>
</div>
</div>