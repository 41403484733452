<!-- Fascia Governo & Servizi -->
<nav [hidden]="
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-entrata-uscita') ||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr') ||
    router.url.includes('dashboard-sisma')||
    router.url.includes('cruscotto-note-atti')
  " class="navbar navbar-expand-sm preheader sticky-top" style="z-index: 1022">
  <div class="container">
    <a title="{{ente.nome}}" href="{{ente.sitoWeb}}" target="_blank" rel=”noopener”>{{ente.nome}}
    </a><span class="text-white" style="font-size: 16px">{{
      titleExtraText
      }}</span>

    <div class="float-right" *ngIf="isUserlogged()">

      <a routerLink="account/me"><span>{{ getCurrentUsername() }}</span></a>&nbsp;&nbsp;
      <a>
        <i class="fas fa-user-tie text-white" [popover]="popTemplate"></i>
      </a>
    </div>
  </div>
</nav>

<!-- Modal -->
<div class="modal fade" id="warning" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Avvisi</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="list-group text-0083e0">
          <a href="#" class="list-group-item list-group-item-action text-0083e0"><i
              class="fas fa-arrow-alt-circle-right"></i>&nbsp;Dapibus ac
            facilisis in</a>
          <a href="#" class="list-group-item list-group-item-action text-0083e0"><i
              class="fas fa-arrow-alt-circle-right"></i>&nbsp;Morbi leo
            risus</a>
          <a href="#" class="list-group-item list-group-item-action text-0083e0"><i
              class="fas fa-arrow-alt-circle-right"></i>&nbsp;Porta ac
            consectetur ac</a>
          <a href="#" class="list-group-item list-group-item-action text-0083e0"><i
              class="fas fa-arrow-alt-circle-right"></i>&nbsp;Vestibulum at
            eros</a>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Chiudi
        </button>
        <!--<button type="button" class="btn btn-primary">Save changes</button>-->
      </div>
    </div>
  </div>
</div>


<header [hidden]="
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-entrata-uscita') ||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr') ||
    router.url.includes('dashboard-sisma')||
    router.url.includes('cruscotto-note-atti')
  " class="mainheader">

  <!-- Logo E Titolo -->

  <div [hidden]="isHeaderCustom()" class="container" [ngClass]="{
      fascia_titolo_login: isUserlogged(),
      fascia_titolo: !isUserlogged()
    }" >

    <div class="d-flex justify-content-between align-items-center">


      <div class="logo-img d-flex align-items-center" [ngClass]="{
        'logo-img-login': isUserlogged(),
        'logo-img': !isUserlogged()
      }">
        <img class="" style="height: 85px; " src="{{ente.logo}}"
          alt="logo {{ente.nome}}" />

        <h4 class="m-0 text-uppercase">{{ente.nome}}</h4>
      </div>

      <div class=" flex-nowrap" (click)="goToHome()" style="cursor: pointer" title="Vai alla Home Page">
        <img class=" logo-app my-2" style="height: 85px; " src="{{logo}}"
          alt="logo {{title}}" />
      </div>
    </div>
  </div>
  <!-- Logo e Titolo Fine-->
</header>
<div *ngIf="isUserlogged()" #menuElement [class.menu_fisso]="menuFixed" class="sticky-top"
  style="top: 40px; z-index: 1021">
  <app-header-menu *ngIf="router.url !== '/'"></app-header-menu>
  <app-bar-menu *ngIf="router.url === '/'"></app-bar-menu>
</div>


<ng-template #popTemplate>
  <ul class="popover-menu" style="padding: 0px !important">
    <li>
      <form action="/logout" method="post">
        <a (click)="signOut()">LOG OUT &nbsp;&nbsp;<i class="fa fa-sign-out-alt"></i></a>
      </form>
    </li>
    <li *ngIf="isRegistered()">
      <a routerLink="account/me">ACCOUNT&nbsp;<i class="fa fa-user-circle"></i></a>
    </li>
    <li *ngIf="!isLdapUser">
      <a routerLink="changePassword">MODIFICA PASSWORD&nbsp;<i class="fa fa-key"></i></a>
    </li>
  </ul>
</ng-template>
