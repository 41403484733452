import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { Intervento } from "../models";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}dashboard/`;
  private publicApiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob_public}dashboard/`;
  constructor(private http: HttpClient) {}

  getDashboardErogazioni() {
    return this.http.get<any>(`${this.apiUrl}erogazioni`);
  }

  getDashboardErogazioniDetails(
    ruoloId: number,
    soggettoId: number,
    filterIntervento: string = ""
  ) {
    if (!filterIntervento) filterIntervento = "";
    if (!soggettoId) soggettoId = -1;
    return this.http.get<any>(
      `${this.apiUrl}erogazioni/details?ruoloId=${ruoloId}&soggettoId=${soggettoId}&filterIntervento=${filterIntervento}`
    );
  }

  getDashboardErogazioniDetailsExport(
    ruoloId: number,
    soggettoId: number,
    filterIntervento: string = ""
  ) {
    if (!filterIntervento) filterIntervento = "";
    if (!soggettoId) soggettoId = -1;
    return this.http.get(
      `${this.apiUrl}erogazioni/details/export?ruoloId=${ruoloId}&soggettoId=${soggettoId}&filterIntervento=${filterIntervento}`,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  esportaDashboardPnrr(filterObject) {
    return this.http.get(`${this.apiUrl}pnrr/export`, {
      observe: "response",
      responseType: "blob",
      params: { filterObject: JSON.stringify(filterObject) },
    });
  }

  esportaDashboardPnrrWithLatLong(filterObject) {
    return this.http.get(`${this.apiUrl}pnrr/export`, {
      observe: "response",
      responseType: "blob",
      params: { filterObject: JSON.stringify(filterObject), withLatLong: "1" },
    });
  }

  getDashboardFinanziariaExport(filterObject) {
    if (!filterObject.filterStatus) filterObject.filterStatus = [-1];
    if (!Array.isArray(filterObject.filterStatus))
      filterObject.filterStatus = [filterObject.filterStatus];
    else if (filterObject.filterStatus.length <= 0)
      filterObject.filterStatus.push(-1);
    if (!filterObject.filterTipologiaIntervento)
      filterObject.filterTipologiaIntervento = [-1];
    if (!Array.isArray(filterObject.filterTipologiaIntervento))
      filterObject.filterTipologiaIntervento = [
        filterObject.filterTipologiaIntervento,
      ];
    else if (filterObject.filterTipologiaIntervento.length <= 0)
      filterObject.filterTipologiaIntervento.push(-1);
    if (!filterObject.filterFonteFinanziamentoIds)
      filterObject.filterFonteFinanziamentoIds = [-1];
    if (!Array.isArray(filterObject.filterFonteFinanziamentoIds))
      filterObject.filterFonteFinanziamentoIds = [
        filterObject.filterFonteFinanziamentoIds,
      ];
    else if (filterObject.filterFonteFinanziamentoIds.length <= 0)
      filterObject.filterFonteFinanziamentoIds.push(-1);
    if (!filterObject.filterAggregato) filterObject.filterAggregato = [-1];
    if (!Array.isArray(filterObject.filterAggregato))
      filterObject.filterAggregato = [filterObject.filterAggregato];
    else if (filterObject.filterAggregato.length <= 0)
      filterObject.filterAggregato.push(-1);

    return this.http.get(`${this.apiUrl}finanziaria/export`, {
      observe: "response",
      responseType: "blob",
      params: { filterObject: JSON.stringify(filterObject) },
    });
  }

  getDashboardStatoInterventiExport(
    // progettoId: number,
    // interventoId: number,
    // validationStatusCode: any,
    // idAreaTematica: number,
    // idTipoClassificazione: number,
    // idSoggetto: number,
    // anno: string,
    filterObject
  ) {
    if (!filterObject.filterStatus) filterObject.filterStatus = [-1];
    if (!Array.isArray(filterObject.filterStatus))
      filterObject.filterStatus = [filterObject.filterStatus];

    return this.http.get(`${this.apiUrl}statoInterventi/detailTable/export`, {
      observe: "response",
      responseType: "blob",
      params: { filterObject: JSON.stringify(filterObject) },
    });
  }

  getDashboardFasiData(
    table: string,
    elementoId: number,
    interventoId: number,
    codiceStatoFase: number,
    idAreaTematica: number,
    idTipoClassificazione: number,
    idTipologiaSoggetto: number,
    idTipologiaFase: number,
    idSoggetto: number,
    flussoDiCassa: number,
    filterPnrr: any = "-1",
    filterLineeFinanziamentiPnrr: any = null,
    filterSoggettoAttuatore: number = -1,
    filterRp: number = -1,
    filterRup: number = -1,
    filterComune: number = -1,
    dataPrevistaInizio: any = null,
    dataPrevistaFine: any = null,
    dataInizio: any = null,
    dataFine: any = null,
    filterTipologieFaseFase: any = null,
    filterTipologieFaseTitolo: any = null
  ) {
    return this.http.post<any[]>(
      `${this.apiUrl}fasi/${
        idAreaTematica ? idAreaTematica : -1
      }/${table}/${elementoId}/stato/${codiceStatoFase}/tipoClassificazione/${idTipoClassificazione}/soggettoResponsabile/${idTipologiaSoggetto}/soggetto/${idSoggetto}/tipologiaFase/${idTipologiaFase}/simple?flussoDiCassa=${flussoDiCassa}&filterPnrr=${filterPnrr}&filterSoggettoAttuatore=${filterSoggettoAttuatore}&rp=${filterRp}&rup=${filterRup}&comune=${filterComune}&dataPrevistaInizio=${
        dataPrevistaInizio || ""
      }&dataPrevistaFine=${dataPrevistaFine || ""}&dataInizio=${
        dataInizio || ""
      }&dataFine=${
        dataFine || ""
      }&filterTipologieFaseFase=${filterTipologieFaseFase}&filterTipologieFaseTitolo=${filterTipologieFaseTitolo}`,
      filterLineeFinanziamentiPnrr
    );
  }

  getDashboardFasiObjectList(filterObject: any) {
    return this.http.get<any[]>(`${this.publicApiUrl}fasi/objectlist`, {
      params: { filterObject: JSON.stringify(filterObject) },
    });
  }

  getDashboardFasiDetailTableData(
    progettoId: number,
    interventoId: number,
    finanziamentoId: number,
    idAreaTematica: number,
    idTipoClassificazione: number,
    mainType: string,
    idTipologiaSoggettoResponsabile: number,
    idTipologiaFase: number,
    idStatoFase: number,
    soggettoResponsabileId: number,
    flussoDiCassa: number,
    idTemaPrioritario: number,
    filterPnrr: any = -1,
    filterLineeFinanziamentiPnrr: any = null,
    filterSoggettoAttuatore: number = -1,
    filterRp: number = -1,
    filterRup: number = -1,
    filterComune: number = -1,
    dataPrevistaInizio: any = null,
    dataPrevistaFine: any = null,
    dataInizio: any = null,
    dataFine: any = null,
    filterTipologieFaseFase: any = null,
    filterTipologieFaseTitolo: any = null
  ) {
    return this.http.post<any[]>(
      `${
        this.apiUrl
      }fasi/detailTable?progettoId=${progettoId}&interventoId=${interventoId}&finanziamentoId=${finanziamentoId}&idAreeTematiche=${idAreaTematica}&idTipoClassificazione=${idTipoClassificazione}&mainType=${mainType}&idTipologiaSoggettoResponsabile=${idTipologiaSoggettoResponsabile}&idTipologiaFase=${idTipologiaFase}&idStatoFase=${idStatoFase}&soggettoResponsabileId=${soggettoResponsabileId}&flussoDiCassa=${flussoDiCassa}&idTemaPrioritario=${idTemaPrioritario}&filterPnrr=${filterPnrr}&filterSoggettoAttuatore=${filterSoggettoAttuatore}&rp=${filterRp}&rup=${filterRup}&comune=${filterComune}&dataPrevistaInizio=${
        dataPrevistaInizio || ""
      }&dataPrevistaFine=${dataPrevistaFine || ""}&dataInizio=${
        dataInizio || ""
      }&dataFine=${
        dataFine || ""
      }&filterTipologieFaseFase=${filterTipologieFaseFase}&filterTipologieFaseTitolo=${filterTipologieFaseTitolo}`,
      filterLineeFinanziamentiPnrr
    );
  }

  getDashboardFasiDetailTableDataExport(
    progettoId: number,
    interventoId: number,
    finanziamentoId: number,
    idAreaTematica: number,
    idTipoClassificazione: number,
    mainType: string,
    idTipologiaSoggettoResponsabile: number,
    idTipologiaFase: number,
    idStatoFase: number,
    soggettoResponsabileId: number,
    flussoDiCassa,
    idTemaPrioritario: number,
    filterPnrr: any = "-1",
    filterLineeFinanziamentiPnrr: any = null,
    filterSoggettoAttuatore: number = -1,
    filterRp: number = -1,
    isSisma: boolean = false,
    filterRup: number = -1,
    filterComune: number = -1,
    dataPrevistaInizio: any = null,
    dataPrevistaFine: any = null,
    dataInizio: any = null,
    dataFine: any = null,
    filterTipologieFaseFase: any = null,
    filterTipologieFaseTitolo: any = null
  ) {
    return this.http.post(
      `${
        this.apiUrl
      }fasi/detailTable/export?progettoId=${progettoId}&interventoId=${interventoId}&finanziamentoId=${finanziamentoId}&idAreeTematiche=${idAreaTematica}&idTipoClassificazione=${idTipoClassificazione}&mainType=${mainType}&idTipologiaSoggettoResponsabile=${idTipologiaSoggettoResponsabile}&idTipologiaFase=${idTipologiaFase}&idStatoFase=${idStatoFase}&soggettoResponsabileId=${soggettoResponsabileId}&flussoDiCassa=${flussoDiCassa}&idTemaPrioritario=${idTemaPrioritario}&filterPnrr=${filterPnrr}&filterSoggettoAttuatore=${filterSoggettoAttuatore}&rp=${filterRp}&isSisma=${isSisma}&rup=${filterRup}&comune=${filterComune}&dataPrevistaInizio=${
        dataPrevistaInizio || ""
      }&dataPrevistaFine=${dataPrevistaFine || ""}&dataInizio=${
        dataInizio || ""
      }&dataFine=${
        dataFine || ""
      }&filterTipologieFaseFase=${filterTipologieFaseFase}&filterTipologieFaseTitolo=${filterTipologieFaseTitolo}`,
      filterLineeFinanziamentiPnrr,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  //dashboard stato finanziamenti

  getDashboardStatoFinanziamentiData(
    idAreaTematica: number,
    fonteId: any, //vettore di id
    strumentoId: number,
    dispSiNo: number,
    anno: string,
    finanziamentoId: any //vettore di id
  ) {
    if (!fonteId) fonteId = [-1];
    if (!Array.isArray(fonteId)) fonteId = [fonteId];
    else if (fonteId.length <= 0) fonteId.push(-1);

    if (!finanziamentoId) finanziamentoId = [-1];
    if (!Array.isArray(finanziamentoId)) finanziamentoId = [finanziamentoId];
    else if (finanziamentoId.length <= 0) finanziamentoId.push(-1);
    return this.http.get<any[]>(
      `${
        this.apiUrl
      }finanziamento/${finanziamentoId}/statoFinanziamenti/areaTematica/${
        idAreaTematica ? idAreaTematica : -1
      }/fonte/${fonteId}/strumento/${strumentoId}/disponibilita/${dispSiNo}/anno/${anno}/simple`
    );
  }

  getAnniFinanziamenti() {
    return this.http.get<string[]>(`${this.apiUrl}statoFinanziamenti/anni`);
  }

  getDashboardStatoFinanziamentiDetailTableDataExport(
    idAreaTematica: number,
    fonteId: any,
    strumentoId: number,
    dispSiNo: number,
    anno: string,
    finanziamentoId: any
  ) {
    return this.http.get(
      `${this.apiUrl}statoFinanziamenti/detailTable/export?idAreaTematica=${idAreaTematica}&idFonte=${fonteId}&idStrumento=${strumentoId}&anno=${anno}&finanziamentoId=${finanziamentoId}`,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  getAnni() {
    return this.http.get<any[]>(`${this.apiUrl}anni`);
  }

  getDashboardFasiGanttExport(
    progettoId: number,
    interventoId: number,
    finanziamentoId: number,
    idAreaTematica: number,
    idTipoClassificazione: number,
    mainType: string,
    idTipologiaSoggettoResponsabile: number,
    idTipologiaFase: any,
    idStatoFase: number,
    soggettoResponsabileId: number,
    flussoDiCassa: any,
    tipologieRaggruppate: boolean,
    raggruppamentoMesi: string,
    idTemaPrioritario: any
  ) {
    if (idTipologiaFase.length == 0) idTipologiaFase = [-1];
    if (idTemaPrioritario.length == 0) idTemaPrioritario = [-1];
    return this.http.get(
      `${
        this.apiUrl
      }fasi/gantt/export?progettoId=${progettoId}&interventoId=${interventoId}&finanziamentoId=${finanziamentoId}&idAreeTematiche=${idAreaTematica}&idTipoClassificazione=${idTipoClassificazione}&mainType=${mainType}&idTipologiaSoggettoResponsabile=${idTipologiaSoggettoResponsabile}&idTipologiaFase=${idTipologiaFase}&idStatoFase=${idStatoFase}&soggettoResponsabileId=${soggettoResponsabileId}&flussoDiCassa=${flussoDiCassa}&tipologieRaggruppate=${
        tipologieRaggruppate ? "1" : "0"
      }&raggruppamentoMesi=${raggruppamentoMesi}&&idTemaPrioritario=${idTemaPrioritario}`,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }
  getDashboardFasiGanttData(
    progettoId: number,
    interventoId: number,
    finanziamentoId: number,
    idAreaTematica: number,
    idTipoClassificazione: number,
    mainType: string,
    idTipologiaSoggettoResponsabile: number,
    idTipologiaFase: any, //array
    idStatoFase: number,
    soggettoResponsabileId: number,
    flussoDiCassa: any,
    tipologieRaggruppate: boolean,
    idTemaPrioritario: any //array
  ) {
    if (idTipologiaFase.length == 0) idTipologiaFase = [-1];
    if (idTemaPrioritario.length == 0) idTemaPrioritario = [-1];
    return this.http.get(
      `${
        this.apiUrl
      }fasi/gantt?progettoId=${progettoId}&interventoId=${interventoId}&finanziamentoId=${finanziamentoId}&idAreeTematiche=${idAreaTematica}&idTipoClassificazione=${idTipoClassificazione}&mainType=${mainType}&idTipologiaSoggettoResponsabile=${idTipologiaSoggettoResponsabile}&idTipologiaFase=${idTipologiaFase}&idStatoFase=${idStatoFase}&soggettoResponsabileId=${soggettoResponsabileId}&flussoDiCassa=${flussoDiCassa}&tipologieRaggruppate=${
        tipologieRaggruppate ? "1" : "0"
      }&idTemaPrioritario=${idTemaPrioritario}`
    );
  }
  getDashboardPnrrSimplData(filterObject) {
    if (!filterObject.filterTipologiaIntervento)
      filterObject.filterTipologiaIntervento = [-1];
    if (!Array.isArray(filterObject.filterTipologiaIntervento))
      filterObject.filterTipologiaIntervento = [
        filterObject.filterTipologiaIntervento,
      ];
    else if (filterObject.filterTipologiaIntervento.length <= 0)
      filterObject.filterTipologiaIntervento.push(-1);
    if (!filterObject.filterAggregato) filterObject.filterAggregato = [-1];
    if (!Array.isArray(filterObject.filterAggregato))
      filterObject.filterAggregato = [filterObject.filterAggregato];
    else if (filterObject.filterAggregato.length <= 0)
      filterObject.filterAggregato.push(-1);

    filterObject.filterSimpleInterventionId =
      filterObject.filterSimpleInterventionId !== null &&
      filterObject.filterSimpleInterventionId > 0
        ? filterObject.filterSimpleInterventionId
        : -1;

    return this.http.get<Intervento[]>(
      `${this.publicApiUrl}direzioni/aree/simpl`,
      { params: { filterObject: JSON.stringify(filterObject) } }
    );
  }

  getDashboardPnrrInterventiList(filterObject) {
    if (!filterObject.filterTipologiaIntervento)
      filterObject.filterTipologiaIntervento = [-1];
    if (!Array.isArray(filterObject.filterTipologiaIntervento))
      filterObject.filterTipologiaIntervento = [
        filterObject.filterTipologiaIntervento,
      ];
    else if (filterObject.filterTipologiaIntervento.length <= 0)
      filterObject.filterTipologiaIntervento.push(-1);
    if (!filterObject.filterAggregato) filterObject.filterAggregato = [-1];
    if (!Array.isArray(filterObject.filterAggregato))
      filterObject.filterAggregato = [filterObject.filterAggregato];
    else if (filterObject.filterAggregato.length <= 0)
      filterObject.filterAggregato.push(-1);

    filterObject.filterSimpleInterventionId =
      filterObject.filterSimpleInterventionId !== null &&
      filterObject.filterSimpleInterventionId > 0
        ? filterObject.filterSimpleInterventionId
        : -1;

    return this.http.get<Intervento[]>(
      `${this.publicApiUrl}direzioni/aree/simpl/interventiList`,
      { params: { filterObject: JSON.stringify(filterObject) } }
    );
  }

  getDashboardInterventiGanttExport(
    progettoId: number,
    interventoId: number,
    finanziamentoId: number,
    idAreaTematica: number,
    idTipoClassificazione: number,
    mainType: string,
    idTipologiaSoggettoResponsabile: number,
    idTipologiaFase: any,
    idStatoFase: number,
    soggettoResponsabileId: number,
    flussoDiCassa: any,
    tipologieRaggruppate: boolean,
    raggruppamentoMesi: string
  ) {
    if (idTipologiaFase.length == 0) idTipologiaFase = [-1];
    return this.http.get(
      `${
        this.apiUrl
      }interventi/gantt/export?progettoId=${progettoId}&interventoId=${interventoId}&finanziamentoId=${finanziamentoId}&idAreeTematiche=${idAreaTematica}&idTipoClassificazione=${idTipoClassificazione}&mainType=${mainType}&idTipologiaSoggettoResponsabile=${idTipologiaSoggettoResponsabile}&idTipologiaFase=${idTipologiaFase}&idStatoFase=${idStatoFase}&soggettoResponsabileId=${soggettoResponsabileId}&flussoDiCassa=${flussoDiCassa}&tipologieRaggruppate=${
        tipologieRaggruppate ? "1" : "0"
      }&raggruppamentoMesi=${raggruppamentoMesi}`,
      {
        observe: "response",
        responseType: "blob",
      }
    );
  }

  getDashboardFinanziariaDetailTableExport(filterObject) {
    if (!filterObject.filterStatus) filterObject.filterStatus = [-1];
    if (!Array.isArray(filterObject.filterStatus))
      filterObject.filterStatus = [filterObject.filterStatus];
    else if (filterObject.filterStatus.length <= 0)
      filterObject.filterStatus.push(-1);
    if (!filterObject.filterTipologiaIntervento)
      filterObject.filterTipologiaIntervento = [-1];
    if (!Array.isArray(filterObject.filterTipologiaIntervento))
      filterObject.filterTipologiaIntervento = [
        filterObject.filterTipologiaIntervento,
      ];
    else if (filterObject.filterTipologiaIntervento.length <= 0)
      filterObject.filterTipologiaIntervento.push(-1);
    if (!filterObject.filterFonteFinanziamentoIds)
      filterObject.filterFonteFinanziamentoIds = [-1];
    if (!Array.isArray(filterObject.filterFonteFinanziamentoIds))
      filterObject.filterFonteFinanziamentoIds = [
        filterObject.filterFonteFinanziamentoIds,
      ];
    else if (filterObject.filterFonteFinanziamentoIds.length <= 0)
      filterObject.filterFonteFinanziamentoIds.push(-1);
    if (!filterObject.filterAggregato) filterObject.filterAggregato = [-1];
    if (!Array.isArray(filterObject.filterAggregato))
      filterObject.filterAggregato = [filterObject.filterAggregato];
    else if (filterObject.filterAggregato.length <= 0)
      filterObject.filterAggregato.push(-1);

    return this.http.get(`${this.apiUrl}finanziaria/exportDetailTable`, {
      observe: "response",
      responseType: "blob",
      params: { filterObject: JSON.stringify(filterObject) },
    });
  }

  esportaDashboardEntrata(
    soggettoGiuridico: any = null,
    fontiFinanziamento: any = null,
    filterObject
  ) {
    if (!soggettoGiuridico) soggettoGiuridico = "";
    if (!fontiFinanziamento) fontiFinanziamento = "";
    if (!filterObject.intervento) filterObject.intervento = "";
    if (!filterObject.progettualita) filterObject.progettualita = "";
    if (!filterObject.direction) filterObject.direction = "";
    if (!filterObject.classification) filterObject.classification = "";
    return this.http.get(
      `${this.apiUrl}entrata?soggettoGiuridico=${soggettoGiuridico}&fontiFinanziamento=${fontiFinanziamento}`,
      {
        observe: "response",
        responseType: "blob",
        params: {
          filterObject: JSON.stringify(filterObject),
        },
      }
    );
  }

  esportaDashboardUscita(
    soggettoGiuridico: any = null,
    fontiFinanziamento: any = null,
    filterObject
  ) {
    if (!soggettoGiuridico) soggettoGiuridico = "";
    if (!fontiFinanziamento) fontiFinanziamento = "";
    if (!filterObject.intervento) filterObject.intervento = "";
    if (!filterObject.progettualita) filterObject.progettualita = "";
    if (!filterObject.direction) filterObject.direction = "";
    if (!filterObject.classification) filterObject.classification = "";
    return this.http.get(
      `${this.apiUrl}uscita?soggettoGiuridico=${soggettoGiuridico}&fontiFinanziamento=${fontiFinanziamento}`,
      {
        observe: "response",
        responseType: "blob",
        params: {
          filterObject: JSON.stringify(filterObject),
        },
      }
    );
  }

  getPnrrData(filtersObject: any) {
    return this.http.get<any>(`${this.publicApiUrl}pnrrData`, {
      params: { filterObject: JSON.stringify(filtersObject) },
    });
  }
}
