<div class="container bg-white">
  <div class="row clearfix mt-3">
    <div
      class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 offset-xl-2 offset-lg-2 mb-5 mt-2"
    >
      <div class="account-wall bg-white">
        <form [formGroup]="myGroup" class="form-registrazione">
          <div class="row mx-0">
            <div
              class="col-md-12 riepilogodati"
              *ngIf="userInfo.username != '' && hasRoles() && !isManager()"
            >
              <p>
                Tipo utente:
                <span>{{ roleDescription }}</span>
              </p>
            </div>
            <div
              class="col-md-12 riepilogodati"
              *ngIf="userInfo.username != '' && isManager()"
            >
              <p>
                <span class="mandatory">Tipo utente:</span>
              </p>
              <div
                class="form-group"
                [ngClass]="{ 'is-invalid': submitted && f.userType.errors }"
              >
                <ng-select
                  [formGroup]="myGroup"
                  formControlName="userType"
                  [items]="userTypes"
                  bindValue="key"
                  bindLabel="value"
                  (change)="onChangeUserType($event)"
                  placeholder="Seleziona tipologia utente"
                >
                </ng-select>
              </div>
              <div
                *ngIf="submitted && f.userType.errors"
                class="invalidFieldMessage"
                style="display: block"
              >
                <div
                  *ngIf="f.userType.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  Tipo di utente &egrave; obbligatorio
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-0 mt-3">
            <div class="user-type col-12" [hidden]="!isEsterno">
              <p for="enteAppartenenza" class="mandatory">
                Ente di appartenenza:
              </p>
              <div
                class="form-group"
                [ngClass]="{
                  'is-invalid': submitted && f.enteAppartenenza.errors
                }"
              >
                <ng-select
                  [formGroup]="myGroup"
                  formControlName="enteAppartenenza"
                  [items]="listaEnti"
                  bindValue="id"
                  bindLabel="denominazione"
                  placeholder="Seleziona ente"
                >
                </ng-select>
              </div>
              <div
                *ngIf="submitted && f.enteAppartenenza.errors"
                class="invalidFieldMessage"
                style="display: block"
              >
                <div
                  *ngIf="f.enteAppartenenza.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  campo &egrave; obbligatorio
                </div>
              </div>
              <div class="user-type" [hidden]="!isEsterno">
                <p>
                  Gentile nuovo utente, ti invitiamo a compilare e sottoscrivere
                  l’autocertificazione
                  <a
                    href="/inframob/assets/docs/Autocertificazione.doc"
                    target="_blank"
                    >scaricabile qui</a
                  >. Appena conclusa questa procedura di registrazione, ti
                  chiediamo di inviare la certificazione ed i documenti
                  richiesti all’indirizzo
                  <a href="mailto:inframob@regione.lazio.it"
                    >test@test.it</a
                  >. L’utenza verrà attivata a verifiche concluse.
                </p>
              </div>
            </div>
          </div>

          <div
            *ngIf="canSeeAreeAppartenenza && !isEsterno()"
            id="myContainer"
            class="form-group px-3 form-istituzione"
          >
            <label class="">Area di appartenenza:</label>

            <div class="form-inline">
              <div
                [ngClass]="{ 'is-invalid': selectedAreaInvalid }"
                class="col-md-9 col-xl-10 px-0"
              >
                <ng-select
                  [formGroup]="myGroup"
                  formControlName="selectedArea"
                  [items]="areeTematiche"
                  bindValue="id"
                  placeholder="Seleziona"
                  bindLabel="descrizione"
                  selectOnTab="true"
                  [searchable]="true"
                  [clearable]="false"
                  notFoundText="Nessun risultato trovato..."
                >
                </ng-select>
              </div>
              <div class="col-md-3 col-xl-2 pr-0 pl-0 pl-md-2">
                <button
                  class="btn btn-sm btn-secondary btn-block bold"
                  (click)="setArea()"
                  [disabled]="saving"
                >
                  <i class="fas fa-arrow-right"></i>&nbsp;&nbsp;INSERISCI
                </button>
              </div>
            </div>
            <div *ngIf="areaFieldInvalid" class="invalidFieldMessage">
              <div class="invalid-feedback" style="display: block">
                Selezionare almeno un'area
              </div>
            </div>

            <div id="instList">
              <div
                *ngFor="let area of userInfo.areeTematiche; index as i"
                class="form-inline mpi_inseriti"
              >
                <input
                  #school{{i}}
                  type="text"
                  class="form-control col-md-9 col-xl-10"
                  placeholder="{{ area.descrizione }}"
                  readonly
                />
                <div class="col-md-3 col-xl-2 pr-0 pl-0 pl-md-2">
                  <button
                    [disabled]="saving"
                    class="btn btn-sm btn-primary btn-block"
                    (click)="deleteArea(area)"
                  >
                    <i class="far fa-trash-alt text-white"></i
                    >&nbsp;&nbsp;ELIMINA
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!--<div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p>
                Codice fiscale:
                <span>{{ userInfo.fiscalCode | uppercase }}</span>
              </p>
            </div>
          </div>-->

          <div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p class="bold">
                Username:
                <span>{{ userInfo.username }}</span>
              </p>
            </div>
          </div>

          <!--<div class="row mx-0">
            <div class="col-md-6 riepilogodati">
              <p>
                Cognome:
                <span>{{ userInfo.lastName | titlecase }}</span>
              </p>
            </div>

            <div class="col-md-6 riepilogodati">
              <p>
                Nome:
                <span>{{ userInfo.firstName | titlecase }}</span>
              </p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-md-6 riepilogodati">
              <p>
                Data di nascita:
                <span>{{ userInfo.birthDay }}</span>
              </p>
            </div>

            <div class="col-md-6 riepilogodati">
              <p>
                Nazione di nascita:
                <span>{{ userInfo.nationName }}</span>
              </p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-md-6 riepilogodati">
              <p>
                Provincia di nascita:
                <span>{{ userInfo.provinceName }}</span>
              </p>
            </div>

            <div class="col-md-6 riepilogodati">
              <p>
                Comune di nascita:
                <span>{{ userInfo.municipalityName }}</span>
              </p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p>
                Sesso:
                <span>{{ userInfo.male?'MASCHIO':'FEMMINA' }}</span>
              </p>
            </div>
          </div>-->

          <div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p>
                <span class="mandatory">Cognome:</span>
                <input
                  formControlName="lastname"
                  class="form-control"
                  type="text"
                  [ngClass]="{
                    'is-invalid': submitted && myGroup.controls.lastname.errors
                  }"
                />
              </p>
            </div>
            <div
              *ngIf="submitted && myGroup.controls.lastname.errors"
              class="invalid-feedback"
              style="display: block"
            >
              <div *ngIf="myGroup.controls.lastname.errors.required">
                Cognome &egrave; obbligatorio
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p>
                <span class="mandatory">Nome:</span>
                <input
                  formControlName="firstname"
                  class="form-control"
                  type="text"
                  [ngClass]="{
                    'is-invalid': submitted && myGroup.controls.firstname.errors
                  }"
                />
              </p>
            </div>
            <div
              *ngIf="submitted && myGroup.controls.firstname.errors"
              class="invalid-feedback"
              style="display: block"
            >
              <div *ngIf="myGroup.controls.firstname.errors.required">
                Nome &egrave; obbligatorio
              </div>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-md-12 riepilogodati">
              <p>
                <span class="mandatory">Email:</span>
                <input
                  formControlName="email"
                  class="form-control"
                  type="email"
                  [ngClass]="{
                    'is-invalid': submitted && myGroup.controls.email.errors
                  }"
                />
              </p>
            </div>
            <div
              *ngIf="submitted && myGroup.controls.email.errors"
              class="invalid-feedback"
              style="display: block"
            >
              <div *ngIf="myGroup.controls.email.errors.required">
                Email &egrave; obbligatoria
              </div>
              <div *ngIf="myGroup.controls.email.errors.email">
                Email non valida
              </div>
            </div>
          </div>

          <div *ngIf="isAdmin()" class="user-type col-12">
            <p for="menu">Menù:</p>
            <div class="form-group">
              <ng-select
                [formGroup]="myGroup"
                formControlName="menu"
                [items]="menus"
                bindValue="id"
                bindLabel="code"
                placeholder="Seleziona menù"
              >
              </ng-select>
            </div>
          </div>

          <!--<hr *ngIf="!isAdmin() || userInfo.isDocsOk" />

          <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row mx-0 pb-3">
            <div class="col-md-12 text-center bold">
              DOCUMENTI ALLEGATI IN FASE DI REGISTRAZIONE
            </div>
          </div>

          <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row mx-0 pb-3">
            <label class="col-md riepilogodati">CARTA IDENTITA' o PASSAPORTO&nbsp;&nbsp;</label>
                        <button (click)="downloadDocument('id')" class="btn btn-sm btn-success btn-block col-md">
              documento allegato
            </button>
                    </div>

                    <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row mx-0 pb-3">
                        <label class="col-md riepilogodati">CODICE FISCALE&nbsp;&nbsp;</label>
                        <button (click)="downloadDocument('fc')" class="btn btn-sm btn-success btn-block col-md" name="fc">
              documento allegato
            </button>
                    </div>

                    <hr />

                    <div *ngIf="!isAdmin() || userInfo.isDocsOk" class="row">
                        <button (click)="deleteMyAccount()" id="contenuto_btn_elimina" type="button" class="btn btn-primary mx-auto" style="margin-top: 10px" [disabled]="saving">
              <i class="fa fa-trash" aria-hidden="true"></i>
              &nbsp;ELIMINA SOTTOSCRIZIONE
            </button>
                    </div>-->

          <div class="col-lg-12">
            <hr />
            <img
              *ngIf="saving"
              class="pl-3"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
            <button
              [disabled]="saving"
              style="font-size: 20px"
              class="btn btn-primary btn-invia btn-block mt-4"
              (click)="editAccount()"
            >
              Salva
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
