

<app-login-iam [hidden]="!newLogin"></app-login-iam>
<!-- login-->
<section class="fascia-grey-login mt-5 " [hidden]="newLogin">
    <div class="container ">
        <div class="row clearfix">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                <div class="account-wall">
                    <form class="form-signin border shadow p-3 mt-5 mb-5 bg-white rounded" [formGroup]="loginForm" (ngSubmit)="signIn()">
                        <h3 class="text-center login-title mb-4"><b>Login</b></h3>
                        <div *ngIf="invalidLogin" class="alert alert-danger">
                            Username e/o password non validi.
                        </div>
                        <div class="form-group">
                            <label for="username" class="bold">Username</label>

                            <input type="text" formControlName="username" tabindex="1" class="form-control" placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Username &egrave; obbligatorio</div>
                            </div>
                            <!--<small><a routerLink="/forgotUsername" class="float-right" tabindex="5" style="font-size: 14px;">Username
                  dimenticato?</a></small>-->
                        </div>
                        <div class="form-group" style="margin-top:20px">
                            <label for="inputPassword" class="bold">Password</label>
                            <show-hide-password size="md" [btnOutline]="false">
                                <input type="password" formControlName="password" tabindex="2" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            </show-hide-password>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div>Password &egrave; obbligatoria</div>
                            </div>
                            <!--<small><a routerLink="/forgotPassword" class="float-right" tabindex="6" style="font-size: 14px;">Password
                  dimenticata?</a></small>-->
                        </div>
                        <div style="width:100%" style="margin-top:50px">
                            <button class="btn btn-lg btn-primary btn-block" type="submit" tabindex="3" [disabled]="loading">
                                ACCEDI
                                <img *ngIf="loading" class="pl-3 float-right"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </button>

                        </div>
                        <!--<div style="width:100%" style="margin-top:50px">
                            <button (click)="iamLogin()" class="btn btn-lg btn-primary btn-block" type="button" tabindex="3" [disabled]="loading">
                                ACCEDI IAM
                                <img *ngIf="loading" class="pl-3 float-right"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </button>

                        </div>-->
                        <!--<div class="mt-3"><a class="pointer text-uppercase text-decoration-none"  (click)="visualizzaIamLogin()">&nbsp;Passa a nuova login</a></div>-->
                        <div class="mt-3"><a class="pointer text-uppercase text-decoration-none" href="manualistica" target="_blank"><i class="fas fa-book"></i>&nbsp;Manualistica</a></div>
                        <!--<small>
              <a routerLink="/register" class="float-right need-help" tabindex="4">
                Registrati</a>
              <span class="float-right need-help" style="font-size: 14px;">Sei un nuovo utente? &nbsp;</span>
            </small>-->
                        <span class="clearfix"></span>
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- fine login-->
