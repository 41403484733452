import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from 'rxjs';
import { ToastService } from "src/app/services/toast.service";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxDatatableWrapperComponent } from '../../../../components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.component'
declare var $: any;
//import { AreeTematicheService } from '../../../services';

@Component({
    selector: "generic-selection-modal-component",
    templateUrl: "./generic-selection-modal.component.html",
    styleUrls: ["./generic-selection-modal.component.css"]
})


export class GenericSelectionModalComponent implements OnInit, OnDestroy, AfterViewInit {
    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();

    selectButtonTemplates = " "

    @Input() modalDefinition: any;//{columnsDefinition:{},ajaxMethod:function()}
    @Output() onSubmitOk = new EventEmitter();

    @Input() templateList = [];

    @ViewChild('btnSelectTemplate', { static: true })
    public btnSelectTemplate: TemplateRef<any>;

    @ViewChild('btnCustomSelectTemplate', { static: true })
    public btnCustomSelectTemplate: TemplateRef<any>;

    @ViewChild('ngxDatatableWrapper')
    ngxDatatableWrapper: NgxDatatableWrapperComponent;

    //templateList = [];
    @Input()
    dtOptionsNgx: any;

    @Input()
    useCustomSelectButtonTemplate: boolean = false;

    constructor(
        private modalService: BsModalService
        , private toastr: ToastService
        , public bsModalRef: BsModalRef
        //  ,private areeTematicheService:AreeTematicheService
    ) {

    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        //this.modalRef.content.onClose.unsubscribe();
    }

    ngOnInit(): void {
        //this.getTable();
        if (!this.templateList) this.templateList = [];

        this.templateList = [
            { name: 'btnSelectTemplate', template: this.btnSelectTemplate },
            { name: 'btnCustomSelectTemplate', template: this.btnCustomSelectTemplate },
            ...this.templateList
        ];
        this.dtOptionsNgx.columns = [{
            name: this.useCustomSelectButtonTemplate ? 'associa': 'aggiungi'
            , prop: null
            , sortable: false
            , filtrable: false
            , cellTemplate: this.useCustomSelectButtonTemplate ? 'btnCustomSelectTemplate': 'btnSelectTemplate'// 'buttonsTemplate'
            //, width: 10
            , resizeable: false
            , draggable: false
            , flexGrow: 1
            , minWidth: 59
        },
        ...this.dtOptionsNgx.columns];
    }

    rerender(): void {
        /*this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });*/
    }


    getTable(): void {
        const that = this;
        this.dtOptions = {
            dom:
                '<"row"<"col-lg-12 col-md-12 col-sm-12 col-xs-12"B>> <"row"<"col-lg-6 col-md-6 col-sm-6 col-xs-12"l><"col-lg-6 col-md-6 col-sm-6 col-xs-12"f>>  rti <"float-right btn-sm border mt-3 p-0"p>', //"Blfrtip",
            buttons: [
                {
                    extend: "csv",
                    className: "dt-button-copy",
                    exportOptions: { columns: ":visible :not(.datatable-dt-action)" }
                },
                {
                    extend: "pdf",
                    className: "dt-button-pdf",
                    exportOptions: { columns: ":visible :not(.datatable-dt-action)" },
                    orientation: "landscape"
                },
                {
                    extend: "excel",
                    className: "dt-button-excel",
                    exportOptions: { columns: ":visible :not(.datatable-dt-action)" }
                }
            ],
            language: {
                sEmptyTable: "Nessun dato presente nella tabella",
                sInfo: "Vista da _START_ a _END_ di _TOTAL_ elementi",
                sInfoEmpty: "Vista da 0 a 0 di 0 elementi",
                sInfoFiltered: "(filtrati da _MAX_ elementi totali)",
                sInfoPostFix: "",
                sInfoThousands: ".",
                sLengthMenu: "Visualizza _MENU_ elementi",
                sLoadingRecords: "Caricamento...",
                sProcessing: "Elaborazione...",
                sSearch: "Cerca:",
                sZeroRecords: "La ricerca non ha portato alcun risultato.",
                oPaginate: {
                    sFirst: "Inizio",
                    sPrevious: "Precedente",
                    sNext: "Successivo",
                    sLast: "Fine"
                },
                oAria: {
                    sSortAscending:
                        ": attiva per ordinare la colonna in ordine crescente",
                    sSortDescending:
                        ": attiva per ordinare la colonna in ordine decrescente"
                }
            },
            pagingType: "full_numbers",
            responsive: true,
            orderCellsTop: true,
            pageLength: 10,
            serverSide: true,
            process: true,
            order: [],
            rowCallback: (row: Node, data: any[] | Object, index: number) => {
                const self = this;



                $(".plusBtn", row).unbind("click").bind("click", () => { self.editHandler(data); });
                // $(".deleteBtn", row).unbind("click").bind("click", () => {self.deleteHandler(schoolYear);});

                return row;
            },
            columns: [
                {
                    title: "Aggiungi",
                    data: "null",
                    searchable: false,
                    orderable: false,
                    className: "datatable-dt-action",
                    width: '40px',
                    render: function (data, type, row, meta) {
                        return '<button class="btn btn_icone btn-primary btn-sm btn-round plusBtn" data-toggle="tooltip" data-placement="top" title="Aggiungi"><i class="fas fa-plus"></i> </button> '
                    }
                },
                ...this.modalDefinition.columns

            ],
            ajax: this.modalDefinition.ajax
        };
    }


    editHandler(data) {
        this.onSubmitOk.emit(data);
    }
}