<div [hidden]="
    router.url.includes('dashboard-finanziaria') ||
    router.url.includes('dashboard-fasi') ||
    router.url.includes('dashboard-stato-finanziamento') ||
    router.url.includes('dashboard-stato-intervento') ||
    router.url.includes('dashboard-interventi') ||
    router.url.includes('dashboard-pnrr') ||
    router.url.includes('dashboard-non-disegnabile') ||
    router.url.includes('dashboard-pkmg') ||
    router.url.includes('dashboard-entrata-uscita') ||
    router.url.includes('dashboard-analisi-reporting-risorse-progetti-finanziati-pnrr') ||
    router.url.includes('dashboard-sisma')||
    router.url.includes('cruscotto-note-atti')
  " class="bg-0083e0">
    <div class="container container-fluid-md">
        <megaMenu [items]="menuItems"></megaMenu>
    </div>
</div>
<div *ngIf="isEnabled() && false" class="wd-100 p-1 new-menu menu-z">
    <div class="container ">
        <!-- Static navbar -->

        <nav class="navbar navbar-expand-md navbar-light bg-rosso">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

            <div class="d-md-none" style="position: absolute; margin-left: 50px; top: 5px">
                <a routerLink="/" class="text-white">
                    <b>{{title}}</b><br />

                </a>
            </div>

            <div class="collapse navbar-collapse nav-fostrap padding-sx-10" id="navbarNavDropdown">
                <ul class="navbar-nav no-padding menu-z">
                    <li class="nav-item hamburger-menu" *ngFor="let item of menuItems" [class.dropdown]="item.subMenu && item.subMenu.length > 0" [hidden]="!canAccess(item)">
                        <a *ngIf="!(item.subMenu && item.subMenu.length > 0)" (click)="gotoPage(item)" class="nav-link" [ngClass]="item.cssClass">{{ item.title }}</a
            >
            <a
              *ngIf="item.subMenu && item.subMenu.length > 0"
              class="nav-link"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              [ngClass]="item.cssClass"
              >{{ item.title }}</a
            >
            <ul
              *ngIf="item.subMenu && item.subMenu.length > 0"
              class="dropdown-menu pull-left"
              aria-labelledby="navbarDropdown"
            >
              <li
                *ngFor="let subItem of item['subMenu']"
                [hidden]="!canAccess(subItem)"
              >
                <a
                  *ngIf="!(subItem.subMenu && subItem.subMenu.length > 0)"
                  class="dropdown-item nav-link"
                  (click)="gotoPage(subItem)"
                >
                  {{ subItem.title }}</a
                >
                <!--third level-->
                <a
                  *ngIf="subItem.subMenu && subItem.subMenu.length > 0"
                  class="dropdown-item dropdown-toggle"
                  href="#"
                  >{{ subItem.title }}</a
                >
                <ul class="dropdown-menu">
                  <li
                    *ngFor="let subThirdItem of subItem['subMenu']"
                    [hidden]="!canAccess(subThirdItem)"
                  >
                    <a class="dropdown-item" (click)="gotoPage(subThirdItem)"
                      >{{ subThirdItem.title }}
                    </a>
                    </li>
                </ul>
                </li>
                </ul>
                </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
