import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SoggettoGiuridicoService, SoggettoRuoloService } from '../../../services';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SoggettoGiuridico } from '../../../models';
import {  BsModalRef } from "ngx-bootstrap/modal";

@Component({
    templateUrl: "./soggetto-giuridico-selection-modal.component.html",
    styleUrls: ["./soggetto-giuridico-selection-modal.component.css"]
})
export class SoggettoGiuridicoSelectionModalComponent implements OnInit {

    roles=[];
    soggettiGiuridici=[];
    myGroup:FormGroup;
    submitted:boolean=false;
    @Output() onSubmitOk = new EventEmitter();    

    constructor(
        private soggettoGiuridicoService:SoggettoGiuridicoService
        ,private soggettoRuoloService:SoggettoRuoloService
        ,private formBuilder:FormBuilder
        ,public bsModalRef: BsModalRef
        ){}

    ngOnInit(): void {
        this.myGroup = this.initializeFormGroup();
        this.getSoggettiGiuridici();
        this.getRoles();
    }

    initializeFormGroup() {
        return this.formBuilder.group({
            role: [null, [Validators.required]],
            soggettoGiuridico: [null, [Validators.required]]             
        });
    }

    getSoggettiGiuridici() {
        this.soggettoGiuridicoService.getAll().subscribe(
            res => {
                let ress: any[]; ress = res; ress.map((i) => {
                    i.descrizione = `${i.siglaProvincia} - ${i.codiceIdentificativo} - ${i.denominazione}`;
                    return i;
                }); 
                this.soggettiGiuridici = ress;
            }
        );
    }

    getRoles(){
        this.soggettoRuoloService.getAll().subscribe(
            res => {                
                this.roles = res;
            }
        );
    }

    get f() {
        return this.myGroup.controls;
    }

    add(){

        console.log("onSubmit");
        this.submitted = true;

        var errors = false;
        for (let c in this.f) {
            errors = errors || this.f[c].errors !== null;
            console.log(
                "this.f[" + c + "].errors:" + JSON.stringify(this.f[c].errors)
            );
        }

        console.log("errors:" + errors);
        if (this.myGroup.invalid || errors 
            ) {           
            console.log("invalid");
            return false;
        }

        let idSoggetto = this.f.soggettoGiuridico.value;
        let idRuolo = this.f.role.value;
        let data = Object.assign({},this.soggettiGiuridici.find(x=>x.id == idSoggetto));
        data.ruoloId = idRuolo;
        data.ruolo = this.roles.find(x=>x.id == idRuolo).ruolo;
        this.onSubmitOk.emit(data);
    }
}