import { NgxInputSwitchModule } from 'ngx-input-switch';
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule ,ReactiveFormsModule} from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { IamComponent } from "./components/iam/iam.component";
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtModule } from "@auth0/angular-jwt";
import { AuthService } from "./services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { HomeComponent } from "./components/home/home.component";
import { AuthGuard } from "./guards/auth-guard.service";
import { FooterComponent } from "./components/_components/footer/footer.component";
import { HeaderComponent } from "./components/_components/header/header.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { Ng2CompleterModule } from "ng2-completer";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { ModalModule } from "ngx-bootstrap/modal";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { NewsService } from "./services/news.service";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ComunicationsComponent } from "./components/home/comunications/comunications.component";
import { NewsComponent } from "./components/home/comunications/news/news.component";
import { NewsManagementComponent } from "./components/newsManagement/news-management.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DatePipe } from "@angular/common";
import { LoadingComponent } from "./components/_components/loading/loading.component";
import { AlertComponent } from "./components/_components/alert/alert.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ShowHidePasswordModule } from "ngx-show-hide-password";
import { ScrollToTopButtonComponent } from "./components/_components/scrollToTop/scroll-to-top-button.component";
import { HeaderMenuComponent } from "./components/_components/headerMenu/header-menu.component";
import { BreadcrumbComponent } from "./components/_components/breadcrumb/breadcrumb.component";
import { RoleService } from "./services/role.service";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { TitlePageComponent } from "./components/_components/titlePage/title-page.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { DocumentsComponent } from "./components/documents/documents.component";
import { AccessLogListComponent } from "./components/accessLogList/accesslog-list.component";
import { AccountComponent } from "./components/account/account.component";
import {
  RegisterComponent,
  RegisterMainStepComponent,
  RegisterStep4Component,
} from "./components/register";
import {
  GeoManagementComponent,
  ProvinceManagementComponent,
  RegionManagementComponent,
  MunicipalityManagementComponent,
  NationManagementComponent,
  EditMunicipalityModalComponent,
  EditProvinceModalComponent,
  EditNationModalComponent,
  EditRegionModalComponent,
} from "./components/geoManagement/";
import { AccountListComponent } from "./components/accountList/account-list.component";
import { SendEmailUserModalComponent } from "./components/accountList/accountListSendEmailModal/account-list-send-email-modal";

import { ToastrModule } from "ngx-toastr";
import { NgxMaskModule } from "ngx-mask";
import { IMaskModule } from "angular-imask";

import { NgxDatatableWrapperModule } from "./components/_components/ngxDatatableWrapper/ngx-datatable-wrapper.module";
import { AppLoadingModule } from "./components/_components/loading/loading.module";

import { InfraMobModule } from "./modules/inframob/inframob.module";
import { Router } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { ItalianCurrencyPipeModule } from "./modules/italian-currency-pipe/italian-currency-pipe.module";
import { CompleterModule } from "./components/_components/completer/completer.module";

import { MegaMenuModule } from "./components/_components/megaMenu/megamenu.module";

import { WebGisModule } from "./modules/web-gis/web-gis.module";
import { DashboardComponent } from "./modules/web-gis/components/dashboard/dashboard.component";
import { IsDirtyHelper } from "./helpers/is-dirty.helper";

import { PopoverComponent } from './components/_components/popover/popover.component';
import { PopoverPageComponent } from "./components/_components/popoverPage/popover-page.component";
import { UpdatePermissionsComponent } from './components/updatePermissions/update-permissions.component';
import { AddPermissionsComponent } from './components/updatePermissions/addPermissions/add-permissions.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { CardMenuComponent } from './components/homepage/card-menu/card-menu.component';
import { RowMenuComponent } from './components/homepage/row-menu/row-menu.component';
import { BarMenuComponent } from './components/homepage/bar-menu/bar-menu.component';

import { AuthenticationIAMService } from './services/authentication-iam.service';
import { LoginIamComponent } from "./components/_components/login-iam/login-iam.component";
import { AppConfigService } from './services/app-config.service';


export function initializeApp(appConfig: AppConfigService) {
    return () => appConfig.load();
  }

export function tokenGetter() {
  return "";
}

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    LoginComponent,
    LoginIamComponent,
    IamComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ComunicationsComponent,
    NewsComponent,
    NewsManagementComponent,
    AlertComponent,
    ScrollToTopButtonComponent,
    PopoverPageComponent,
    HeaderMenuComponent,
    BreadcrumbComponent,
    PrivacyComponent,
    TitlePageComponent,
    DocumentsComponent,
    AccessLogListComponent,
    AccountComponent,
    RegisterComponent,
    RegisterMainStepComponent,
    RegisterStep4Component,
    GeoManagementComponent,
    ProvinceManagementComponent,
    RegionManagementComponent,
    MunicipalityManagementComponent,
    NationManagementComponent,
    EditMunicipalityModalComponent,
    EditProvinceModalComponent,
    EditNationModalComponent,
    EditRegionModalComponent,
    AccountListComponent,
    SendEmailUserModalComponent,
    PopoverComponent,
    UpdatePermissionsComponent,
    AddPermissionsComponent,
    HomepageComponent,
    CardMenuComponent,
    RowMenuComponent,
    BarMenuComponent
  ],
  imports: [

    BrowserAnimationsModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FormsModule,
    ShowHidePasswordModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["example.com"],
        blacklistedRoutes: ["localhost:8080/"],
      },
    }),
    HttpClientModule,
    NgSelectModule,
    Ng2CompleterModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      progressBar: false,
      maxOpened: 6,
      newestOnTop: true,
      /*autoDismiss:false,*/
      timeOut: 2000,
      /*disableTimeOut:true*/
    }),
    NgxMaskModule.forRoot(),
    InfraMobModule,
    NgxDatatableModule,
    NgxDatatableWrapperModule,
    ItalianCurrencyPipeModule,
    IMaskModule,
    CompleterModule,
    MegaMenuModule,
    AppLoadingModule,
    WebGisModule,
    NgxInputSwitchModule,
  ],

  providers: [

    AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfigService],
            multi: true
        },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AuthenticationIAMService,
    AuthService,
    CookieService,
    AuthGuard,
    NewsService,
    RoleService,
    DatePipe,
    IsDirtyHelper,

  ],
  entryComponents: [
    LoadingComponent,
    EditMunicipalityModalComponent,
    EditProvinceModalComponent,
    EditNationModalComponent,
    EditRegionModalComponent,
    SendEmailUserModalComponent,
    DashboardComponent,
    PopoverComponent,
    AddPermissionsComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    /*const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

        console.log('Routes: ', JSON.stringify(router.config, replacer, 2));*/
  }
}
