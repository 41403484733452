<div class="modal-header text-center">
    <h4 class="modal-title pull-left">{{modalDefinition.pageTitle}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">



    <!--<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table-bordered row-border hover small"
    style="width:100%"></table>-->
    <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style="width: 100%; height: auto;" [dtOptions]="dtOptionsNgx">
        <ng-template #btnSelectTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class="bottone-inserisci  border-0 text-center" (click)="editHandler(row)" data-toggle="tooltip" data-placement="top" title="Aggiungi"><i class="fas fa-plus pointer"></i></a>
        </ng-template>
        <ng-template #btnCustomSelectTemplate let-row="row" let-value="value">
            <a [ngClass]="{
            'disabled': loading
            }" class="bottone-inserisci  border-0 text-center" (click)="editHandler(row)" data-toggle="tooltip" data-placement="top" title="Associa"><i class="fas fa-plus pointer"></i></a>
        </ng-template>
    </ngx-datatable-wrapper>
</div>