<div class="container-fluid px-0 bg-celeste" [formGroup]="myForm">
    <div class="text-center p-5 position-absolute top-50 start-50 translate-middle bg-white border border-dark rounded shadow"
    style="z-index: 999999999" *ngIf="showLoading">
      <app-loading message="Caricamento in corso..."></app-loading>
    </div>
    <div>

      <app-header-dashboard></app-header-dashboard>
      <!-- menu -->

      <div class="row pb-3 pt-1 bg-0083e0" style="z-index: 9000;">
        <!--filtro progettualita' fabbisogno-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label>
            <h6 class="mb-0 text-white bold">Progettualità{{isAdmin() || isGr34()?'/Fabbisogno':''}}</h6>
          </label>
          <ng-select title="{{ description }}" [items]="allProjects" bindValue="codice" bindLabel="label"
            placeholder="Seleziona progettualità{{isAdmin() || isGr34()?'/Fabbisogno':''}}" formControlName="project"
            (change)="setProject($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.descrizione }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
        <!--filtro intervento-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label for=" importoFinanziamento ">
            <h6 class="mb-0 text-white bold">
              Intervento <small>(min 3 caratteri)</small>
            </h6>
          </label>

          <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setIntervention($event)"
            (keyup)="onChangeIntervention($event)">
          </app-completer>
        </div>

        <!--filtro per finanziamento-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="!isAdmin() && !isGr34()">
          <label for="">
            <h6 class="mb-0 text-white bold">
              Finanziamento <small>(min 3 caratteri)</small>
            </h6>
          </label>

          <app-completer [cpOptions]="completerParamsFinanziamento" (onSelectEmitter)="setFinanziamento($event)"
            (keyup)="onChangeFiltroFinanziamento($event)">
          </app-completer>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="!isAdmin() && !isGr34()">
          <label for="">
            <h6 class="mb-0 text-white bold">
              Fonte finanziamento
            </h6>
          </label>
          <ng-select [items]="fontiFianziamentoList" bindValue="id" bindLabel="tipoFinanziamento"
            placeholder="Seleziona fonte finanziamento" formControlName="fonteFinanziamento"
            (change)="setFonteFinanziamento($event)" [multiple]="true" [selectableGroup]="true"
            [selectableGroupAsModel]="false" groupBy="gruppo">


            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
                title="{{ item.tipoFinanziamento }}">&nbsp;{{ item.tipoFinanziamento }}</span>
            </ng-template>
          </ng-select>

        </div>

        <!--filtro periodo-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="!isAdmin() && !isGr34()">
          <label>
            <h6 class="mb-0 text-white bold">
              Periodo <small>(da **** a ****)</small>
            </h6>
          </label>
          <div class="d-flex">
            <ng-select class="col-lg-6 pl-0" [items]="anniFromCombo" bindValue="anno" bindLabel="anno" placeholder="Da"
              formControlName="from" (change)="setFrom($event)">
            </ng-select>

            <ng-select class="col-lg-6 pr-0" [items]="anniToCombo" bindValue="anno" bindLabel="anno" placeholder="A"
              formControlName="to" (change)="setTo($event)">
            </ng-select>
          </div>
        </div>
        <!--filtro stato progettualita/intervento-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label for="importoFinanziamento ">
            <h6 class="mb-0 text-white bold">Stato Progettualità/Intervento</h6>
          </label>
          <ng-select [items]="statuses" bindValue="code" bindLabel="label" formControlName="status"
            placeholder="Seleziona stato progettualità/intervento " (change)="setInterventionStatus($event)"
            [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo">
            <!--<ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>-->

            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.gruppo | uppercase}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
                title="{{ item.label }}">&nbsp;{{ item.label }}</span>
            </ng-template>


          </ng-select>
        </div>
        <!--filtro temi prioritari-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="!isAdmin() && !isGr34()">
          <label>
            <h6 class="mb-0 text-white bold">Temi Prioritari</h6>
          </label>
          <ng-select [items]="temiCombo" bindValue="id" bindLabel="descrizione" formControlName="priority"
            placeholder="Seleziona tema prioritario" (change)="setTema($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
            </ng-template>
          </ng-select>
        </div>


        <!-- filtro tipologia classificazioni-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="!isAdmin() && !isGr34()">
          <label>
            <h6 class="mb-0 text-white bold">Tipologia Classificazioni</h6>
          </label>
          <ng-select [items]="classificazioniCombo" bindValue="id" bindLabel="tipo" formControlName="classification"
            placeholder="Seleziona tipologia classificazioni" (change)="setClassificazione($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.tipo }}">{{ item.tipo }}</div>
            </ng-template>
          </ng-select>
        </div>
        <!-- filtro per tipologia intervento -->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label>
            <h6 class="mb-0 text-white bold">Tipologia Intervento</h6>
          </label>
          <ng-select [items]="tipologieInterventoForSelectItems" bindValue="key" bindLabel="label"
            formControlName="status" placeholder="Seleziona tipologia intervento"
            (change)="setTipologiaIntervento($event)" [multiple]="true" [selectableGroup]="true"
            [selectableGroupAsModel]="false" groupBy="gruppo">
            <!--<ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>-->

            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.gruppo | uppercase}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
                title="{{ item.label }}">&nbsp;{{ item.label }}</span>
            </ng-template>


          </ng-select>
        </div>

        <!--filtro aree gestionali direzione e/o esterne-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label for="importoFinanziamento ">
            <h6 class="mb-0 text-white bold">
              Aree gestionali direzione e/o esterne
            </h6>
          </label>
          <ng-select [items]="areeTematicheCombo" bindValue="id" bindLabel="descrizione" formControlName="direction"
            placeholder="Seleziona area gestionale direzione e/o esterna " (change)="setAreaTematica($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
            </ng-template>
          </ng-select>
        </div>
        <!--filtro per soggetti giuridici-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label>
            <h6 class="mb-0 text-white bold">{{isGr34() || isAdmin()?'Soggetti Giuridici':'Soggetti Attuatori'}}</h6>
          </label>
          <ng-select [items]="soggettiCombo" formControlName="subject" bindValue="id" bindLabel="denominazione"
            placeholder="Seleziona soggetto" (change)="setSoggetto($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.denominazione }}">{{ item.denominazione }}</div>
            </ng-template>
          </ng-select>
        </div>

        <!--filtro per provincia soggetti giuridici-->
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12  pt-1" [hidden]="!isGr34() && !isAdmin()">
          <label title="Provincia Soggetti Giuridici">
            <h6 class="mb-0 text-white bold">Provincia&nbsp;Soggetti Giuridici</h6>
          </label>
          <ng-select [items]="provinceSoggettiCombo" formControlName="subjectProvince" bindValue="key" bindLabel="value"
            placeholder="Seleziona provincia" (change)="setProvinciaSoggettiGiuridici($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>

        <!--filtro per pnrr-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label title="PNRR / PNC">
            <h6 class="mb-0 text-white bold">PNRR / PNC</h6>
          </label>
          <ng-select [items]="pnrrComboItems" formControlName="pnrrPnc" bindValue="key" bindLabel="value"
            placeholder="Seleziona" (change)="setPnrrFilter($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>

        <!--filtro per linee di finanziamento pnrr-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label>
            <h6 class="mb-0 text-white bold">Linee di Finanziamento PNRR / PNC - Missioni</h6>
          </label>
          <ng-select [items]="finanziamentoMissionsPnrrComboItems" formControlName="finanziamentoPnrrPnc"
            [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo"
            [closeOnSelect]="false" bindValue="codice" bindLabel="valore"
            placeholder="Seleziona una o più linee di finanziamento" (change)="setLineeFinanziamento($event, 'mission')">
            <!-- <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.codice }}">{{ item.codice }}</div>
            </ng-template> -->

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
                title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
            </ng-template>

          </ng-select>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label>
            <h6 class="mb-0 text-white bold">Linee di Finanziamento PNRR / PNC - Componenti</h6>
          </label>
          <ng-select [items]="finanziamentoComponentsPnrrComboItems" formControlName="finanziamentoPnrrPnc"
            [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo"
            [closeOnSelect]="false" bindValue="codice" bindLabel="valore"
            placeholder="Seleziona una o più linee di finanziamento"
            (change)="setLineeFinanziamento($event, 'component')">
            <!-- <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.codice }}">{{ item.codice }}</div>
            </ng-template> -->

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
                title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
            </ng-template>

          </ng-select>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label>
            <h6 class="mb-0 text-white bold">Linee di Finanziamento PNRR / PNC - Linee</h6>
          </label>
          <ng-select [items]="finanziamentoLinesPnrrComboItems" formControlName="finanziamentoPnrrPnc" [multiple]="true"
            [selectableGroup]="true" [selectableGroupAsModel]="false" groupBy="gruppo" [closeOnSelect]="false"
            bindValue="codice" bindLabel="valore" placeholder="Seleziona una o più linee di finanziamento"
            (change)="setLineeFinanziamento($event, 'line')">
            <!-- <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.codice }}">{{ item.codice }}</div>
            </ng-template> -->

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
                title="{{ item.valore }}">&nbsp;{{ item.valore }}</span>
            </ng-template>

          </ng-select>
        </div>

        <!--filtro per Provincia-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label title="Provincia">
            <h6 class="mb-0 text-white bold">Provincia</h6>
          </label>
          <ng-select [items]="provinciaItems" formControlName="province" bindValue="key" bindLabel="value"
            placeholder="Seleziona provincia" (change)="setProvincia($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>

        <!--filtro per Comune-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label title="Comune">
            <h6 class="mb-0 text-white bold">Comune</h6>
          </label>
          <ng-select [items]="comuneItems" formControlName="municipality" bindValue="key" bindLabel="value"
            placeholder="Seleziona comune" (change)="setComune($event)">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.value }}">{{ item.value }}</div>
            </ng-template>
          </ng-select>
        </div>

        <!--filtro per Dettaglio tipologia-->
        <div class="col-xl-3 col-lg-4 col-md-6 pt-1">
          <label title="Dettaglio tipologia">
            <h6 class="mb-0 text-white bold">Dettaglio tipologia</h6>
          </label>
          <ng-select [items]="aggregatoItems" formControlName="dettaglioTipologia" bindValue="id" bindLabel="name"
            placeholder="Seleziona dettaglio tipologia" (change)="setAggregato($event)"
            [multiple]="true"
            [selectableGroup]="true"
            [selectableGroupAsModel]="false"
            groupBy="gruppo">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.gruppo | uppercase}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> <span
                title="{{ item.name }}">&nbsp;{{ item.name }}</span>
            </ng-template>
          </ng-select>
        </div>

<!--filtro per RP solo sisma-->
<div class="col-xl-3 col-lg-4 col-md-6 pt-1" [hidden]="!isSisma">
    <label>
      <h6 class="mb-0 text-white bold">RP</h6>
    </label>
    <ng-select [items]="soggettiRPCombo" formControlName="subject" bindValue="id" bindLabel="label"
      placeholder="Seleziona RP" (change)="setRP($event)">
      <ng-template ng-option-tmp let-item="item">
        <div title="{{ item.label }}">{{ item.label }}</div>
      </ng-template>
    </ng-select>
  </div>


        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 pt-1 align-self-end align-items-end">

          <div class="d-flex align-self-end align-items-end">
            <!--bottone cerca-->
            <div class="col-lg-6 pl-0 align-self-end align-items-end">
              <button [disabled]="showLoading" type="button" class="mt-1 btn btn-info btn-block"
                (click)="filterInterventions(fillInterventions, true)">
                Cerca
              </button>
            </div>

            <!--bottone esporta-->


            <div class="col-lg-6 pr-0 align-self-end align-items-end">
              <button [disabled]="loadingExportExcel" type="button" class="mt-1 btn btn-info btn-block"
                (click)="esportaExcel()">
                Esporta
                <img *ngIf="loadingExportExcel" class="pl-3 float-right"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </button>
            </div>
          </div>


        </div>
      </div>
      <!--fine sezione filtri-->

      <ng-container *ngIf="!showPage">
        <div [hidden]="loading" *ngIf="!noresult" class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white">
          <div class="container text-center">
            <h4 class="bold">{{isSisma?"Dashboard Sisma":"Dashboard di Sintesi"}}</h4>
            <p class="h5">
              <small>
              {{ (isFiltered() ? 'Premere' : 'Selezionare almeno un filtro e premere') + ' sul pulsante Cerca per attivare la visualizzazione dei grafici di sintesi.' }}
              </small>
            </p>
          </div>
        </div>

        <ng-container *ngIf="noresult">
          <div [hidden]="loading" class="jumbotron container my-5 border shadow-sm my-3 rounded bg-white">
            <div class="container text-center">
              <p class="h5"><small>La ricerca non ha prodotto risultati</small></p>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="showPage">
        <div class="row ">

          <div class="col-xl-6 col-lg-7 col-md-12 col-sm-12 ">
            <div class="row">
              <!-- interventi -->
              <div class="col-lg-6 col-md-6">
                <div class="shadow-sm bg-white card my-2 rounded">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <span class="text-uppercase">N° Progettualità{{isAdmin() || isGr34()?'/Fabbisogni':''}}</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0">
                      {{ countMain() }}
                      <small>&nbsp;di cui con interventi</small>
                      {{ countSubIntervents() }}
                    </h6>
                  </div>
                </div>
                <div class="shadow-sm bg-white card my-2 rounded">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <span>VALORE TOTALE</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0 text-67b7dc">
                      {{ printValueAsCurrency(valoreTotaleProgetti) }}
                    </h6>
                  </div>
                </div>
                <div class="shadow-sm bg-white card my-2 rounded" *ngIf="isAdmin() || isGr34()">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <span>TOTALE FINANZIATO</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0 text-success">
                      {{ printValueAsCurrency(valoreTotaleFinanziatoProgetti) }}
                    </h6>
                  </div>
                </div>
                <div class="shadow-sm bg-white card my-2 rounded" *ngIf="isAdmin() || isGr34()">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <span class="letter05">DIFFERENZA TRA TOTALE VALORE E TOTALE FINANZIATO</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0 text-orange">
                      {{
                      printValueAsCurrency(
                      valoreTotaleProgetti - valoreTotaleFinanziatoProgetti
                      )
                      }}
                    </h6>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="shadow-sm bg-white card my-2 rounded">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <a type="button" class="help"
                        popover="Nota Bene: i progetti che non hanno interventi al loro interno sono considerati loro stessi degli interventi"
                        placement="bottom" triggers="mouseenter:mouseleave">
                        <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px">
                        </i>
                      </a>
                      &nbsp;<span>N° INTERVENTI</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0">{{ countNotMain() }}</h6>
                  </div>
                </div>
                <div class="shadow-sm bg-white card my-2 rounded">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <span>VALORE TOTALE</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0 text-67b7dc">
                      {{ printValueAsCurrency(totInt) }}
                    </h6>
                  </div>
                </div>
                <div class="shadow-sm bg-white card my-2 rounded" *ngIf="isAdmin() || isGr34()">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <span>TOTALE FINANZIATO</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0 text-success">
                      {{ printValueAsCurrency(totFin) }}
                    </h6>
                  </div>
                </div>
                <div class="shadow-sm bg-white card my-2 rounded" *ngIf="isAdmin() || isGr34()">
                  <div class="card-body">
                    <h6 class="text-center border-bottom text-secondary bold font14">
                      <span class="letter05">DIFFERENZA TRA TOTALE VALORE E TOTALE FINANZIATO</span>
                      <span *ngIf="isFiltered()"><small>&nbsp;(filtrati)</small></span>
                    </h6>

                    <h6 class="text-center bold mb-0 text-orange">
                      {{ printValueAsCurrency(totInt - totFin) }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <!-- grafici "copertura"-->
            <div class="row" *ngIf="isAdmin() || isGr34()">
              <div class="col-lg-6 col-md-12">
                <div id="chart2" class="bg-white chart2 border shadow-sm rounded my-3"></div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div id="chart5" class="bg-white chart5 border shadow-sm rounded my-3"></div>
              </div>
            </div>

            <!-- grafico stato interventi-->
            <div class="row" *ngIf="!isAdmin() && !isGr34()">
              <div class="col-lg-12 col-md-12">

                <div id="chart1" class="bg-white chart1 border shadow-sm my-3 rounded"></div>
              </div>

            </div>
          </div>

          <!-- mappa -->
          <div class="col-xl-6 col-lg-5 col-md-12 col-sm-12 mt-2 mb-3">
            <app-map></app-map>
          </div>
        </div>

        <!--grafici classificazione-->
        <div class="row" [hidden]="isHiddenChart67" style="z-index: 12000; overflow-y: visible">
          <div class="col-lg-6 col-md-12" style="z-index: 12000; overflow-y: visible">
            <div id="chart6" class="bg-white chart6 border shadow-sm my-3 rounded"
              style="z-index: 12000; overflow-y: visible"></div>
          </div>
          <div class="col-lg-6 col-md-12" style="z-index: 12000; overflow-y: visible">
            <div id="chart7" style="z-index: 12000; overflow-y: visible"
              class="bg-white chart7 border shadow-sm my-3 rounded"></div>
          </div>
        </div>

        <!-- grafico tipologia intervento -->
        <div class="row">
          <div class=" col-md-12"
            [ngClass]="{'col-lg-12':(!isAdmin() && ! isGr34()), 'col-lg-12':(isAdmin() ||  isGr34())}">
            <div id="chart3" class="bg-white chart3 border shadow-sm my-3 rounded"></div>
          </div>
        </div>

      <!-- grafico stato interventi-->
        <div class="row">
          <div class="col-md-12" *ngIf="isAdmin() || isGr34()">

            <div id="chart1" class="bg-white chart1 border shadow-sm my-3 rounded"></div>
          </div>

        </div>
  <!-- grafico copertura per anno-->
        <div class="row">
          <div class="col-lg-12 ">
            <div id="chart4 " class="bg-white chart4 border shadow-sm my-3 rounded"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 my-3 text-center">
            <div class="bg-white border shadow-sm rounded"><button *ngIf="!tabellaDettaglioObjLoaded"
                (click)="tabellaDettaglioObjLoaded = true" class="btn btn-primary my-2">Mostra tabella di
                dettaglio</button>
              <div class="text-left">
                <dashboard-finanziaria-details-table-component [observable$]="observable$ | async"
                  [tabellaDettaglioObj]="tabellaDettaglioObj" [hidden]="!tabellaDettaglioObjLoaded"
                  (onExportExcelClick)="exportXlsDetailTableData($event)"
                  [doingExportExcel$]="subjectTabDettaglioDoingExport$ | async">
                </dashboard-finanziaria-details-table-component>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>



  <!--inizio modale esportazione-->
  <ng-template #modalExportData>
    <div class="modal-header  text-center">
      <h4 class="modal-title pull-left">Opzioni esportazione</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalEsportazione()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [formGroup]="exportForm">
      <div class="custom-control custom-switch"
        *ngIf="this.filterObject.filterTipoClassificazione && this.filterObject.filterTipoClassificazione!='-1'">
        <input [formGroup]="exportForm" formControlName="conClassificazioni" type="checkbox" class="custom-control-input"
          id="conClassificazioni">
        <label class="custom-control-label" for="conClassificazioni">esporta classificazioni</label>
      </div>

      <button [disabled]="loadingExportExcel" class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0"
        (click)="doEsportaExcel()">
        Esporta
        <img *ngIf="loadingExportExcel" class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button>
    </div>
  </ng-template>
  <!--fine modale esportazione-->
