<form [formGroup]="loginForm">

  <div class="container">
    <div class="row clearfix">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sx-6 col-xs-12 offset-xl-3 offset-lg-3  offset-md-3 mb-5">
        <h3 class="text-center login-title mt-4 mb-4 font-weight-bold">
          Cambio password
        </h3>

        <div class="account-wall">
          <div id="data" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-3">
            <!--class="form-signin"-->
            <label class="bold mt-3">
              Vecchia password<span class="text-danger font-weight-bold">*</span>:
            </label>
            <show-hide-password size="sm" [btnOutline]="false">
              <input type="password" #oldPassword tabindex="1" formControlName="oldPassword" class="form-control"
                [ngClass]="{
                    'is-invalid': submitted && f.oldPassword.errors
                  }" />
            </show-hide-password>
            <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback" style="display:block">

              <div *ngIf="f.oldPassword.errors.required">
                Vecchia password &egrave; obbligatoria
              </div>
            </div>

            <div class="mb-2 mt-3">
              <label class="bold d-inline pb-2">
                Nuova password<span class="text-danger font-weight-bold">*</span>:
              </label>

              <div class="dropdown d-inline pb-2">
                <app-popover
                  [html]="htmlPopover"
                  [placement]="'top'"
                  [class]="'btn btn-sm btn-outline-secondary float-right mb-2'"
                  [htmlImage]="htmlImg"
                  [text]="'Requisiti password'"
                ></app-popover>
              </div>
            </div>

            <show-hide-password size="sm" [btnOutline]="false">
              <input type="password" tabindex="2" #newPassword formControlName="newPassword" class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && f.newPassword.errors
                }" />
            </show-hide-password>

            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback" style="display:block">
              <div *ngIf="f.newPassword.errors.required">
                Nuova password &egrave; obbligatoria
              </div>
              <div *ngIf="f.newPassword.errors.pattern">
                La password non rispetta i requisiti
              </div>
            </div>

            <label class="bold mt-3">
              Conferma nuova password <span class="text-danger font-weight-bold">*</span>:
            </label>
            <show-hide-password size="sm" [btnOutline]="false">
              <input type="password" tabindex="3" formControlName="confirmPassword" #confirmPassword
                class="form-control" [ngClass]="{
                  'is-invalid': submitted && f.confirmPassword.errors
                }" />
            </show-hide-password>
            <div *ngIf="submitted && (f.newPassword.errors || f.confirmPassword.errors ) " class="invalid-feedback"
              style="display:block">
              <div *ngIf="f.newPassword != f.confirmPassword">
                Riscrivere la nuova password
              </div>

            </div>
            <button class="btn btn-sm btn-primary btn-block" value="INVIA" style="margin-top:15px" (click)="onSubmit()">
              INVIA
            </button>
            <span class="clearfix"></span>
            <small>
              <br />
              I campi contrassegnati da asterisco (<span class="text-danger font-weight-bold">*</span>) sono obbligatori</small>
          </div>
        </div>

      </div>

    </div>
  </div>

</form>
