import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { JwtUser } from 'src/app/models/jwt-user';
import { matchOtherValidator } from 'src/app/helpers/validators/matchOtherValidator';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  currentUser: JwtUser;
  htmlPopover = '';
  htmlImg = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastService
  ) {}

  ngOnInit() {
    this.htmlPopover = `<ul class="popover-menu" style="width:500px">
                          <li><a>lunghezza minima 8 caratteri</a></li>
                          <li><a>almeno un carattere maiuscolo</a></li>
                          <li><a>almeno un carattere minuscolo</a></li>
                          <li><a>almeno un numero</a></li>
                          <li>
                            <a>almeno un carattere speciale tra: <br />! @ # $ - _</a>
                          </li>
                          <li><a>non può contenere spazi</a></li>
                        </ul>`;

    this.htmlImg = '<b class="caret"></b>';

    this.authService.currentUser.subscribe(u => (this.currentUser = u));
    this.loginForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: [
          '',
          [
              Validators.required,
              Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@$_-\])(?!.*\\s).{8,}$')
          ]
      ],
      confirmPassword: [
          '',
          [
              Validators.required,
              matchOtherValidator('newPassword')
          ]
      ]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.f.newPassword.value.includes('%')) {
      this.f.newPassword.setErrors({ pattern: true });
    }

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    console.log('call service.changePassword with ' + this.f.newPassword.value);
    console.log('this.currentUser:' + JSON.stringify(this.currentUser));
    this.userService
      .changePassword(
        this.f.oldPassword.value,
        this.f.newPassword.value,
        this.f.confirmPassword.value,
        this.currentUser.user_name
      )
      .subscribe(
          data => {
            if (!data) {
              // data is null, so permission issue
              this.toastr.error( `Errore durante il salvataggio della password, contattare l'amministratore`);
              return;
            }           
            this.toastr.success( 'Nuova password salvata con successo');            
            return;
          }
          , error => {
            this.loading = false;            
            this.toastr.error(`Errore: ${error.error.message?error.error.message:(error.error.error_description?error.error.error_description:error.error)}`, null, {
                timeOut: 5000, disableTimeOut: false
            });
            console.log('error');
        }
      );
  }
}
