<div class="container bg-white">
    <div class="col-md-12 mt-3 sfondo_bianco">
        <app-title-page></app-title-page>
        <div class="col-md-12">
            <p class=" text-center">
                In questa sezione e' possibile visualizzare gli accessi effettuati dagli utenti in vari periodi prestabiliti.
            </p>
        </div>

        <div class="mt-4">
            <form [formGroup]="registerForm" novalidate>

                <div class="row">

                    <div class="form-group clearfix col-lg-6">
                        <h5><label class="mandatory" for="userType">Tipologia utente:</label></h5>
                        <ng-select [items]="userTypes" bindValue="key" bindLabel="value" groupBy="group" placeholder="Seleziona una tipologia" formControlName="userType" selectOnTab="true" [clearable]="false" [searchable]="false" (change)="onChangeUserType($event)"></ng-select>
                    </div>

                    <div class="form-group clearfix col-lg-6">
                        <h5><label>Periodo</label></h5>

                        <div class="d-flex">
                            <input type="text" formControlName="datePeriod" class="form-control" bsDaterangepicker [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY', containerClass:'theme-dark-blue' }" #datePeriod="bsDaterangepicker" [maxDate]="maxDate"
                                style="border-bottom-right-radius: unset;border-top-right-radius: unset;">
                            <button class="btn btn-primary" (click)="datePeriod.toggle()" style="border-bottom-left-radius: unset;border-top-left-radius: unset;">
                               <i class="fas fa-calendar-alt"></i>
                            </button>
                        </div>
                        <div *ngIf="submitted && f.datePeriod.errors" class="invalid-feedback">
                            <div *ngIf="f.datePeriod.errors.required">Selezionare un periodo</div>
                        </div>

                    </div>

                </div>
                <div class="form-group clearfix" *ngIf="selectedUserType != ''">
                    <button class="btn btn-lg btn-primary btn-block" type="button" style="margin:15px 0;" (click)="search()">
                     <i class="fas fa-search" aria-hidden="true"></i>&nbsp;Cerca
                    </button>
                </div>
            </form>


        </div>

        <div [hidden]="!searched">
            <div class="">
                <ngx-datatable-wrapper #ngxDatatableWrapper [templates]="templateList" style=" width: 100%; height: auto;" [dtOptions]="dtOptionsNgx"></ngx-datatable-wrapper>
            </div>
        </div>
        <!--start div chart-->
        <div id="report_timeline" style="width:100%;min-width: 310px; min-height: 400px; margin: 0 auto;" [hidden]="!searched"></div>
        <!--end chart div-->

    </div>
</div>
<!--end body of the page-->