<div class="p-5 container-xl text-center" [hidden]="loadingCounter<=0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>
<hr>
<div [hidden]="loadingCounter>0">
  <div [formGroup]="myGroup" novalidate>
    <!--start form ricerca-->

    <div class="form-row">

      <div class="form-group col-lg-4 col-sm-12" [hidden]="true">
        <label for="ruolo" class="">
          <h6 class="bold">Ruolo</h6>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.ruolo.errors }">
          <ng-select [formGroup]="myGroup" formControlName="ruolo" [items]="rolesList" bindValue="id" bindLabel="ruolo"
            placeholder="Seleziona un ruolo">
          </ng-select>
        </div>

        <div *ngIf="submitted && f.ruolo.errors" class="invalid-feedback d-block">
          <div *ngIf="f.ruolo.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>

      <div class="form-group col-lg-4 col-sm-12">
        <label for="ruolo" class="">
          <h6 class="bold">Progettualità/Fabbisogno/Intervento <span class="small">(min 3 caratteri)</span></h6>
        </label>
        <div>
          <app-completer [cpOptions]="completerParams" (onSelectEmitter)="setIntervention($event)"
            (keyup)="onChangeIntervention($event)">
          </app-completer>
        </div>
      </div>


      <div class="form-group col-lg-4 col-sm-12 d-block">
        <label for="soggetto">
          <h6 class="bold">Soggetto giuridico</h6>
        </label>
        <div [ngClass]="{ 'is-invalid': submitted && f.soggetto.errors }">
          <ng-select
          [items]="soggettiGiuridiciList"
          bindValue="id"
          bindLabel="descrizione"
          placeholder="Seleziona un soggetto giuridico"
          [formGroup]="myGroup"
          formControlName="soggetto"
          >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.descrizione }}">{{ item.descrizione }}</div>
        </ng-template>
          </ng-select>
        </div>

        <div *ngIf="submitted && f.soggetto.errors" class="invalid-feedback d-block">
          <div *ngIf="f.soggetto.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group col-lg-2 col-sm-12 d-block align-self-end">

        <button [disabled]="!f.soggetto.value && !filterIntervento" class="btn btn-primary btn-block" (click)="search()">Cerca&nbsp;<i
            class="fas fa-search pointer"></i></button>

      </div>
      <div class="form-group col-lg-2 col-sm-12 d-block align-self-end">

        <button [disabled]="!isFiltered" class="btn btn-secondary btn-block font-weight-bold"
          (click)="undoSearch()">Resetta<i class="fas fa-search-alt pointer"></i></button>

      </div>
    </div>
  </div>
  <!--end form ricerca-->

  <!--start tabella generica interventi ed erogazioni-->
  <div class="row" [hidden]="isFiltered">


    <div class="col-lg-3 table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class=" text-center">
              <h5>Interventi validati</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class=" text-center">
              <h3>{{data?data.interventi:''}}</h3>
            </td>

          </tr>

        </tbody>
      </table>
    </div>

    <div class="col-lg-9 table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <ng-container *ngIf="data">
              <th  scope="col" *ngFor="let item of data.annualita">{{item.anno}}</th>
            </ng-container>
            <th  scope="col">Totale</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Erogazioni </th>
            <td>{{data?data.erogazioniSa:''}}</td>
            <ng-container *ngIf="data">
              <td *ngFor="let item of data.annualita">{{printValueAsCurrency(item.erogazioniSaImporto)}}</td>
            </ng-container>
            <td>{{printValueAsCurrency(getTotaleErogazionexAnnualita('erogazioniSaImporto'))}}</td>
          </tr>
          <!-- <tr>
                        <th scope="row">Erogazioni</th>
                        <td>{{data?data.erogazioniNormali:''}}</td>
                        <ng-container *ngIf="data">
                            <td *ngFor="let item of data.annualita">{{printValueAsCurrency(item.erogazioniNormaleImporto)}}</td>
                        </ng-container>
                        <td>{{printValueAsCurrency(getTotaleErogazionexAnnualita('erogazioniNormaleImporto'))}}</td>
                    </tr>-->

        </tbody>
      </table>
    </div>
  </div>

  <!--end tabella generica interventi ed erogazioni-->
  <!--start grafici generici interventi ed erogazioni-->
  <hr>
  <div class="row mb-5" [hidden]="isFiltered">
    <div class="col-lg-12 border shadow-sm rounded">
      <div class="chart" id="chart2"></div>
    </div>

  </div>
  <div class="row mb-5 border shadow-sm rounded" [hidden]="true">
    <div class="col-lg-6">
      <div class="chart" id="chart1"></div>
    </div>


    <div class="col-lg-6 " [hidden]="true">
      <div class="chart" id="chart3"></div>
    </div>
  </div>
  <!--end grafici generici interventi ed erogazioni-->
  <!--inizio tabella e grafici di dettaglio dopo filtro-->
  <div class="row  border shadow-sm rounded" [hidden]="!isFiltered">
    <div class="col-lg-12 ">

      <div class="fixedHeaderTable col-lg-12 my-5 table-responsive table-wrapper-scroll-y scrollbar-y px-0"
        style="height: 600px;">
        <table class="table table-hover table-colore">
          <thead class="">
            <th class="fixedmeY" style="min-width: 290px;"><button (click)="esporta();" type="button"
                class="btn btn-info btn-block font-weight-bold">Esporta&nbsp;<i class="fas fa-file-export"></i></button>
            </th>
            <th>&nbsp;</th>
            <ng-container *ngIf="detailsObject">
              <th class="text-right" style="min-width: 150px;" *ngFor="let anno of anniArray">
                <span class="pr-4">{{anno}}</span>
            </th>
            </ng-container>
            <th class="text-right" style="min-width: 180px;">
                <span class="pr-4">totale</span>
            </th>
          </thead>
          <tbody>
            <ng-container *ngIf="detailsObject">
              <ng-container *ngFor="let progetto of detailsObject;let i=index">
                <tr>
                  <td class="fixedme" style="max-width: 290px;">
                    <a onclick="$('i',this).hasClass('fa-arrow-circle-down')?$('i',this).addClass('fa-arrow-circle-up').removeClass('fa-arrow-circle-down'):$('i',this).addClass('fa-arrow-circle-down').removeClass('fa-arrow-circle-up')"
                      class="pointer font-weight-bold text-0083e0" data-toggle="collapse" role="button"
                      aria-expanded="false" [attr.aria-controls]="'.tr_collapse_' + i"
                      [attr.data-target]="'.tr_collapse_' + i">
                      <i class=" fas  fa-arrow-circle-down">
                      </i>&nbsp;progetto / fabbisogno {{progetto.progettoCodice}}
                    </a>
                    <div class="" style="font-size: 13px;">{{progetto.progettoDescrizione}}</div>
                    <ng-container *ngFor="let ruolo of progetto.ruoli">
                      <div class="" style="font-size: 13px; font-weight: normal;">
                        Ruolo: {{ruolo.ruolo}} - {{ruolo.soggetto}}</div>
                    </ng-container>
                  </td>
                  <td style="min-width: 290px; font-size: 13px;">
                    <ul class="list-group font-14">
                      <li class="list-group-item font-14">A) totale SAL/ALTRO emessi</li>
                      <li class="list-group-item font-14">B) totale Giustificativi emessi</li>
                      <li class="list-group-item font-14">C) totale importi erogati</li>
                      <!--<li class="list-group-item font-14">D) totale importi erogati</li>-->
                      <li class="list-group-item font-14">D) valore progetto/fabbisogno</li>
                      <li class="list-group-item font-14" title="A-C) differenza da erogare su SAL/ALTRO emessi ">A-C)
                        differenza da erogare su SAL/ALTRO emessi</li>
                      <!--<li class="list-group-item font-14">B-D) differenza da erogare su SAL emessi</li>-->
                      <li class="list-group-item font-14" title="D-C) differenza da erogare su intervento">D-C)
                        differenza da erogare su int...</li>
                      <!--<li class="list-group-item font-14" title="C-D) differenza da erogare su anticipazioni a SA">C-D) differenza da erogare su anticip...</li>-->
                    </ul>
                  </td>
                  <td style="min-width: 150px;" *ngFor="let anno of anniArray">
                    <ul class="list-group font-14">
                      <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getSalEmessiSa(progetto,anno))}}</li>
                      <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getSalEmessi(progetto,anno))}}</li>
                      <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getImportiErogatiSa(progetto,anno))}}
                      </li>
                      <!--<li class="list-group-item font-14">{{printValueAsCurrency(getImportiErogati(progetto,anno))}}</li>-->
                      <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getValore(progetto,anno))}}</li>
                      <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getAC(progetto,anno))}}</li>
                      <!--<li class="list-group-item font-14">{{printValueAsCurrency(getBD(progetto,anno))}}</li>-->
                      <li class="list-group-item font-14 text-right">&nbsp;<!--{{printValueAsCurrency(getEC(progetto,anno))}}--></li>

                    </ul>
                  </td>
                  <td style="min-width: 180px;">
                    <ul class="list-group font-14">
                      <li class="list-group-item font-14 text-right">
                        <strong>{{printValueAsCurrency(getSalEmessiSaTotale(progetto))}}</strong></li>
                      <li class="list-group-item font-14 text-right">
                        <strong>{{printValueAsCurrency(getSalEmessiTotale(progetto))}}</strong></li>
                      <li class="list-group-item font-14 text-right">
                        <strong>{{printValueAsCurrency(getImportiErogatiSaTotale(progetto))}}</strong></li>
                      <!--<li class="list-group-item font-14"><strong>{{printValueAsCurrency(getImportiErogatiTotale(progetto))}}</strong></li>-->
                      <li class="list-group-item font-14 text-right">
                        <strong>{{printValueAsCurrency(getValoreTotale(progetto))}}</strong></li>
                      <li class="list-group-item font-14 text-right">
                        <strong>{{printValueAsCurrency(getACTotale(progetto))}}</strong></li>
                      <!--<li class="list-group-item font-14"><strong>{{printValueAsCurrency(getBDTotale(progetto))}}</strong></li>-->
                      <li class="list-group-item font-14 text-right">
                        <strong>{{printValueAsCurrency(getECTotale(progetto))}}</strong></li>
                      <!--<li class="list-group-item font-14"><strong>{{printValueAsCurrency(getCDTotale(progetto))}}</strong></li>-->
                    </ul>
                  </td>
                </tr>
                <!--interventi-->
                <ng-container *ngIf="progetto && progetto.interventi.length>0">
                  <ng-container *ngFor="let intervento of progetto.interventi;let j = index">
                    <tr class="background-table collapse {{'tr_collapse_' + i}}">
                      <th class="fixedme" style="max-width: 290px;" scope="row">
                        intervento {{intervento.codice}}
                        <div class="" style="font-size: 13px; font-weight: normal;">
                          {{intervento.descrizione}}</div>
                        <ng-container *ngFor="let ruolo of intervento.ruoli">
                          <div class="" style="font-size: 13px; font-weight: normal;">
                            Ruolo: {{ruolo.ruolo}} - {{ruolo.soggetto}}</div>
                        </ng-container>
                      </th>

                      <td style="min-width: 290px; font-size: 13px;">
                        <ul class="list-group font-14">
                          <li class="list-group-item font-14">A) totale SAL/ALTRO emessi </li>
                          <li class="list-group-item font-14">B) totale Giustificativi emessi</li>
                          <li class="list-group-item font-14">C) totale importi erogati </li>
                          <!--<li class="list-group-item font-14">D) totale importi erogati</li>-->
                          <li class="list-group-item font-14">D) importo intervento</li>
                          <li class="list-group-item font-14" title="A-C) differenza da erogare su SAL/ALTRO emessi ">
                            A-C) differenza da erogare su SAL/ALTRO emessi</li>
                          <!--<li class="list-group-item font-14">B-D) differenza da erogare su SAL emessi</li>-->
                          <li class="list-group-item font-14" title="D-C) differenza da erogare su intervento">D-C)
                            differenza da erogare su int...</li>
                          <!--<li class="list-group-item font-14" title="C-D) differenza da erogare su anticipazioni a SA">C-D) differenza da erogare su anticip...</li>-->
                        </ul>
                      </td>

                      <td style="min-width: 150px;" *ngFor="let anno of anniArray">
                        <ul class="list-group font-14">
                          <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getSalEmessiSa(intervento,anno))}}
                          </li>
                          <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getSalEmessi(intervento,anno))}}
                          </li>
                          <li class="list-group-item font-14 text-right">
                            {{printValueAsCurrency(getImportiErogatiSa(intervento,anno))}}</li>
                          <!--<li class="list-group-item font-14">{{printValueAsCurrency(getImportiErogati(intervento,anno))}}</li>-->
                          <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getValore(intervento,anno))}}</li>
                          <li class="list-group-item font-14 text-right">{{printValueAsCurrency(getAC(intervento,anno))}}</li>
                          <!--<li class="list-group-item font-14">{{printValueAsCurrency(getBD(intervento,anno))}}</li>-->
                          <li class="list-group-item font-14 text-right">&nbsp;<!--{{printValueAsCurrency(getEC(intervento,anno))}}--></li>
                          <!--<li class="list-group-item font-14">{{printValueAsCurrency(getCD(intervento,anno))}}</li>-->

                        </ul>
                      </td>
                      <td style="min-width: 180px;">
                        <ul class="list-group font-14">
                          <li class="list-group-item font-14 text-right">
                            <strong>{{printValueAsCurrency(getSalEmessiSaTotale(intervento))}}</strong></li>
                          <li class="list-group-item font-14 text-right">
                            <strong>{{printValueAsCurrency(getSalEmessiTotale(intervento))}}</strong></li>
                          <li class="list-group-item font-14 text-right">
                            <strong>{{printValueAsCurrency(getImportiErogatiSaTotale(intervento))}}</strong></li>
                          <!--<li class="list-group-item font-14"><strong>{{printValueAsCurrency(getImportiErogatiTotale(intervento))}}</strong></li>-->
                          <li class="list-group-item font-14 text-right">
                            <strong>{{printValueAsCurrency(getValoreTotale(intervento))}}</strong></li>
                          <li class="list-group-item font-14 text-right">
                            <strong>{{printValueAsCurrency(getACTotale(intervento))}}</strong></li>
                          <!--<li class="list-group-item font-14"><strong>{{printValueAsCurrency(getBDTotale(intervento))}}</strong></li>-->
                          <li class="list-group-item font-14 text-right">
                            <strong>{{printValueAsCurrency(getECTotale(intervento))}}</strong></li>
                          <!--<li class="list-group-item font-14"><strong>{{printValueAsCurrency(getCDTotale(intervento))}}</strong></li>-->
                        </ul>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!(detailsObject && detailsObject.length>0)">
                <tr>
                  <td colspan="20">Nessun progetto / fabbisogno presente</td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>



          <tfoot>
            <tr>
              <th class="fixedmefooter">totale</th>
              <th>
                <ul class="list-group font-14">
                  <li class="list-group-item font-14 bg-002742">A) totale SAL/ALTRO emessi</li>
                  <li class="list-group-item font-14 bg-002742">B) totale Giustificativi emessi</li>
                  <li class="list-group-item font-14 bg-002742">C) totale importi erogati</li>
                  <!--<li class="list-group-item font-14 bg-002742">D) totale importi erogati</li>-->
                  <li class="list-group-item font-14 bg-002742">D) importo intervento</li>
                  <li class="list-group-item font-14 bg-002742" title="A-C) differenza da erogare su SAL/ALTRO emessi">
                    A-C) differenza da erogare su SAL/ALTRO emessi</li>
                  <!--<li class="list-group-item font-14 bg-002742">B-D) differenza da erogare su SAL emessi</li>-->
                  <li class="list-group-item font-14 bg-002742" title="D-C) differenza da erogare su intervento">D-C)
                    differenza da erogare su int...</li>
                  <!--<li class="list-group-item font-14 bg-002742" title="C-D) differenza da erogare su anticipazioni a SA">C-D) differenza da erogare su anticip...</li>-->
                </ul>
              </th>
              <ng-container *ngIf="detailsObject">
                <th style="min-width: 150px;" *ngFor="let anno of anniArray">
                  <ul class="list-group  font-14">
                    <li class="list-group-item font-14 bg-002742 text-right">
                      {{printValueAsCurrency(getSalEmessiSaTotaleAnno(anno))}}</li>
                    <li class="list-group-item font-14 bg-002742 text-right">{{printValueAsCurrency(getSalEmessiTotaleAnno(anno))}}
                    </li>
                    <li class="list-group-item font-14 bg-002742 text-right">
                      {{printValueAsCurrency(getImportiErogatiSaTotaleAnno(anno))}}</li>
                    <!--<li class="list-group-item font-14 bg-002742">{{printValueAsCurrency(getImportiErogatiTotaleAnno(anno))}}</li>-->
                    <li class="list-group-item font-14 bg-002742 text-right">{{printValueAsCurrency(getValoreTotaleAnno(anno))}}
                    </li>
                    <li class="list-group-item font-14 bg-002742 text-right">{{printValueAsCurrency(getACTotaleAnno(anno))}}</li>
                    <!--<li class="list-group-item font-14 bg-002742">{{printValueAsCurrency(getBDTotaleAnno(anno))}}</li>-->
                    <li class="list-group-item font-14 bg-002742 text-right">&nbsp;<!--{{printValueAsCurrency(getECTotaleAnno(anno))}}--></li>
                    <!--<li class="list-group-item font-14 bg-002742">{{printValueAsCurrency(getCDTotaleAnno(anno))}}</li>-->

                  </ul>
              </ng-container>
              <th>
                <ul class="list-group font-14">
                  <li class="list-group-item font-14 bg-002742 text-right">
                    {{printValueAsCurrency(getSalEmessiSaTotaleComplessivo())}}</li>
                  <li class="list-group-item font-14 bg-002742 text-right">
                    {{printValueAsCurrency(getSalEmessiTotaleComplessivo())}}</li>
                  <li class="list-group-item font-14 bg-002742 text-right">
                    {{printValueAsCurrency(getImportiErogatiSaTotaleComplessivo())}}</li>
                  <!--<li class="list-group-item font-14 bg-002742">{{printValueAsCurrency(getImportiErogatiTotaleComplessivo())}}</li>-->
                  <li class="list-group-item font-14 bg-002742 text-right">{{printValueAsCurrency(getValoreTotaleComplessivo())}}
                  </li>
                  <li class="list-group-item font-14 bg-002742 text-right">{{printValueAsCurrency(getACTotaleComplessivo())}}</li>
                  <!--<li class="list-group-item font-14 bg-002742">{{printValueAsCurrency(getBDTotaleComplessivo())}}</li>-->
                  <li class="list-group-item font-14 bg-002742 text-right">{{printValueAsCurrency(getECTotaleComplessivo())}}</li>
                  <!--<li class="list-group-item font-14 bg-002742">{{printValueAsCurrency(getCDTotaleComplessivo())}}</li>-->

                </ul>
              </th>
            </tr>

          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <div class="row my-5 border shadow-sm rounded" [hidden]="!isFiltered">
    <div class="col-lg-5">
      <div class="chart" id="chart4"></div>
    </div>
    <div class="col-lg-7">
      <div class="chart" id="chart5"></div>
    </div>

  </div>
  <!--fine tabella grafici di dettaglio-->
</div>
