import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { AuthGuard } from "./guards/auth-guard.service";
import { NewsManagementComponent } from "./components/newsManagement/news-management.component";
import { RoleGuard } from "./guards/role-guard";
import { NotLoggedGuard } from "./guards/not-logged-guard";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { DocumentsComponent } from "./components/documents/documents.component";
import { AccessLogListComponent } from "./components/accessLogList/accesslog-list.component";
import { AccountComponent } from "./components/account/account.component";
import { LoggedGuard } from "./guards/logged-guard";
import { RegisterComponent } from "./components/register/register.component";
import { GeoManagementComponent } from "./components/geoManagement/index";
import { AccountListComponent } from "./components/accountList/account-list.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { UpdatePermissionsComponent } from "./components/updatePermissions/update-permissions.component";
import { HomepageComponent } from "./components/homepage/homepage.component";
import { IamComponent } from "./components/iam/iam.component";
import { Roles } from "./helpers/constants";



const routes: Routes = [
    {
        path: "",
        // component: HomeComponent,
        component: HomepageComponent,

        data: {
            breadcrumb: null
        },
        canActivate: [LoggedGuard, AuthGuard]
    }
    ,
  {
    path: "changePassword",
    data: {
      breadcrumb: "Cambio password",
    },
    component: ChangePasswordComponent,
    canActivate: [LoggedGuard],
  }
    ,


    {
        path: "account",
        //component: AccountPageComponent,
        data: {
            breadcrumb: "Account",
            clickable: false
        },
        children: [
            {
                path: "me/:user",
                data: {
                    breadcrumb: "Visualizza account",
                    expectedRole: [
                        Roles.role_admin
                    ]
                },
                component: AccountComponent,
                canActivate: [LoggedGuard, RoleGuard]
            },
            {
                path: "me",
                component: AccountComponent,
                data: {
                    breadcrumb: "Visualizza account",                    
                },
                canActivate: [LoggedGuard, AuthGuard]
            },
            {
                path: "accessList",
                data: {
                    breadcrumb: "Lista accessi",
                    expectedRole: [
                        Roles.role_admin
                    ]
                },
                component: AccessLogListComponent,
                canActivate: [LoggedGuard, RoleGuard]
            },
            {
                path: "accountList",
                data: {
                    breadcrumb: "Lista utenti",
                    expectedRole: [
                        Roles.role_admin,
                        Roles.role_manager,
                    ]
                },
                component: AccountListComponent,
                canActivate: [LoggedGuard, RoleGuard]
            },
            {
                path: "updatePermissions",
                data: {
                    breadcrumb: "Aggiornamento Permessi",
                    expectedRole: [
                        Roles.role_admin
                    ]
                },
                component: UpdatePermissionsComponent,
                canActivate: [LoggedGuard, RoleGuard]
            }
        ]
    },
    {
        path: 'admin',
        data: {
            breadcrumb: "Admin",
            clickable: false,
            expectedRole: [Roles.role_admin]
        },
        children: [
            {
                path: "geoManagement",
                component: GeoManagementComponent,
                data: {
                    breadcrumb: "Gestione territorio",
                    expectedRole: [Roles.role_admin]
                },
                canActivate: [LoggedGuard, RoleGuard]
            },
            {
                path: "manageNews",
                data: {
                    breadcrumb: "Gestione comunicazioni",
                    expectedRole: [
                        Roles.role_admin
                    ]
                },
                component: NewsManagementComponent,
                canActivate: [LoggedGuard, RoleGuard]
            }
        ],
        canActivate: [RoleGuard]
    },
    {
        path: "inframob",
        data:{breadcrumb:null,expectedRole: []},
        loadChildren: () => import('./modules/inframob/inframob.module').then(m => m.InfraMobModule),
        canActivate: []        
    },
/*
    {
        path:"progetti",
        data:{breadcrumb:"Progetti",clickable: false},
        children:[
            {path:"inframob",canActivate:[LoggedGuard],data:{breadcrumb:null}}
        ]
    }
    ,*/

    {
        path: "login",
        data: {
            breadcrumb: ""
        },
        component: LoginComponent,
        canActivate: [NotLoggedGuard]
    },
    {
        path: "iam",
        data: {
            breadcrumb: "iam"
        },
        component: IamComponent,
        canActivate: [NotLoggedGuard]
    },
    {
        path: "documents",
        data: { breadcrumb: "Gestione documentazione" },
        component: DocumentsComponent,
        canActivate: [LoggedGuard]
    },
    {
        path: "register",
        component: RegisterComponent,
        data: {
            breadcrumb: "Registrazione",
            notExpectedRole: [Roles.role_reg]
        },
        canActivate: [LoggedGuard, AuthGuard, RoleGuard]
    },    
    {
        path: "privacy",
        data: {
            breadcrumb: "Privacy Policy"
        },
        component: PrivacyComponent,
        canActivate: [NotLoggedGuard]
    }
    
    ,
    { path: "**", redirectTo: "" }
];

@NgModule({
    providers: [],
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            enableTracing: false,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
